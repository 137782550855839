function initialState() {
  return {
    quickDateRange: '',
    startDate: '',
    endDate: '',
    minFromDate: ''
  };
}

const getters = {
  quickDateRange: (state) => state.quickDateRange,
  startDate: (state) => state.startDate,
  endDate: (state) => state.endDate,
  minFromDate: (state) => (state?.minFromDate?.length ? state.minFromDate : '2017-01-01')
};

const mutations = {
  SET_QUICK_DATE_RANGE(state, payload) {
    state.quickDateRange = payload;
  },
  SET_START_DATE(state, payload) {
    state.startDate = payload;
  },
  SET_END_DATE(state, payload) {
    state.endDate = payload;
  },
  SET_MIN_FROM_DATE(state, payload) {
    state.minFromDate = payload;
  },
  RESET(state) {
    const initial = initialState();

    Object.keys(initial).forEach((key) => {
      state[key] = initial[key];
    });
  }
};

const actions = {
  setQuickDateRange({ commit }, payload) {
    commit('SET_QUICK_DATE_RANGE', payload);
  },
  setStartDate({ commit }, payload) {
    commit('SET_START_DATE', payload);
  },
  setEndDate({ commit }, payload) {
    commit('SET_END_DATE', payload);
  },
  setMinFromDate({ commit }, payload) {
    commit('SET_MIN_FROM_DATE', payload);
  },
  resetDateRangeState({ commit }) {
    commit('RESET');
  }
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  mutations,
  actions
};
