import API from '@api/corporate.api';
import pagination from '../pagination';

function initialState() {
  return {
    ...pagination.state,
    propertyId: null,
    playlists: [],
    q: '',
    sourceType: 'all',
    loading: false
  };
}

const getters = {
  ...pagination.getters,
  playlists: (state) => state.playlists,
  q: (state) => state.q,
  sourceType: (state) => state.sourceType,
  loading: (state) => state.loading
};

const mutations = {
  ...pagination.mutations,
  SET_PROPERTY_ID(state, propertyId) {
    state.propertyId = propertyId;
  },
  SET_LOADING(state, payload) {
    state.loading = payload;
  },
  SET_PROPERTY_PLAYLISTS(state, data) {
    state.playlists = data;
  },
  SET_SEARCH_FILTER(state, search) {
    state.q = search;
  },
  SET_SOURCE_TYPE(state, sourceType) {
    state.sourceType = sourceType;
  },
  ADD_PLAYLISTS(state, data) {
    state.playlists.push(...data);
  },
  RESET(state) {
    const initial = initialState();

    Object.keys(initial).forEach((key) => {
      state[key] = initial[key];
    });
  }
};

const actions = {
  async apiGetPropertyPlaylists({ state }, params) {
    const { data } = await API.getPropertyPlaylists(
      state.propertyId,
      {
        source: state.sourceType,
        perPage: state.perPage,
        ...params
      }
    );

    return data;
  },
  async getPropertyPlaylists({ state, commit, dispatch }) {
    commit('SET_LOADING', true);

    const data = await dispatch('apiGetPropertyPlaylists', {
      page: state.page,
      q: state.q
    });

    commit('SET_LOADING', false);
    commit('SET_SHOW_MORE', state.page < data.pagination.pages);
    commit('SET_PROPERTY_PLAYLISTS', data.records);
  },
  async applyFilter({ state, commit, dispatch }, filter) {
    commit('SET_LOADING', true);

    const data = await dispatch('apiGetPropertyPlaylists', {
      page: 1,
      q: filter
    });

    commit('SET_LOADING', false);
    commit('SET_PAGE', 1);
    commit('SET_SEARCH_FILTER', filter);
    commit('SET_SHOW_MORE', state.page < data.pagination.pages);
    commit('SET_PROPERTY_PLAYLISTS', data.records);
  },
  async fetchMore({ state, commit, dispatch }) {
    commit('SET_FETCHING_MORE', true);

    const nextPage = state.page + 1;

    const data = await dispatch('apiGetPropertyPlaylists', {
      page: nextPage,
      q: state.q
    });

    commit('SET_FETCHING_MORE', false);
    commit('SET_PAGE', nextPage);
    commit('SET_SHOW_MORE', state.page < data.pagination.pages);
    commit('ADD_PLAYLISTS', data.records);
  },
  async setSourceType({ commit }, sourceType) {
    await commit('SET_SOURCE_TYPE', sourceType);
  },
  setPropertyId({ commit }, propertyId) {
    commit('SET_PROPERTY_ID', propertyId);
  },
  reset({ commit }) {
    commit('RESET');
  }
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  mutations,
  actions
};
