import API from '@api/liveSession.api';
import pagination from '../pagination';

function initialState() {
  return {
    ...pagination.state,
    records: [],
    filter: '',
    managedPropertyId: null,
    perPage: 12
  };
}

const moduleGetters = {
  ...pagination.getters,
  records: (state) => state.records,
  filter: (state) => state.filter,
  currentFolderId: (state, getters, rootState) => rootState.subFolderNavigation.currentFolderId,
  getAction: (state, getters, rootState) => (
    rootState.subFolderNavigation.subFolderOpened
      ? 'apiGetSubFolders'
      : 'apiGetFolders'
  )
};

const mutations = {
  ...pagination.mutations,
  SET_FOLDERS(state, data) {
    state.records = data;
  },
  SET_FILTER(state, filter) {
    state.filter = filter;
  },
  SET_MANAGED_PROPERTY_FILTER(state, payload) {
    state.managedPropertyId = payload;
  },
  ADD_FOLDERS(state, data) {
    state.records.push(...data);
  },
  RESET(state) {
    const initial = initialState();

    Object.keys(initial).forEach((key) => {
      // we don`t need to reset this on folders
      if (key !== 'managedPropertyId') {
        state[key] = initial[key];
      }
    });
  }
};

const actions = {
  async apiGetFolders({ state }, params) {
    params.search = params.q;
    delete params.q;
    if (!params.search || (params.search && (params.search.length === 0))) delete params.search;

    const { data } = await API.getFolders({
      perPage: state.perPage,
      ...params
    });

    return data;
  },
  async apiGetSubFolders({ commit, state, rootState }, params) {
    // we don't want to search with this request
    params.search = params.q;
    delete params.q;
    if (!params.search || (params.search && (params.search.length === 0))) delete params.search;

    // reset filter when navigating to Sub Folder
    commit('SET_FILTER', '');

    const { data } = await API.getFolderSubFolders(
      rootState.subFolderNavigation.currentFolderId,
      {
        perPage: state.perPage,
        ...params
      }
    );

    return data;
  },
  // eslint-disable-next-line object-curly-newline
  async getLiveSessionFoldersList({ state, commit, dispatch, getters }, params) {
    commit('SET_LOADING', true);

    const data = await dispatch(getters.getAction, {
      page: state.page,
      q: state.filter,
      ...(params?.managedPropertyId?.length && {
        managedPropertyId: params.managedPropertyId
      })
    });

    if (params?.managedPropertyId?.length) {
      commit('SET_MANAGED_PROPERTY_FILTER', params.managedPropertyId);
    }

    commit('SET_SHOW_MORE', state.page < data.pagination.pages);
    commit('SET_FOLDERS', data.records);
    commit('SET_LOADING', false);
  },
  // eslint-disable-next-line object-curly-newline
  async applyFilter({ state, commit, dispatch, getters }, filter) {
    commit('SET_LOADING', true);

    // Reset SubFolderNavigation

    const data = await dispatch(getters.getAction, {
      page: 1,
      q: filter,
      ...(state.managedPropertyId && {
        managedPropertyId: state.managedPropertyId
      })
    });

    commit('SET_PAGE', 1);
    commit('SET_FILTER', filter);
    commit('SET_SHOW_MORE', state.page < data.pagination.pages);
    commit('SET_FOLDERS', data.records);
    commit('SET_LOADING', false);
  },
  // eslint-disable-next-line object-curly-newline
  async fetchMore({ state, commit, dispatch, getters }) {
    commit('SET_FETCHING_MORE', true);

    const nextPage = state.page + 1;

    // Action based on SubFolderOpened

    const data = await dispatch(getters.getAction, {
      page: nextPage,
      q: state.filter,
      ...(state.managedPropertyId && {
        managedPropertyId: state.managedPropertyId
      })
    });

    commit('SET_PAGE', nextPage);
    commit('SET_SHOW_MORE', state.page < data.pagination.pages);
    commit('ADD_FOLDERS', data.records);
    commit('SET_FETCHING_MORE', false);
  },
  resetFolders({ commit }) {
    commit('RESET');
  },
  resetLibrary({ commit }) {
    commit('RESET');

    commit('liveSessionLibraryVideos/RESET', null, { root: true });
    commit('subFolderNavigation/RESET', null, { root: true });
  }
};

export default {
  namespaced: true,
  state: initialState,
  getters: moduleGetters,
  mutations,
  actions
};
