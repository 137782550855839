import apiClient from './apiClient';

export default {
  getCorpPropertiesList: () => apiClient.get('corp_properties/available'),
  getReportColumns: () => apiClient.get('/admin/reports/columns?userType=client'),
  saveFilters: (data) => apiClient.post('/corp_properties/exports/filters', { ...data }),
  getSavedFilters: () => apiClient.get('/corp_properties/exports/filters'),
  /**
   * "dataTypes": [
       {
          "type": "summary",
          "columns": ["string"]
       }
     ],
     "email": "user@example.com",
     "startDate": "string",
     "endDate": "string",
     "propertyIds": ["string"]
   */
  generateReport: (data) => apiClient.post('/admin/reports/generate', { ...data })
};
