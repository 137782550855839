function initialState() {
  return {
    data: {},
    shareSocialRecord: {},
    shownAttachment: {},
    type: null
  };
}

const getters = {
  modalData: (state) => state.data,
  shareSocialRecord: (state) => state.shareSocialRecord,
  shownAttachment: (state) => state.shownAttachment,
  type: (state) => state.type
};

const mutations = {
  SET_MODAL_DATA(state, data) {
    state.data = data;
  },
  SET_MODAL_TYPE(state, type) {
    state.type = type;
  },
  SET_SHARE_SOCIAL_RECORD(state, record) {
    state.shareSocialRecord = record;
  },
  SET_SHOWN_ATTACHMENT(state, record) {
    state.shownAttachment = record;
  },
  RESET(state) {
    const initial = initialState();

    Object.keys(initial).forEach((key) => {
      state[key] = initial[key];
    });
  }
};

const actions = {
  async setModalData({ commit }, data) {
    commit('SET_MODAL_DATA', data);
  },
  setModalType({ commit }, type) {
    commit('SET_MODAL_TYPE', type);
  },
  setShownAttachment({ commit }, params) {
    commit('SET_SHOWN_ATTACHMENT', params);
  },
  async setShareSocialRecord({ commit }, record) {
    commit('SET_SHARE_SOCIAL_RECORD', record);
  },
  resetModal({ commit }) {
    commit('RESET');
  }
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  mutations,
  actions
};
