import API from '@api/dashboardLiveSessions.api';
import corporateAPI from '@api/corporate.api';
import pagination from '../pagination';

function initialState() {
  return {
    ...pagination.state,
    filter: '',
    managedPropertyId: null,
    searchParams: {},
    records: []
  };
}

const moduleGetters = {
  ...pagination.getters,
  records: (state) => state.records,
  filter: (state) => state.filter,
  selectedProperty: (state, getters, rootState) => rootState.dashboardLiveSessionAgenda.selectedProperty,
  getListAction: (state, getters) => (
    Object.keys(getters.selectedProperty).length
      ? 'apiGetPropertyVideoList'
      : 'apiGetVideoList'
  )
};

const mutations = {
  ...pagination.mutations,
  SET_VIDEOS(state, data) {
    state.records = data;
  },
  SET_FILTER(state, filter) {
    state.filter = filter;
  },
  SET_SEARCH_PARAMS(state, payload) {
    state.searchParams = payload;
  },
  ADD_VIDEOS(state, data) {
    state.records.push(...data);
  },
  RESET(state) {
    const initial = initialState();

    Object.keys(initial).forEach((key) => {
      state[key] = initial[key];
    });
  }
};

const actions = {
  async apiGetVideoList({ state }, params) {
    const { contentType, managedPropertyId } = state.searchParams;

    const response = await API.getVideoList({
      perPage: state.perPage,
      page: params.page,
      q: params.search,
      ...(contentType?.length && {
        contentType
      }),
      ...(managedPropertyId?.length && {
        managedPropertyId
      })
    });

    return response;
  },
  async apiGetPropertyVideoList({ state, getters }, params) {
    if (params.managedPropertyId?.length) delete params.managedPropertyId;

    const queryParams = { q: params.search, ...params };

    const response = await corporateAPI.getPropertyVideos(
      getters.selectedProperty.id,
      {
        lsPropertyVideoList: true,
        perPage: state.perPage,
        ...queryParams
      }
    );

    return response;
  },
  // eslint-disable-next-line object-curly-newline, no-shadow
  async getVideoList({ state, commit, getters, dispatch }) {
    commit('SET_LOADING', true);

    const response = await dispatch(getters.getListAction, {
      page: state.page,
      search: state.filter
    });

    if (response.status === 200) {
      const { data } = response;

      let { pages } = data;

      if (data.pagination) {
        pages = data.pagination.pages;
      }

      commit('SET_SHOW_MORE', state.page < pages);
      commit('SET_VIDEOS', data.records || data.videos);
    }

    commit('SET_LOADING', false);
  },
  // eslint-disable-next-line object-curly-newline, no-shadow
  async applyFilter({ state, commit, getters, dispatch }, filter) {
    commit('SET_LOADING', true);

    const response = await dispatch(getters.getListAction, {
      page: 1,
      search: filter
    });

    if (response.status === 200) {
      const { data } = response;

      let { pages } = data;

      if (data.pagination) {
        pages = data.pagination.pages;
      }

      commit('SET_PAGE', 1);
      commit('SET_FILTER', filter);
      commit('SET_SHOW_MORE', state.page < pages);
      commit('SET_VIDEOS', data.records || data.videos);
    }

    commit('SET_LOADING', false);
  },
  // eslint-disable-next-line object-curly-newline, no-shadow
  async fetchMore({ state, commit, getters, dispatch }) {
    commit('SET_FETCHING_MORE', true);

    const nextPage = state.page + 1;

    const response = await dispatch(getters.getListAction, {
      page: nextPage,
      search: state.filter
    });

    if (response.status === 200) {
      const { data } = response;

      let { pages } = data;

      if (data.pagination) {
        pages = data.pagination.pages;
      }

      commit('SET_PAGE', nextPage);
      commit('SET_SHOW_MORE', state.page < pages);
      commit('ADD_VIDEOS', data.records || data.videos);
    }

    commit('SET_FETCHING_MORE', false);
  },
  setSearchParams({ commit }, payload) {
    commit('SET_SEARCH_PARAMS', payload);
  },
  resetVideosFilter({ commit }) {
    commit('SET_FILTER', '');
    commit('SET_PAGE', 1);
  },
  resetVideoList({ commit }) {
    commit('RESET');
  }
};

export default {
  namespaced: true,
  state: initialState,
  getters: moduleGetters,
  mutations,
  actions
};
