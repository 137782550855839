import API from '@api/dashboardVideos.api';
import corporateAPI from '@api/corporate.api';
import userAPI from '@api/user.api';

function initialState() {
  return {
    modalData: {},
    actionType: '',
    message: '',
    isActionAvailable: false,
    loading: false
  };
}

const getters = {
  modalData: (state) => state.modalData,
  actionType: (state) => state.actionType,
  message: (state) => state.message,
  isActionAvailable: (state) => state.isActionAvailable,
  loading: (state) => state.loading,
  isUserActiveRoleAgent: (state, vuexGetters, rootState, rootGetters) => rootGetters['user/isUserActiveRoleAgent']
};

const mutations = {
  SET_MODAL_DATA(state, payload) {
    state.modalData = payload;
  },
  SET_ACTION_TYPE(state, payload) {
    state.actionType = payload;
  },
  SET_ACTION_AVAILABILITY(state, payload) {
    state.isActionAvailable = payload;
  },
  SET_ACTION_MESSAGE(state, payload) {
    state.message = payload;
  },
  SET_LOADING(state, payload) {
    state.loading = payload;
  },
  RESET(state) {
    const initial = initialState();

    Object.keys(initial).forEach((key) => {
      state[key] = initial[key];
    });
  }
};

const actions = {
  async checkActionAvailability({ state, commit }, videoId) {
    commit('SET_LOADING', true);

    const errorMessages = [
      'Video is being edited. Please try moving it again later.',
      'Video is being edited. Please try copying it again later.',
      'This video has clips that are not uploaded yet.',
      'will be removed from playlist(s)'
    ];

    const actionTypeModalMessage = {
      move: 'Select a property from the list below to where the video will be moved.',
      copy: 'Select a property from the list below to where the video will be copied.'
    };

    const response = await API.checkVideoMoveCopyAvailability(videoId, {
      actionType: state.actionType
    });

    const isAvailable = !errorMessages.some((msg) => response?.data?.message?.includes(msg));

    // "isAvailable" is always true
    commit('SET_ACTION_AVAILABILITY', response?.data?.isAvailable || isAvailable);
    // In case video can not be moved we receive message
    commit('SET_ACTION_MESSAGE', response?.data?.message || actionTypeModalMessage[state.actionType]);
    commit('SET_LOADING', false);
  },
  async apiMoveVideoToProperty({ state }, managedPropertyId) {
    const response = await corporateAPI.moveVideo(state.modalData.id, managedPropertyId);

    return response;
  },
  async apiAgentMoveVideoToProperty({ state }, managedPropertyId) {
    const response = await userAPI.agentMoveVideo(state.modalData.id, managedPropertyId);

    return response;
  },
  async moveVideoToProperty({ dispatch, commit, state }, payload) {
    commit('SET_LOADING', true);
    const { agentId, managedPropertyId } = payload;

    const dispatchActions = {
      agentMove: 'apiAgentMoveVideoToProperty',
      managerCorpMove: 'apiMoveVideoToProperty'
    };

    const apiAction = state.modalData.isAgent
      ? dispatchActions.agentMove : dispatchActions.managerCorpMove;

    const response = await dispatch(apiAction, {
      agentId,
      managedPropertyId
    });

    commit('SET_LOADING', false);

    return response;
  },
  async copyVideoToProperties({ state, commit }, payload) {
    commit('SET_LOADING', true);
    const { managedPropertyIds, agentId } = payload;

    const api = state.modalData.isAgent ? userAPI : corporateAPI;

    const response = await api.copyVideo(state.modalData.id, {
      ...(agentId?.length && {
        agentId
      }),
      managedPropertyIds
    });

    commit('SET_LOADING', false);

    return response;
  },
  setModalData({ commit }, data) {
    commit('SET_MODAL_DATA', data);
  },
  setActionMessage({ commit }, payload) {
    commit('SET_ACTION_MESSAGE', payload || '');
  },
  setActionAvailability({ commit }, payload) {
    commit('SET_ACTION_AVAILABILITY', payload);
  },
  setActionType({ commit }, type) {
    commit('SET_ACTION_TYPE', type);
  },
  resetModal({ commit }) {
    commit('RESET');
  }
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  mutations,
  actions
};
