import apiClient from './apiClient';

export default {
  getGeneralHostVideoTypes: () => apiClient.get('/pre-recorded-video/host-video-types'),
  getHostVideoTypes: (params) => apiClient.get('/pre-recorded-video/property/host-video-types', { params }),
  getHostVideoTypeOptions: (hostVideoTypeId, params) => apiClient.get(
    `/pre-recorded-video/host-video-type/${hostVideoTypeId}/host-video-type-options`,
    { params }
  ),
  getHostVideoTypeDefaultSegments: (hostVideoTypeId) => apiClient.get(`/pre-recorded-video/host-video-type/${hostVideoTypeId}/default-segments`)
};
