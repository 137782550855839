import API from '@api/corporate.api';
import pagination from '../pagination';

function initialState() {
  return {
    ...pagination.state,
    videos: [],
    filter: '',
    propertyId: '',
    breadcrumbName: '',
    perPage: 24
  };
}

const getters = {
  ...pagination.getters,
  videos: (state) => state.videos,
  filter: (state) => state.filter,
  propertyId: (state) => state.propertyId,
  breadcrumbName: (state) => state.breadcrumbName
};

const mutations = {
  ...pagination.mutations,
  SET_VIDEOS(state, data) {
    state.videos = data;
  },
  SET_FILTER(state, filter) {
    state.filter = filter;
  },
  ADD_VIDEOS(state, data) {
    state.videos.push(...data);
  },
  SET_PROPERTY_ID(state, data) {
    state.propertyId = data;
  },
  SET_BREADCRUMB_NAME(state, data) {
    state.breadcrumbName = data;
  },
  RESET(state) {
    const initial = initialState();

    Object.keys(initial).forEach((key) => {
      if (key !== 'breadcrumbName') {
        state[key] = initial[key];
      }
    });
  }
};

const actions = {
  // eslint-disable-next-line no-shadow
  apiGetPropertyVideos({ state }, params) {
    const response = API.getPropertyVideos(
      params.propertyId,
      {
        perPage: state.perPage,
        ...params
      }
    );

    return response;
  },
  // eslint-disable-next-line object-curly-newline
  async getPropertyVideosList({ state, commit, dispatch }, params) {
    commit('SET_LOADING', true);

    const { data } = await dispatch('apiGetPropertyVideos', {
      propertyId: params.id,
      lsPropertyVideoList: true,
      page: state.page
    });

    commit('SET_SHOW_MORE', state.page < data.pagination.pages);
    commit('SET_VIDEOS', data.records);
    commit('SET_BREADCRUMB_NAME', params.name);
    commit('SET_PROPERTY_ID', params.id);
    commit('SET_LOADING', false);
  },
  async applyFilter({ state, commit, dispatch }, filters) {
    commit('SET_LOADING', true);
    filters.propertyId = state.propertyId;

    const { data } = await dispatch('apiGetPropertyVideos', {
      page: 1,
      lsPropertyVideoList: true,
      ...filters
    });

    commit('SET_PAGE', 1);
    commit('SET_FILTER', filters);
    commit('SET_SHOW_MORE', state.page < data.pagination.pages);
    commit('SET_VIDEOS', data.records);
    commit('SET_LOADING', false);
  },
  // eslint-disable-next-line object-curly-newline
  async fetchMore({ state, commit, dispatch }) {
    commit('SET_FETCHING_MORE', true);

    const nextPage = state.page + 1;

    const { data } = await dispatch('apiGetPropertyVideos', {
      propertyId: state.propertyId,
      lsPropertyVideoList: true,
      page: nextPage,
      ...state.filter
    });

    commit('SET_PAGE', nextPage);
    commit('SET_SHOW_MORE', state.page < data.pagination.pages);
    commit('ADD_VIDEOS', data.records);
    commit('SET_FETCHING_MORE', false);
  },
  resetPropertyVideos({ commit }) {
    commit('RESET');
  },
  setBreadcrumbName({ commit }, params) {
    commit('SET_BREADCRUMB_NAME', params);
  }
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  mutations,
  actions
};
