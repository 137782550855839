import {
  productionIntercomSecretId,
  developIntercomSecretId
} from '@helpers/constants';

function hideIntercom() {
  return window.location.href.includes('share')
    || window.location.href.includes('past_video')
    || window.location.href.includes('live')
    || window.location.href.includes('live_video');
}
const intercomInitialize = (currentUser) => {
  if (!currentUser) return;

  let appID;

  function isProduction() {
    return window.location.host === 'realync.com';
  }

  if (isProduction()) {
    appID = productionIntercomSecretId;
  } else {
    appID = developIntercomSecretId;
  }

  window.intercomSettings = { app_id: appID };

  if (currentUser) {
    window.intercomSettings.id = currentUser.id;
    window.intercomSettings.email = currentUser.email;
    window.intercomSettings.name = currentUser.name;
    window.intercomSettings.agent = currentUser.realtor ? 'YES' : 'NO';
    window.intercomSettings.is_client = 'YES';
    window.intercomSettings.hide_default_launcher = hideIntercom();
  }
  /* eslint-disable */
  (function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',intercomSettings);}else{var d=document;var i=function(){i.c(arguments)};i.q=[];i.c=function(args){i.q.push(args)};w.Intercom=i;function l(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/' + appID;var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);}if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})()
};

export default intercomInitialize;
