import Vue from 'vue';
import VueRouter from 'vue-router';
import { tryAuthWithCallback } from '@/api/apiClient';
import { browserNotSupported, isMobile } from '@/helpers/browserSupport';
import { isWebView } from '@/helpers/isWebView';

import {
  videoLinkRoute,
  videoEditRoutes,
  reviewRedirectRoute,
  inAppRoutes,
  publicRoutes
} from './routes';

Vue.use(VueRouter);

const routes = [
  videoLinkRoute,
  videoEditRoutes,
  reviewRedirectRoute,
  publicRoutes,
  inAppRoutes
];

const router = new VueRouter({
  mode: 'history',
  base: '/',
  routes,
  strict: true
});

const browserNotSupportedPath = /\/browser_not_supported/;
const singleSignOnPath = /\/single-sign-on/;
const oldVideoFileUrl = /\/share#!\/videoFile\//;

router.beforeEach(async (to, from, next) => {
  const matchBrowserNotSupported = browserNotSupportedPath.test(to.path);
  const matchSingleSignOn = singleSignOnPath.test(to.path);

  if (to.path === '/logout') {
    localStorage.clear();

    next({
      name: 'SignIn',
      replace: true
    });

    return;
  }

  const {
    skipBrowserNotSupported = false,
    mobileNotSupported = false
  } = to.meta;

  await tryAuthWithCallback((authorized) => {
    if (to.meta.redirectIfAuthorized && authorized) {
      next({
        name: 'Home',
        replace: true
      });
    } else if (!to.meta.publicPage && !authorized) {
      localStorage.clear();

      next({
        name: 'SignIn',
        replace: true,
        query: {
          unauthorized: true
        }
      });
    }
  });

  if (oldVideoFileUrl.test(to.fullPath)) {
    const videoId = to.fullPath.split(oldVideoFileUrl)[1];

    next({
      name: 'VideoLink',
      replace: true,
      params: { videoId }
    });
  }

  if (matchSingleSignOn && isWebView()) {
    next();

    return;
  }

  const redirectToBrowserNotSupported = !skipBrowserNotSupported
    && !matchBrowserNotSupported
    && (browserNotSupported() || (mobileNotSupported && isMobile()));

  if (redirectToBrowserNotSupported) {
    next({
      name: 'BrowserNotSupported',
      replace: true,
      query: {
        from: to.name
      }
    });
  }

  if (to.fullPath === '/') {
    next({
      name: 'SignIn',
      replace: true
    });
  }

  next();
});

export default router;
