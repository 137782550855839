import API from '@api/dashboardVideos.api';
import pagination from '../pagination';

function initialState() {
  return {
    ...pagination.state,
    playlists: [],
    filter: '',
    sourceType: 'all',
    savingPlaylist: false,
    formPlaylist: {}
  };
}

const getters = {
  ...pagination.getters,
  playlists: (state) => state.playlists,
  filter: (state) => state.filter,
  savingPlaylist: (state) => state.savingPlaylist,
  formPlaylist: (state) => state.formPlaylist,
  sourceType: (state) => state.sourceType
};

const mutations = {
  ...pagination.mutations,
  SET_PLAYLISTS(state, data) {
    state.playlists = data;
  },
  SET_FILTER(state, filter) {
    state.filter = filter;
  },
  SET_SOURCE_TYPE(state, sourceType) {
    state.sourceType = sourceType;
  },
  ADD_PLAYLISTS(state, data) {
    state.playlists.push(...data);
  },
  SET_SAVING_PLAYLIST(state, updating) {
    state.savingPlaylist = updating;
  },
  SET_FORM_PLAYLIST(state, playlist) {
    state.formPlaylist = playlist;
  },
  RESET(state) {
    const initial = initialState();

    Object.keys(initial).forEach((key) => {
      state[key] = initial[key];
    });
  }
};

const actions = {
  async apiGetPlaylists({ state }, params) {
    const response = await API.getPlaylistsList(
      {
        source: state.sourceType,
        perPage: state.perPage,
        ...params
      }
    );

    return response;
  },
  async getPlaylistsList({ state, commit, dispatch }) {
    commit('SET_LOADING', true);

    const response = await dispatch('apiGetPlaylists', {
      page: 1,
      q: state.filter
    });

    if (response.status === 200) {
      const { data } = response;

      commit('SET_PAGE', 1);
      commit('SET_SHOW_MORE', state.page < data.pagination.pages);
      commit('SET_PLAYLISTS', data.playlists);
    }

    commit('SET_LOADING', false);
  },
  async applyFilter({ state, commit, dispatch }, filter) {
    commit('SET_LOADING', true);

    const response = await dispatch('apiGetPlaylists', {
      page: 1,
      q: filter
    });

    if (response.status === 200) {
      const { data } = response;

      commit('SET_PAGE', 1);
      commit('SET_FILTER', filter);
      commit('SET_SHOW_MORE', state.page < data.pagination.pages);
      commit('SET_PLAYLISTS', data.playlists);
    }

    commit('SET_LOADING', false);
  },
  async setSourceType({ commit }, sourceType) {
    await commit('SET_SOURCE_TYPE', sourceType);
  },
  async fetchMore({ state, commit, dispatch }) {
    commit('SET_FETCHING_MORE', true);

    const nextPage = state.page + 1;

    const response = await dispatch('apiGetPlaylists', {
      page: nextPage,
      q: state.filter
    });

    if (response.status === 200) {
      const { data } = response;

      commit('SET_PAGE', nextPage);
      commit('SET_SHOW_MORE', state.page < data.pagination.pages);
      commit('ADD_PLAYLISTS', data.playlists);
    }

    commit('SET_FETCHING_MORE', false);
  },
  async deletePlaylist({ state, commit }, playlistId) {
    const response = await API.deletePlaylist(playlistId);

    if (response.status === 200) {
      commit('SET_PLAYLISTS', state.playlists.filter((el) => (el.id !== playlistId)));
    }

    return response;
  },
  updatePlaylistList({ commit, dispatch }) {
    commit('SET_SOURCE_TYPE', 'all');
    commit('SET_PAGE', 1);
    dispatch('getPlaylistsList');
  },
  resetPlaylistsList({ commit }) {
    commit('RESET');
  }
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  mutations,
  actions
};
