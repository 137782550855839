import moment from 'moment-timezone';
import API from '@api/dashboardLiveSessions.api';
import APILiveSession from '@api/liveSession.api';
import APIAttachments from '@api/attachments.api';
import userAPI from '@api/user.api';

function initialState() {
  return {
    liveSession: {
      fullAddress: null,
      attachmentIds: [],
      attendees: [],
      date: null,
      id: null,
      note: '',
      title: '',
      time: moment().format('HH:mm:ss'),
      startImmediately: false,
      unitId: null,
      building: null
    },
    attachmentsTab: 'agenda',
    edit: false,
    editableAttachment: {},
    editLiveSessionTypeChanged: false,
    fullAddressValid: true,
    isValid: null,
    newAgenda: {},
    selectedAgenda: [],
    persisted: false,
    privateLiveSessionHasAttendees: null,
    recentAttendees: [],
    saving: false,
    savingAttachment: false,
    type: 'private',
    validationError: null,
    validationErrorType: null,
    validationErrorFields: [],
    validDateTime: null
  };
}

const moduleGetters = {
  attendees: (state) => state.liveSession.attendees,
  edit: (state) => state.edit,
  editableAttachment: (state) => state.editableAttachment,
  editLiveSessionTypeChanged: (state) => state.editLiveSessionTypeChanged,
  fullAddressValid: (state) => state.fullAddressValid,
  fullDate: (state) => `${state.liveSession.date} ${state.liveSession.time}`,
  isPublic: (state) => state.type === 'public',
  isTab: (state) => (tab) => state.attachmentsTab === tab,
  isValid: (state) => state.isValid,
  liveSession: (state) => state.liveSession,
  newAgenda: (state) => state.newAgenda,
  persisted: (state) => state.persisted,
  privateLiveSessionHasAttendees: (state) => state.privateLiveSessionHasAttendees,
  recentAttendees: (state) => state.recentAttendees,
  saving: (state) => state.saving,
  savingAttachment: (state) => state.savingAttachment,
  type: (state) => state.type,
  validationError: (state) => state.validationError,
  validationErrorType: (state) => state.validationErrorType,
  validationErrorFields: (state) => state.validationErrorFields,
  validDateTime: (state) => state.validDateTime
};

const mutations = {
  SET_LIVE_SESSION(state, formData) {
    state.liveSession = formData;
  },
  SET_LIVE_SESSION_TYPE(state, type) {
    state.type = type;
    state.liveSession.startImmediately = type === 'public';
  },
  SET_EDIT(state, edit) {
    state.edit = edit;
  },
  SET_EDIT_LIVE_SESSION_TYPE_CHANGED(state) {
    if (state.edit) state.editLiveSessionTypeChanged = true;
  },
  SET_ATTACHMENTS_TAB(state, tab) {
    state.attachmentsTab = tab;
  },
  UPDATE_LIVE_SESSION_FORM(state, data) {
    state.liveSession = { ...state.liveSession, ...data };
  },
  ADD_ATTENDEE(state, data) {
    state.liveSession.attendees.push(data);
  },
  REMOVE_ATTENDEE(state, email) {
    const index = state.liveSession.attendees.findIndex((attendee) => attendee.email === email);

    state.liveSession.attendees.splice(index, 1);
  },
  ADD_ATTACHMENT(state, data) {
    state.liveSession.attachmentIds.push(data);
  },
  REMOVE_ATTACHMENT(state, data) {
    state.liveSession.attachmentIds.splice(state.liveSession.attachmentIds.indexOf(data), 1);
  },
  SET_RECENT_ATTENDEES(state, recent) {
    state.recentAttendees = recent;
  },
  SET_PERSISTED(state, persisted) {
    state.persisted = persisted;
  },
  TOGGLE_SAVING(state) {
    state.saving = !state.saving;
  },
  SET_SAVING_ATTACHMENT(state, savingAttachment) {
    state.savingAttachment = savingAttachment;
  },
  SET_EDITABLE_ATTACHMENT(state, attachment) {
    state.editableAttachment = attachment;
  },
  SET_NEW_AGENDA(state, data) {
    state.newAgenda = data;
  },
  SET_SELECTED_AGENDA(state, data) {
    state.selectedAgenda = data;
  },
  SET_VALID(state, isValid) {
    state.isValid = isValid;
  },
  SET_VALID_DATETIME(state, valid) {
    state.validDateTime = valid;
  },
  SET_PRIVATE_LIVE_SESSION_HAS_ATTENDEES(state, valid) {
    state.privateLiveSessionHasAttendees = valid;
  },
  SET_VALIDATION_ERROR(state, errorMessage) {
    state.validationError = errorMessage;
  },
  SET_VALIDATION_ERROR_TYPE(state, errorType) {
    state.validationErrorType = errorType;
  },
  SET_FULL_ADDRESS_VALID(state, valid) {
    state.fullAddressValid = valid;
  },
  SET_VALIDATION_ERROR_FIELDS(state, fields) {
    state.validationErrorFields = fields;
  },
  RESET(state) {
    const initial = initialState();

    Object.keys(initial).forEach((key) => {
      state[key] = initial[key];
    });
  }
};

const actions = {
  async buildRequestObj({ state }) {
    const {
      attendees,
      fullAddress,
      attachmentIds,
      date,
      time,
      title,
      startImmediately,
      unitId
    } = state.liveSession;
    const startAt = moment(`${date} ${time}`).utc().format();
    const prospects = startImmediately ? [] : attendees;

    return {
      attachmentIds,
      fullAddress,
      prospects,
      startAt,
      startImmediately,
      title,
      unitId
    };
  },
  async createLiveSession({ commit, dispatch, state }) {
    commit('TOGGLE_SAVING');

    const requestObj = await dispatch('buildRequestObj');

    if (!requestObj.unitId?.length) {
      delete requestObj.unitId;
    }

    const { data: { record } } = await API.createLiveSession(requestObj);

    await APILiveSession.updateLiveSessionNote(record.id, { note: state.liveSession.note });
    await dispatch('processAgenda', record.id);

    if (requestObj.startImmediately) {
      await dispatch('modals/setShareSocialRecord', {
        ...record,
        isLiveSession: true,
        startDate: record.startAt
      }, { root: true });
    }

    commit('SET_PERSISTED', true);
    commit('TOGGLE_SAVING');
  },
  async updateLiveSession({ commit, dispatch, state }) {
    commit('TOGGLE_SAVING');

    const requestObj = await dispatch('buildRequestObj');
    const liveSessionId = state.liveSession.id;

    await API.updateLiveSession(liveSessionId, requestObj);
    await APILiveSession.updateLiveSessionNote(liveSessionId, { note: state.liveSession.note });
    await dispatch('processAgenda', liveSessionId);

    commit('SET_PERSISTED', true);
    commit('TOGGLE_SAVING');
  },
  async setLiveSession({ commit }, payload) {
    commit('SET_LIVE_SESSION', payload);
  },
  async updateLiveSessionTitle({ commit, dispatch, state }, title) {
    commit('TOGGLE_SAVING');

    const liveSessionId = state.liveSession.id;

    const response = await API.updateLiveSessionTitle(liveSessionId, { title });

    if (response.status === 200) {
      dispatch(
        'dashboardLiveSessionList/updateRecordInTheList',
        { id: liveSessionId, title },
        { root: true }
      );
    }

    commit('TOGGLE_SAVING');
  },
  async uploadAttachment({ commit }, params) {
    const { data: { record: attachment } } = await APIAttachments.createAttachment(params);

    commit('ADD_ATTACHMENT', attachment.id);

    return attachment;
  },
  async getDefaultAttachments() {
    const { data: { records: attachments } } = await APIAttachments.getAttachments();

    return attachments;
  },
  // eslint-disable-next-line no-empty-pattern
  async getAttachments({}, liveSessionId) {
    const {
      data: { records: attachments }
    } = await APILiveSession.getAttachments(liveSessionId);

    return attachments;
  },
  async getLiveSession({ commit, state }, liveSessionId) {
    const { data: { record } } = await API.getLiveSession(liveSessionId);
    const { data: { record: noteRecord } } = await APILiveSession.getLiveSessionNote(liveSessionId);

    const {
      id, attachmentIds: attachmentIdsOriginal, fullAddress,
      prospects, startAt, startImmediately, title, unitId, building
    } = record;

    const attendees = prospects
      .filter((attendee) => attendee.status !== 'host')
      .map((attendee) => {
        const { email, name } = attendee;

        return { email, name };
      });
    const attachmentIds = attachmentIdsOriginal || [];
    const timezone = moment.tz.guess();
    const dateTime = moment(startAt).tz(timezone);
    const date = dateTime.format('Y-MM-DD');
    const time = dateTime.format('HH:mm:ss');
    const type = startImmediately ? 'public' : 'private';
    const note = noteRecord ? noteRecord.content : '';

    commit('SET_LIVE_SESSION', {
      ...(building ? {
        building
      } : {
        building: state.liveSession.building
      }),
      id,
      attachmentIds,
      attendees,
      date,
      time,
      title,
      fullAddress,
      note,
      unitId
    });
    commit('SET_LIVE_SESSION_TYPE', type);
  },
  async deleteLiveSession({ dispatch }, liveSessionId) {
    await API.deleteLiveSession(liveSessionId);

    dispatch(
      'dashboardLiveSessionList/removeLiveSessionFromTheList',
      liveSessionId,
      { root: true }
    );
  },
  async getRecentAttendees({ commit }) {
    const { data: { records } } = await userAPI.getRecentAttendees();

    commit('SET_RECENT_ATTENDEES', records);
  },
  setEditableAttachment({ commit }, params) {
    commit('SET_EDITABLE_ATTACHMENT', params);
  },
  async updateAttachment({ commit, state }, params) {
    commit('SET_SAVING_ATTACHMENT', true);

    const { id, ...data } = params;
    await APIAttachments.updateAttachment(id, data);

    commit('SET_EDITABLE_ATTACHMENT', { ...state.editableAttachment, ...data });
    commit('SET_SAVING_ATTACHMENT', false);
  },
  setEdit({ commit }) {
    commit('SET_EDIT', true);
  },
  setEditLiveSessionTypeChanged({ commit }) {
    commit('SET_EDIT_LIVE_SESSION_TYPE_CHANGED');
  },
  setValid({ commit }, params) {
    commit('SET_VALID', params.valid);
    commit('SET_VALIDATION_ERROR', params.errorMessage || null);
    commit('SET_VALIDATION_ERROR_TYPE', params.errorType || null);
    commit('SET_VALIDATION_ERROR_FIELDS', params.errorFields || []);
  },
  setLiveSessionType({ commit }, type) {
    commit('SET_LIVE_SESSION_TYPE', type);
  },
  setAttachmentsTab({ commit }, tab) {
    commit('SET_ATTACHMENTS_TAB', tab);
  },
  updateLiveSessionForm({ commit }, data) {
    commit('UPDATE_LIVE_SESSION_FORM', data);
  },
  addAttendee({ commit }, data) {
    commit('ADD_ATTENDEE', data);
  },
  removeAttendee({ commit }, data) {
    commit('REMOVE_ATTENDEE', data);
  },
  addAttachment({ commit }, attachmentId) {
    commit('ADD_ATTACHMENT', attachmentId);
  },
  removeAttachment({ commit }, attachmentId) {
    commit('REMOVE_ATTACHMENT', attachmentId);
  },
  validateDateTime({ commit, state }) {
    const { date, time } = state.liveSession;
    let valid = null;

    if (date && time) {
      valid = moment(`${date} ${time}`).isAfter(moment());
    }

    commit('SET_VALID_DATETIME', valid);
  },
  validatePrivateLiveSessionAttendees({ commit, state }) {
    let valid = true;

    if (!(state.type === 'public')) {
      valid = state.liveSession.attendees.length > 0;
    }

    commit('SET_PRIVATE_LIVE_SESSION_HAS_ATTENDEES', valid);
  },
  validateFullAddress({ commit, state }) {
    let valid = true;

    const address = state.liveSession.fullAddress && state.liveSession.fullAddress.trim();

    if (!address || address.length === 0) {
      valid = false;
    }

    commit('SET_FULL_ADDRESS_VALID', valid);
  },
  async validateForm({ dispatch, state }) {
    await dispatch('validateDateTime');
    await dispatch('validatePrivateLiveSessionAttendees');

    if (state.validDateTime && state.privateLiveSessionHasAttendees) {
      dispatch('setValid', { valid: true });
    }

    if (!state.validDateTime) {
      const errorFields = [];
      let errorMessage = "You can't create a Live Session in the past!";
      let errorType = 'dateTimeInThePast';

      if (!state.liveSession.date || !state.liveSession.time) {
        if (!state.liveSession.date) errorFields.push('date');
        if (!state.liveSession.time) errorFields.push('time');

        errorMessage = `Missing required fields: ${errorFields.join(', ')}`;
        errorType = 'missingDateTimeFields';
      }
      dispatch('setValid', {
        valid: false,
        errorMessage,
        errorType,
        errorFields

      });
    } else if (!state.privateLiveSessionHasAttendees) {
      dispatch('setValid', {
        valid: false,
        errorMessage: 'Please Invite Attendees to the Private Live Session!',
        errorType: 'noAttendees'
      });
    }
  },
  removeFromValidationErrorFields({ commit, state }, field) {
    const indexOfField = state.validationErrorFields.indexOf(field);

    if (indexOfField >= 0) {
      const newFields = [...state.validationErrorFields];
      newFields.splice(indexOfField, 1);

      commit('SET_VALIDATION_ERROR_FIELDS', newFields);
    }
  },
  clearValidationError({ dispatch, state }, type) {
    if (state.validationErrorType === type) {
      dispatch('setValid', {
        valid: true,
        errorMessage: null,
        errorType: null,
        errorFields: null
      });
    }
  },
  async getAgendaList(context, params) {
    const { data } = await API.getAgendaList(params);

    const serializedResponse = data.records?.map((agenda) => {
      const normalized = {
        ...agenda,
        uid: Math.random().toString(36).substring(2),
        startAt: moment(agenda.startAt).format('DD MMMM YYYY hh:mm a')
      };

      return normalized;
    }).filter((r) => (r.videos.length + r.playlists.length) > 0);

    return serializedResponse || [];
  },
  getAgendaParams({ state }) {
    let videoIds = [];
    let playlistIds = [];

    if (state.selectedAgenda.length) {
      const { videos, playlists } = state.selectedAgenda[0];

      if (videos?.length) {
        videoIds = videos.map((v) => v.id);
      }

      if (playlists?.length) {
        playlistIds = playlists.map((v) => v.id);
      }
    }

    return { videoIds, playlistIds };
  },
  async processAgenda({ dispatch }, liveSessionId) {
    const params = await dispatch('getAgendaParams');

    await API.processAgenda(
      liveSessionId,
      params
    );
  },
  setNewAgenda({ commit }, data) {
    commit('SET_NEW_AGENDA', data);
  },
  setSelectedAgenda({ commit }, data) {
    commit('SET_SELECTED_AGENDA', data);
  },
  resetLiveSessionForm({ commit }) {
    commit('RESET');
  }
};

export default {
  namespaced: true,
  state: initialState,
  getters: moduleGetters,
  mutations,
  actions
};
