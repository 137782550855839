import API from '@api/liveSession.api';
import pagination from '../pagination';

function initialState() {
  return {
    ...pagination.state,
    videos: [],
    filter: '',
    managedPropertyId: null,
    perPage: 24
  };
}

const getters = {
  ...pagination.getters,
  videos: (state) => state.videos,
  filter: (state) => state.filter,
  currentFolderId: (state, moduleGetters, rootState) => rootState.subFolderNavigation.currentFolderId,
  getAction: (state, moduleGetters, rootState) => (
    rootState.subFolderNavigation.subFolderOpened
      ? 'apiGetSubVideos'
      : 'apiGetVideos'
  )
};

const mutations = {
  ...pagination.mutations,
  SET_VIDEOS(state, data) {
    state.videos = data;
  },
  SET_FILTER(state, filter) {
    state.filter = filter;
  },
  SET_MANAGED_PROPERTY_FILTER(state, payload) {
    state.managedPropertyId = payload;
  },
  ADD_VIDEOS(state, data) {
    state.videos.push(...data);
  },
  RESET(state) {
    const initial = initialState();

    Object.keys(initial).forEach((key) => {
      // we don`t need to reset this on full library
      if (key !== 'managedPropertyId') {
        state[key] = initial[key];
      }
    });
  }
};

const actions = {
  async apiGetVideos({ state }, params) {
    params.search = params.q;
    delete params.q;
    if (!params.search || (params.search && (params.search.length === 0))) delete params.search;

    const { data } = await API.getFullLibraryVideos({
      perPage: state.perPage,
      ...params
    });

    return data;
  },
  async apiGetSubVideos({ commit, state, rootState }, params) {
    // we don't want to search with this request
    params.search = params.q;
    delete params.q;
    if (!params.search || (params.search && (params.search.length === 0))) delete params.search;

    // reset filter when navigating to Sub Folder
    commit('SET_FILTER', '');

    const { data } = await API.getFolderVideos(
      rootState.subFolderNavigation.currentFolderId,
      {
        perPage: state.perPage,
        ...params
      }
    );

    return data;
  },
  async getLiveSessionVideosList({
    state,
    commit,
    dispatch,
    // eslint-disable-next-line no-shadow
    getters
  }, params) {
    commit('SET_LOADING', true);

    const data = await dispatch(getters.getAction, {
      page: state.page,
      search: state.filter,
      ...(params?.managedPropertyId?.length && {
        managedPropertyId: params.managedPropertyId
      })
    });

    if (params?.managedPropertyId?.length) {
      commit('SET_MANAGED_PROPERTY_FILTER', params.managedPropertyId);
    }

    commit('SET_SHOW_MORE', state.page < data.pagination.pages);
    commit('SET_VIDEOS', data.records);
    commit('SET_LOADING', false);
  },
  async applyFilter({ state, commit, dispatch }, filters) {
    commit('SET_LOADING', true);

    const data = await dispatch('apiGetVideos', {
      page: 1,
      ...filters,
      ...(state.managedPropertyId && {
        managedPropertyId: state.managedPropertyId
      })
    });

    commit('SET_PAGE', 1);
    commit('SET_FILTER', filters);
    commit('SET_SHOW_MORE', state.page < data.pagination.pages);
    commit('SET_VIDEOS', data.records);
    commit('SET_LOADING', false);
  },
  async fetchMore({
    state,
    commit,
    dispatch,
    // eslint-disable-next-line no-shadow
    getters
  }) {
    commit('SET_FETCHING_MORE', true);

    const nextPage = state.page + 1;

    const data = await dispatch(getters.getAction, {
      page: nextPage,
      ...state.filter,
      ...(state.managedPropertyId && {
        managedPropertyId: state.managedPropertyId
      })
    });

    commit('SET_PAGE', nextPage);
    commit('SET_SHOW_MORE', state.page < data.pagination.pages);
    commit('ADD_VIDEOS', data.records);
    commit('SET_FETCHING_MORE', false);
  },
  updateRecords({ commit }, value) {
    commit('SET_VIDEOS', value);
  },
  resetVideos({ commit }) {
    commit('RESET');
  }
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  mutations,
  actions
};
