/* eslint no-shadow: ["error", { "allow": ["state"] }] */

import API from '@api/recipients.api';

function initialState() {
  return {
    records: [],
    currentRecord: {},
    saving: false,
    trackingType: ''
  };
}

const getters = {
  records: (state) => state.records,
  saving: (state) => state.saving,
  currentRecipient: (state) => state.currentRecord,
  trackingType: (state) => state.trackingType
};

const mutations = {
  SET_RECIPIENTS(state, records) {
    state.records = records;
  },
  SET_CURRENT_RECIPIENT(state, record) {
    state.currentRecord = record;
  },
  UPDATE_RECIPIENT(state, params) {
    const recordId = params.id || params.recipientId;
    state.records = state.records.map((recipient) => {
      if (recipient.id === recordId) {
        return { ...recipient, ...params };
      }

      return recipient;
    });
  },
  SET_TRACKING_TYPE(state, payload) {
    if (['VR', 'Click Through'].includes(payload)) {
      state.trackingType = 'video';
    } else {
      state.trackingType = payload;
    }
  },
  TOGGLE_SAVING(state, saving) {
    state.saving = saving;
  },
  RESET(state) {
    const initial = initialState();

    Object.keys(initial).forEach((key) => {
      state[key] = initial[key];
    });
  }
};

const actions = {
  setRecipients({ commit }, data) {
    commit('SET_RECIPIENTS', data);
  },
  setTrackingType({ commit }, payload) {
    commit('SET_TRACKING_TYPE', payload);
  },
  setCurrentRecipient({ commit }, data) {
    commit('SET_CURRENT_RECIPIENT', data);
  },
  async saveRecipient({ commit }, params) {
    commit('TOGGLE_SAVING', true);

    const response = await API.saveRecipients(params);

    commit('TOGGLE_SAVING', false);

    return response;
  },
  async removeTracking({ commit }, params) {
    commit('UPDATE_RECIPIENT', params);

    const response = await API.removeTracking(params);

    return response;
  },
  async updateRecipient({ state, commit }, params) {
    commit('TOGGLE_SAVING', true);

    const { trackingId, ...data } = params;
    const response = await API.updateRecipient(trackingId, {
      ...data,
      trackingType: state.trackingType
    });

    if (response.status === 200) {
      commit('UPDATE_RECIPIENT', params);
    }

    commit('TOGGLE_SAVING', false);

    return response;
  },
  async updateTracking({ commit }, params) {
    commit('TOGGLE_SAVING', true);

    const { id, ...data } = params;
    const response = await API.updateTracking(id, { ...data });

    commit('TOGGLE_SAVING', false);

    return response;
  },
  async trackCopy({ commit }, params) {
    const { trackingId, ...data } = params;
    await API.trackCopy(trackingId, data);

    commit('UPDATE_RECIPIENT', { trackingId, copiedLink: true });
  },
  async trackCopyGif({ commit }, trackingId) {
    await API.trackCopyGif(trackingId);

    commit('UPDATE_RECIPIENT', { trackingId, copyWithGif: true });
  },
  toggleSaving({ commit }, payload) {
    commit('TOGGLE_SAVING', payload);
  },
  resetRecipients({ commit }) {
    commit('RESET');
  }
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  mutations,
  actions
};
