import API from '@api/dashboardVideos.api';
import pagination from '../pagination';

function initialState() {
  return {
    ...pagination.state,
    folders: [],
    filters: {},
    savingFolder: false,
    savingSubFolder: false,
    formFolder: {}
  };
}

const getters = {
  ...pagination.getters,
  folders: (state) => state.folders,
  filters: (state) => state.filters,
  formFolder: (state) => state.formFolder,
  savingFolder: (state) => state.savingFolder,
  savingSubFolder: (state) => state.savingSubFolder
};

const mutations = {
  ...pagination.mutations,
  SET_FOLDERS(state, data) {
    state.folders = data;
  },
  SET_FILTERS(state, filters) {
    state.filters = filters;
  },
  ADD_FOLDERS(state, data) {
    state.folders.push(...data);
  },
  SET_FORM_FOLDER(state, folder) {
    state.formFolder = folder;
  },
  SET_SAVING_FOLDER(state, saving) {
    state.savingFolder = saving;
  },
  SET_SAVING_SUB_FOLDER(state, saving) {
    state.savingSubFolder = saving;
  },
  RESET(state) {
    const initial = initialState();

    Object.keys(initial).forEach((key) => {
      state[key] = initial[key];
    });
  }
};

const actions = {
  async apiGetFolders({ state }, params = {}) {
    const response = await API.getFoldersList(
      {
        page: 1,
        perPage: state.perPage,
        ...params
      }
    );

    return response;
  },
  async getFoldersList({ state, commit, dispatch }) {
    commit('SET_LOADING', true);

    const response = await dispatch('apiGetFolders', {
      ...state.filters
    });

    if (response.status === 200) {
      const { data } = response;

      commit('SET_PAGE', 1);
      commit('SET_FOLDERS', data.folders);
      commit('SET_SHOW_MORE', state.page < data.pagination.pages);
    }

    commit('SET_LOADING', false);
  },
  async applyFilters({ state, commit, dispatch }, filters) {
    commit('SET_LOADING', true);

    const response = await dispatch('apiGetFolders', {
      ...filters
    });

    if (response.status === 200) {
      const { data } = response;

      commit('SET_FILTERS', filters);
      commit('SET_PAGE', 1);
      commit('SET_SHOW_MORE', state.page < data.pagination.pages);
      commit('SET_FOLDERS', data.folders);
    }

    commit('SET_LOADING', false);
  },
  async fetchMore({ state, commit, dispatch }) {
    commit('SET_FETCHING_MORE', true);

    const nextPage = state.page + 1;

    const response = await dispatch('apiGetFolders', {
      page: nextPage,
      ...state.filters
    });

    if (response.status === 200) {
      const { data } = response;

      commit('SET_PAGE', nextPage);
      commit('SET_SHOW_MORE', state.page < data.pagination.pages);
      commit('ADD_FOLDERS', data.folders);
    }

    commit('SET_FETCHING_MORE', false);
  },
  async createFolder({ commit }, params) {
    const commitSaveAction = `SET_SAVING_${params.isSubFolder ? 'SUB_' : ''}FOLDER`;

    commit(commitSaveAction, true);

    const response = await API.createFolder(params.form);

    commit(commitSaveAction, false);

    return response;
  },
  async updateFolder({ commit, state }, params) {
    const commitSaveAction = `SET_SAVING_${params.isSubFolder ? 'SUB_' : ''}FOLDER`;

    commit(commitSaveAction, true);

    const response = await API.updateFolder(state.formFolder.id, params.form);

    commit(commitSaveAction, false);

    return response;
  },
  async setFormFolder({ commit }, folder) {
    await commit('SET_FORM_FOLDER', folder);
  },
  resetAll({ commit }) {
    commit('RESET');
    commit('dashboardVideosTabFoldersSubFolders/RESET', null, { root: true });
    commit('dashboardVideosTabFoldersSubVideos/RESET', null, { root: true });
    commit('subFolderNavigation/RESET', null, { root: true });
  }
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  mutations,
  actions
};
