import API from '@api/dashboardVideosDeleted.api';
import APICorporate from '@api/corporate.api';
import pagination from '../pagination';

function initialState() {
  return {
    ...pagination.state,
    videos: [],
    videosStatus: null,
    loading: false
  };
}

const moduleGetters = {
  ...pagination.getters,
  videos: (state) => state.videos,
  loading: (state) => state.loading,
  getListAction: (state, vuexGetters, rootState) => (
    rootState.manageDeletedContent.isPropertyTab
      ? 'apiGetPropertyDeletedVideos'
      : 'apiGetDeletedVideos'
  )
};

const mutations = {
  ...pagination.mutations,
  SET_VIDEOS(state, payload) {
    state.videos = payload;
  },
  ADD_VIDEOS(state, payload) {
    state.videos.push(...payload);
  },
  SET_LOADING(state, payload) {
    state.loading = payload;
  },
  SET_VIDEOS_STATUS(state, payload) {
    state.videosStatus = payload;
  },
  RESET(state) {
    const initial = initialState();

    Object.keys(initial).forEach((key) => {
      state[key] = initial[key];
    });
  }
};

const actions = {
  async apiGetDeletedVideos({ state }, params) {
    const response = await API.getDeletedVideos(
      {
        perPage: state.perPage,
        ...(state.videosStatus && {
          status: state.videosStatus
        }),
        ...params
      }
    );

    return response;
  },
  async apiGetPropertyDeletedVideos({ state, rootState }, params) {
    const response = await APICorporate.getPropertyDeletedVideos(
      rootState.property.property.id,
      { perPage: state.perPage, ...params }
    );

    return response;
  },
  // eslint-disable-next-line object-curly-newline, no-shadow
  async getDeletedVideos({ state, commit, getters, dispatch }, payload = {}) {
    commit('SET_LOADING', true);

    const response = await dispatch(getters.getListAction, {
      page: 1,
      ...payload
    });

    if (response.status === 200) {
      const { data } = response;

      commit('SET_PAGE', 1);
      commit('SET_SHOW_MORE', state.page < data.pagination.pages);
      commit('SET_VIDEOS', data.records);
    }

    commit('SET_LOADING', false);

    return response;
  },
  async restoreMedia({ commit }, data) {
    commit('SET_LOADING', true);
    const response = await API.restoreMedia(data);

    commit('SET_LOADING', false);
    return response;
  },
  async destroyVideos({ commit }, params) {
    commit('SET_LOADING', true);
    const response = await API.destroyVideos(params);

    commit('SET_LOADING', false);
    return response;
  },
  // eslint-disable-next-line object-curly-newline, no-shadow
  async fetchMore({ state, commit, getters, dispatch }) {
    commit('SET_FETCHING_MORE', true);

    const nextPage = state.page + 1;

    const response = await dispatch(getters.getListAction, {
      page: nextPage
    });

    if (response.status === 200) {
      const { data } = response;

      commit('SET_PAGE', nextPage);
      commit('SET_SHOW_MORE', state.page < data.pagination.pages);
      commit('ADD_VIDEOS', data.records);
    }

    commit('SET_FETCHING_MORE', false);
  },
  async setVideosStatus({ commit }, status) {
    commit('SET_VIDEOS_STATUS', status);
  },
  resetDeletedVideosState({ commit }) {
    commit('RESET');
  }
};

export default {
  namespaced: true,
  state: initialState,
  getters: moduleGetters,
  mutations,
  actions
};
