<template>
  <span class="flex items-center mb-2">
    <svg class="shrink-0 w-4 h-4 mr-1 fill-dark-400">
      <use xlink:href="#icon-info-solid"></use>
    </svg>
    <span
      class="text-1xs text-dark-500"
      v-html="text"
    />
  </span>
</template>

<script>

export default {
  name: 'InfoSpan',
  props: {
    text: {
      type: String,
      default: ''
    }
  }
};
</script>
