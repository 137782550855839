import API from '@api/videoGif.api';

const initialState = () => ({
  modalData: {},
  gifJobData: null,
  gifUrl: '',
  gifLink: '',
  defaultLink: '',
  loading: false
});

const getters = {
  modalData: (state) => state.modalData,
  gifJobData: (state) => state.gifJobData,
  gifUrl: (state) => state.gifJobData?.gifUrl,
  gifLink: (state) => state.gifLink,
  defaultLink: (state) => state.defaultLink,
  gifLoading: (state) => state.loading
};

const mutations = {
  SET_MODAL_DATA(state, payload) {
    state.modalData = payload;
  },
  SET_GIF_JOB_DATA(state, payload) {
    state.gifJobData = payload;
  },
  SET_GIF_LINK(state, payload) {
    state.gifLink = payload;
  },
  SET_DEFAULT_LINK(state, payload) {
    state.defaultLink = payload;
  },
  SET_LOADING(state, payload) {
    state.loading = payload;
  },
  RESET(state) {
    const initial = initialState();

    Object.keys(initial).forEach((key) => {
      state[key] = initial[key];
    });
  }
};

const actions = {
  async apiGetGifJobData({ commit }, payload) {
    commit('SET_LOADING', true);
    // get a gif convert job (if no convert job was found record will be 'null')
    const response = await API.getGifConvertJob(payload);

    commit('SET_LOADING', false);
    return response;
  },
  async apiGenerateGif({ state, commit }, payload) {
    commit('SET_LOADING', true);

    const response = await API.generateGif(state.modalData.id, payload);

    commit('SET_LOADING', false);
    return response;
  },
  async getGifConvertJob({ dispatch, commit }, payload) {
    const response = await dispatch('apiGetGifJobData', payload);

    commit('SET_GIF_JOB_DATA', response.data?.record);
  },
  async generateGif({ dispatch }, payload) {
    const response = await dispatch('apiGenerateGif', payload);

    return response.data?.record;
  },
  async deleteGif({ state }) {
    const response = await API.deleteGif(state.gifJobData.hostVideoId);

    return response;
  },
  setGifModalData({ commit }, payload) {
    commit('SET_MODAL_DATA', payload);
  },
  setGifLink({ commit }, payload) {
    commit('SET_GIF_LINK', payload);
  },
  setDefaultLink({ commit }, payload) {
    commit('SET_DEFAULT_LINK', payload);
  },
  resetVideoGifStore({ commit }) {
    commit('RESET');
  }
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  mutations,
  actions
};
