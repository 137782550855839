import API from '@api/units.api';
import pagination from '../pagination';

function initialState() {
  return {
    ...pagination.state,
    units: [],
    search: '',
    loading: false
  };
}

const getters = {
  ...pagination.getters,
  units: (state) => state.units,
  search: (state) => state.search,
  loading: (state) => state.loading
};

const mutations = {
  ...pagination.mutations,
  SET_LOADING(state, payload) {
    state.loading = payload;
  },
  SET_UNITS(state, payload) {
    state.units = payload;
  },
  ADD_UNITS(state, payload) {
    state.units.push(payload);
  },
  SET_SEARCH(state, payload) {
    state.search = payload;
  }
};

const actions = {
  async searchUnits({ state, commit }, search) {
    commit('SET_LOADING', true);

    const { data } = await API.getUnits({
      page: 1,
      perPage: state.perPage,
      q: search
    });

    commit('SET_PAGE', 1);
    commit('SET_SEARCH', search);
    commit('SET_SHOW_MORE', state.page < data.pagination.pages);
    commit('SET_UNITS', data.records);
    commit('SET_LOADING', false);
  },
  async fetchMoreUnits({ state, commit }) {
    commit('SET_FETCHING_MORE', true);

    const nextPage = state.page + 1;

    const { data } = await API.getUnits({
      page: nextPage,
      perPage: state.perPage,
      q: state.search
    });

    commit('SET_PAGE', nextPage);
    commit('SET_SHOW_MORE', state.page < data.pagination.pages);
    commit('SET_UNITS', [...state.units, ...data.records]);
    commit('SET_FETCHING_MORE', false);
  }
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  mutations,
  actions
};
