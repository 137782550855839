import Vue from 'vue';

import filters from '@/resources/filters';
import '@/resources/tailwind';
import '@/resources/vee-validate';
import '@/resources/lib';
import '@/resources/globalComponents';
import '@/resources/externalLibs/googleAnalytics';

// eslint-disable-next-line import/extensions
import 'swiper/css/bundle';
// import '@/assets/sass/tailwind.css'; TODO: Uncomment and remove main.scss after full UI redesign
import '@/assets/sass/main.scss';

require('video.js/dist/video-js.css');

Object.keys(filters).forEach((key) => Vue.filter(key, filters[key]));
