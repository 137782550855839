import apiClient from './apiClient';

export default {
  createLiveSession: (params) => apiClient.post('/live/video-sessions', { ...params }),
  updateLiveSession: (liveSessionId, params) => apiClient.patch(`/live/video-sessions/${liveSessionId}`, { ...params }),
  getLiveSession: (liveSessionId) => apiClient.get(`/live/video-sessions/${liveSessionId}/session`),
  deleteLiveSession: (liveSessionId) => apiClient.delete(`/live/video-sessions/${liveSessionId}`),
  getLiveSessions: (params) => apiClient.get('/live/video-sessions', { params }),
  getLiveSessionTotals: (params) => apiClient.get('/live/video-sessions/totals', { params }),
  getAgendaList: (params) => apiClient.get('/live/video-sessions/agenda', { params }),
  getVideoList: (params) => apiClient.get('/live/video-sessions/videos', { params }),
  processAgenda: (liveSessionId, params) => apiClient.patch(`/live/video-sessions/${liveSessionId}/agenda`, { ...params }),
  updateLiveSessionTitle: (liveSessionId, title) => apiClient.patch(`/video_sessions/${liveSessionId}/title`, title)
};
