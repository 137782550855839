import APIAttachments from '@api/attachments.api';
import APIPlaylists from '@api/playlists.api';
import APILiveSession from '@api/liveSession.api';
import APILiveTour from '@api/liveTour.api';

function initialState() {
  return {
    attachmentsLoading: false,
    attachments: []
  };
}

const apiModules = {
  LIVE_SESSION: APILiveSession,
  LIVE_TOUR: APILiveTour,
  USER: APIAttachments,
  PLAYLIST: APIPlaylists
};

const getters = {
  attachmentsLoading: (state) => state.attachmentsLoading,
  attachments: (state) => state.attachments
};

const mutations = {
  SET_ATTACHMENTS_LOADING(state, data) {
    state.attachmentsLoading = data;
  },
  SET_ATTACHMENTS(state, data) {
    state.attachments = data;
  },
  RESET(state) {
    const initial = initialState();

    Object.keys(initial).forEach((key) => {
      state[key] = initial[key];
    });
  }
};

const actions = {
  async getAttachments({ commit }, options) {
    commit('SET_ATTACHMENTS_LOADING', true);

    const { data } = await apiModules[options.apiModule].getAttachments(options.recordId);

    commit('SET_ATTACHMENTS', data.records);
    commit('SET_ATTACHMENTS_LOADING', false);
  },
  resetAttachments({ commit }) {
    commit('RESET');
  }
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  mutations,
  actions
};
