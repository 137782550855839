import API from '@api/dashboardVideos.api';
import { getAllTimeRanges, getIndexes, getRange } from '@helpers/closedCaptions.helper';

function initialState() {
  return {
    video: null,
    showModal: false,
    allTimeRanges: [],
    currentRange: {},
    currentTime: 0,
    previousTime: 0,
    isEdited: false,
    loading: false,
    isFromFolder: false
  };
}

const getters = {
  video: (state) => state.video,
  showModal: (state) => state.showModal,
  allTimeRanges: (state) => state.allTimeRanges,
  currentRange: (state) => state.currentRange,
  isEdited: (state) => state.isEdited,
  loading: (state) => state.loading,
  isFromFolder: (state) => state.isFromFolder
};

const mutations = {
  SET_VIDEO(state, video) {
    state.video = video;
  },

  SET_SHOW_MODAL(state, value) {
    state.showModal = value;
  },

  SET_ALL_TIME_RANGES(state) {
    const { transcriptionJSON } = state.video.accessibility;
    const transcriptionJSONCopy = transcriptionJSON.map((transcription) => ({ ...transcription }));

    state.allTimeRanges = getAllTimeRanges(transcriptionJSONCopy);
  },

  SET_CURRENT_TIME_RANGE(state) {
    const { currentTime, previousTime, allTimeRanges } = state;

    const indexes = getIndexes(currentTime, previousTime, allTimeRanges);

    if (!indexes || !(indexes && Object.keys(indexes).length)) return;

    const { currentTimeRangeIndex, previousTimeRangeIndex } = indexes;

    if (previousTimeRangeIndex >= 0) allTimeRanges[previousTimeRangeIndex].current = false;
    if (currentTimeRangeIndex >= 0) allTimeRanges[currentTimeRangeIndex].current = true;

    state.currentRange = getRange(currentTime, allTimeRanges);
  },

  SET_CURRENT_TIME(state, time) {
    if (time > state.allTimeRanges[state.allTimeRanges.length - 1].to) return;

    state.previousTime = state.currentTime;
    state.currentTime = time;
  },

  WATCH_CAPTIONS_WERE_EDITED(state, currentCaptionsTexts) {
    let edited = false;
    const oldCaptionsTexts = state.video.accessibility.transcriptionJSON.map((el) => el.text);

    // eslint-disable-next-line consistent-return
    oldCaptionsTexts.forEach((oldCaption, index) => {
      const oldCaptionSplitted = oldCaption.split('\n');
      const currentCaptionSplitted = currentCaptionsTexts[index].split('\n');

      if (oldCaptionSplitted.length !== currentCaptionSplitted.length) {
        edited = true;

        return edited;
      }

      // eslint-disable-next-line consistent-return
      oldCaptionSplitted.forEach((oldText, i) => {
        if (oldText.trim() !== currentCaptionSplitted[i].trim()) {
          edited = true;

          return edited;
        }
      });
    });

    state.isEdited = edited;
  },

  SET_LOADING(state, value) {
    state.loading = value;
  },

  SET_IS_FROM_FOLDER(state, value) {
    state.isFromFolder = value;
  },

  RESET(state) {
    const initial = initialState();

    Object.keys(initial).forEach((key) => {
      state[key] = initial[key];
    });
  }
};

const actions = {
  setVideo({ commit }, video) {
    commit('SET_VIDEO', video);
  },
  setShowModal({ commit }, value) {
    commit('SET_SHOW_MODAL', value);
  },
  setAllTimeRanges({ commit }) {
    commit('SET_ALL_TIME_RANGES');
  },
  setCurrentTimeRange({ commit }) {
    commit('SET_CURRENT_TIME_RANGE');
  },
  setCurrentTime({ commit }, time) {
    commit('SET_CURRENT_TIME', time);
  },
  setIsFromFolder({ commit }, value) {
    commit('SET_IS_FROM_FOLDER', value);
  },
  watchCaptionsWasEdited({ commit }, currentCaptionsTexts) {
    commit('WATCH_CAPTIONS_WERE_EDITED', currentCaptionsTexts);
  },
  async updateCaptions({ state, commit }, updatedCaptions) {
    commit('SET_LOADING', true);

    const captions = state.video.accessibility.transcriptionJSON.map((captionEl, index) => {
      const captionObject = { ...captionEl };
      captionObject.text = updatedCaptions[index];

      return captionObject;
    });

    await API.updateCaptions(state.video.id, { transcriptionJSON: captions });

    commit('SET_LOADING', false);
  },
  reset({ commit }) {
    commit('RESET');
  }
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  mutations,
  actions
};
