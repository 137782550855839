import apiClient from './apiClient';

export default {
  getSharedVideo: (videoId, params) => apiClient.get(`/pre-recorded-video/shared/${videoId}`, { params, silentErrorNotification: true }),
  logRecipientWatched: (recipientId, params) => apiClient.get(`/video/${recipientId}/watched`, { params }),
  calculateDownload: (videoId) => apiClient.post(`/pre-recorded-video/calculateDownload/${videoId}`),
  getVideoSegmentsByClips: (videoId) => apiClient.get(`/pre-recorded-video/${videoId}/segments-by-clips`),
  getVideoSegments: (videoId) => apiClient.get(`/pre-recorded-video/${videoId}/segments`),

  // Shared playlist
  getSharedPlaylist: (playlistId, recipientId) => apiClient.get(`/pre-recorded-video/playlists/${playlistId}/recipient/${recipientId}`, { silentErrorNotification: true }),
  logRecipientWatchedPlaylistVideo: ({ playlistId, ...rest }) => apiClient.post(`/pre-recorded-video/playlist/${playlistId}/watch`, { ...rest }, { silentErrorNotification: true })
};
