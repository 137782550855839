import apiClient from './apiClient';

export default {
  createTour: (params) => apiClient.post('/live/tours/schedule', { ...params }),
  updateTour: (tourId, params) => apiClient.put(`/live/tours/${tourId}`, { ...params }),
  getTour: (tourId) => apiClient.get(`/tour/show/${tourId}`),
  deleteTour: (tourId) => apiClient.delete(`/live/tours/${tourId}`),
  getTours: (params) => apiClient.get('/live/tours', { params }),
  getTourTotals: (params) => apiClient.get('/live/tours/totals', { params }),
  getUnits: (params) => apiClient.get('/pre-recorded-video/units', { params })
};
