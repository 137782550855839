import API from '@api/corporate.api';
import pagination from '../pagination';

function initialState() {
  return {
    ...pagination.state,
    propertyId: null,
    folders: [],
    filters: {}
  };
}

const getters = {
  ...pagination.getters,
  folders: (state) => state.folders,
  filters: (state) => state.filters
};

const mutations = {
  ...pagination.mutations,
  SET_PROPERTY_ID(state, propertyId) {
    state.propertyId = propertyId;
  },
  SET_PROPERTY_FOLDERS(state, data) {
    state.folders = data;
  },
  SET_FILTERS(state, filters) {
    state.filters = filters;
  },
  ADD_PROPERTY_FOLDERS(state, data) {
    state.folders.push(...data);
  },
  RESET(state) {
    const initial = initialState();

    Object.keys(initial).forEach((key) => {
      state[key] = initial[key];
    });
  }
};

const actions = {
  async apiGetPropertyFolders({ state }, params) {
    const { data } = await API.getPropertyFolders(
      state.propertyId,
      {
        page: 1,
        perPage: state.perPage,
        ...params
      }
    );

    return data;
  },
  async getPropertyFoldersList({ state, commit, dispatch }) {
    commit('SET_LOADING', true);

    const data = await dispatch('apiGetPropertyFolders', {
      ...state.filters
    });

    commit('SET_PROPERTY_FOLDERS', data.records);
    commit('SET_SHOW_MORE', state.page < data.pagination.pages);
    commit('SET_LOADING', false);
  },
  async applyFilters({ state, commit, dispatch }, filters) {
    commit('SET_LOADING', true);

    const data = await dispatch('apiGetPropertyFolders', {
      page: 1,
      ...filters
    });

    commit('SET_FILTERS', filters);
    commit('SET_PAGE', 1);
    commit('SET_SHOW_MORE', state.page < data.pagination.pages);
    commit('SET_PROPERTY_FOLDERS', data.records);
    commit('SET_LOADING', false);
  },
  async fetchMore({ state, commit, dispatch }) {
    commit('SET_FETCHING_MORE', true);

    const nextPage = state.page + 1;

    const data = await dispatch('apiGetPropertyFolders', {
      page: nextPage,
      ...state.filters
    });

    commit('SET_PAGE', nextPage);
    commit('SET_SHOW_MORE', state.page < data.pagination.pages);
    commit('ADD_PROPERTY_FOLDERS', data.records);
    commit('SET_FETCHING_MORE', false);
  },
  setPropertyId({ commit }, propertyId) {
    commit('SET_PROPERTY_ID', propertyId);
  },
  resetAll({ state, commit }, resetFolders = true) {
    const currentPropertyId = state.propertyId;

    commit('RESET');

    // Keep the current propertyId if it's not route change, but the sub folder actions.
    if (!resetFolders) commit('SET_PROPERTY_ID', currentPropertyId);

    commit('propertyFolderSubFolders/RESET', null, { root: true });
    commit('propertyFolderSubVideos/RESET', null, { root: true });
    commit('subFolderNavigation/RESET', null, { root: true });
  }
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  mutations,
  actions
};
