import API from '@api/dashboardVideos.api';
import pagination from '../pagination';

function initialState() {
  return {
    ...pagination.state,
    loading: false,
    videos: [],
    videosCount: 0,
    links: [],
    filter: {},
    contentType: 'video',
    unpublishedVideosCount: 0,
    unpublishedVideos: [],
    unpublishedPage: 1,
    unpublishedFetchingMore: false,
    unpublishedLoading: false,
    unpublishedShowMore: false
  };
}

const getters = {
  ...pagination.getters,
  loading: (state) => state.loading,
  videos: (state) => state.videos,
  links: (state) => state.links,
  filter: (state) => state.filter,
  contentType: (state) => state.contentType,
  videosCount: (state) => state.videosCount,
  unpublishedVideosCount: (state) => state.unpublishedVideosCount,
  unpublishedVideos: (state) => state.unpublishedVideos,
  unpublishedLoading: (state) => state.unpublishedLoading,
  unpublishedFetchingMore: (state) => state.unpublishedFetchingMore,
  unpublishedPage: (state) => state.unpublishedPage,
  unpublishedShowMore: (state) => state.unpublishedShowMore
};

const mutations = {
  ...pagination.mutations,
  SET_VIDEOS(state, payload) {
    state.videos = payload;
  },
  SET_LINKS(state, payload) {
    state.links = payload;
  },
  SET_FILTERS(state, payload) {
    state.filter = payload;
  },
  ADD_VIDEOS(state, payload) {
    state.videos.push(...payload);
  },
  SET_LOADING(state, payload) {
    state.loading = payload;
  },
  SET_CONTENT_TYPE(state, payload) {
    state.contentType = payload;
  },
  SET_VIDEOS_COUNT(state, payload) {
    state.videosCount = payload;
  },
  SET_UNPUBLISHED_VIDEO_COUNT(state, payload) {
    state.unpublishedVideosCount = payload;
  },
  SET_UNPUBLISHED_VIDEOS(state, payload) {
    state.unpublishedVideos = payload;
  },
  ADD_UNPUBLISHED_VIDEOS(state, payload) {
    state.unpublishedVideos.push(...payload);
  },
  SET_UNPUBLISHED_PAGE(state, page) {
    state.unpublishedPage = page;
  },
  SET_UNPUBLISHED_SHOW_MORE(state, showMore) {
    state.unpublishedShowMore = showMore;
  },
  SET_UNPUBLISHED_LOADING(state, loading) {
    state.unpublishedLoading = loading;
  },
  SET_UNPUBLISHED_FETCHING_MORE(state, fetchingMore) {
    state.unpublishedFetchingMore = fetchingMore;
  },
  RESET(state) {
    const initial = initialState();

    Object.keys(initial).forEach((key) => {
      state[key] = initial[key];
    });
  }
};

const actions = {
  async apiGetVideos({ state }, params) {
    const response = await API.getVideosList({
      perPage: state.perPage,
      sort: 'updatedAt',
      sortBy: 'desc',
      contentType: state.contentType.split(','),
      ...params
    });

    return response;
  },
  async apiGetUnpublishedVideos({ state, rootState }, params) {
    const response = await API.getUnpublishedVideos(
      {
        perPage: state.perPage,
        sort: 'updatedAt',
        sortBy: 'desc',
        managedPropertyId: rootState.user.user.activeManagedPropertyId,
        ...params
      }
    );

    return response;
  },
  async getVideosList({ state, commit, dispatch }) {
    commit('SET_LOADING', true);

    const response = await dispatch('apiGetVideos', {
      page: 1,
      ...state.filter
    });

    if (response.status === 200) {
      const { data } = response;

      commit('SET_PAGE', 1);
      commit('SET_SHOW_MORE', state.page < data.pagination.pages);
      commit('SET_VIDEOS_COUNT', data.pagination.count);
      commit('SET_VIDEOS', data.videos);
    }

    commit('SET_LOADING', false);
  },
  async getUnpublishedVideosList({ state, commit, dispatch }) {
    commit('SET_UNPUBLISHED_LOADING', true);

    const response = await dispatch('apiGetUnpublishedVideos', {
      page: 1,
      ...state.filter
    });

    if (response.status === 200) {
      const { data } = response;

      commit('SET_UNPUBLISHED_PAGE', 1);
      commit('SET_UNPUBLISHED_SHOW_MORE', state.page < data.pagination.pages);
      commit('SET_UNPUBLISHED_VIDEO_COUNT', data.pagination.count);
      commit('SET_UNPUBLISHED_VIDEOS', data.records);
    }

    commit('SET_UNPUBLISHED_LOADING', false);
  },
  applyFilters({ commit, dispatch }, payload) {
    const { unpublished, ...filters } = payload;

    commit('SET_FILTERS', filters);

    dispatch('getVideosList');

    if (unpublished) {
      dispatch('getUnpublishedVideosList');
    }
  },
  async fetchMore({ state, commit, dispatch }) {
    commit('SET_FETCHING_MORE', true);

    const nextPage = state.page + 1;

    const response = await dispatch('apiGetVideos', {
      page: nextPage,
      ...state.filter
    });

    if (response.status === 200) {
      const { data } = response;

      commit('SET_PAGE', nextPage);
      commit('SET_SHOW_MORE', state.page < data.pagination.pages);
      commit('ADD_VIDEOS', data.videos);
    }

    commit('SET_FETCHING_MORE', false);
  },
  async unpublishedFetchMore({ state, commit, dispatch }) {
    commit('SET_UNPUBLISHED_FETCHING_MORE', true);

    const nextPage = state.unpublishedPage + 1;

    const response = await dispatch('apiGetUnpublishedVideos', {
      page: nextPage,
      ...state.filter
    });

    if (response.status === 200) {
      const { data } = response;

      commit('SET_UNPUBLISHED_PAGE', nextPage);
      commit('SET_UNPUBLISHED_SHOW_MORE', state.unpublishedPage < data.pagination.pages);
      commit('ADD_UNPUBLISHED_VIDEOS', data.records);
    }

    commit('SET_UNPUBLISHED_FETCHING_MORE', false);
  },
  async deleteVideo({ commit }, videoId) {
    commit('SET_LOADING', true);
    const response = await API.deleteVideo({
      hostVideoID: videoId
    });

    commit('SET_LOADING', false);
    return response;
  },
  // eslint-disable-next-line no-unused-vars
  async apiBulkCreateVideoLinks({ state }, params) {
    const response = await API.createMultipleVideoLinks(params);

    return response;
  },
  async bulkCreateVideoLinks({ commit, dispatch }, params) {
    commit('SET_LOADING', true);
    const response = await dispatch('apiBulkCreateVideoLinks', params);

    if (response.status === 200) {
      commit('SET_LINKS', response.data.records);
    }
    commit('SET_LOADING', false);

    return response;
  },
  // eslint-disable-next-line no-unused-vars
  async deleteMultipleVideos({ state }, params) {
    const response = await API.deleteMultipleVideos({ ids: params.videoIDs });

    return response;
  },
  setContentType({ commit }, payload) {
    commit('SET_CONTENT_TYPE', payload);
  },
  resetVideosList({ commit }) {
    commit('RESET');
  }
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  mutations,
  actions
};
