import { detect } from 'detect-browser';

const { os, name, version } = detect();

const MIN_SAFARI_VERSION_WITH_SCREEN_SHARE = 14;
const MIN_SAFARI_VERSION_WITH_MEDIA_RECORDING = 15;

const browserNotSupported = () => (
  (os === 'iOS' && name !== 'ios')
  || (os === 'Android OS' && (name !== 'chrome' || navigator.userAgent.indexOf(' UCBrowser/') >= 0))
  || (os.includes('Windows') && (name !== 'chrome' && name !== 'firefox' && name !== 'edge' && name !== 'edge-chromium'))
  || (os === 'Linux' && (name !== 'chrome' && name !== 'firefox'))
  || (os === 'Mac OS' && (name !== 'chrome' && name !== 'firefox' && name !== 'edge-chromium' && name !== 'edge' && name !== 'safari'))
);

const isMobile = () => (
  os === 'Android OS'
  || os === 'iOS'
  || os === 'BlackBerry OS'
  || os === 'Windows Mobile'
);

const browserMajorVersion = () => version.split('.')[0];

const isChromeBrowser = () => name === 'chrome';

const isFirefoxBrowser = () => name === 'firefox';

const isSafariMobile = () => name === 'ios';

const isSafariDesktop = () => name === 'safari';

const isIOS = () => os === 'iOS';

const isSafariScreenShareSupported = () => parseInt(browserMajorVersion(), 10) >= MIN_SAFARI_VERSION_WITH_SCREEN_SHARE;

const isSafariRecordSupported = () => parseInt(browserMajorVersion(), 10) >= MIN_SAFARI_VERSION_WITH_MEDIA_RECORDING;

const isIpad = () => navigator.maxTouchPoints && navigator.maxTouchPoints > 1 && /MacIntel|iPad/.test(navigator.platform);

export {
  browserNotSupported,
  isMobile,
  isChromeBrowser,
  isFirefoxBrowser,
  isSafariMobile,
  isSafariDesktop,
  browserMajorVersion,
  isSafariScreenShareSupported,
  isSafariRecordSupported,
  isIpad,
  isIOS
};
