export default {
  state: {
    page: 1,
    perPage: 30,
    showMore: false,
    loading: false,
    fetchingMore: false
  },
  getters: {
    page: (state) => state.page,
    showMore: (state) => state.showMore,
    loading: (state) => state.loading,
    fetchingMore: (state) => state.fetchingMore
  },
  mutations: {
    SET_PAGE(state, page) {
      state.page = page;
    },
    SET_SHOW_MORE(state, showMore) {
      state.showMore = showMore;
    },
    SET_LOADING(state, loading) {
      state.loading = loading;
    },
    SET_FETCHING_MORE(state, fetchingMore) {
      state.fetchingMore = fetchingMore;
    }
  }
};
