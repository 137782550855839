import Analytics from 'analytics';
import googleAnalytics from '@analytics/google-analytics';

const analytics = Analytics({
  plugins: [
    googleAnalytics({
      measurementIds: ['UA-53954805-6']
    })
  ]
});

analytics.page();
