import API from '@api/dashboardVideosDeleted.api';
import pagination from '../pagination';

function initialState() {
  return {
    ...pagination.state,
    playlists: [],
    loading: false
  };
}

const getters = {
  ...pagination.getters,
  playlists: (state) => state.playlists,
  loading: (state) => state.loading
};

const mutations = {
  ...pagination.mutations,
  SET_PLAYLISTS(state, payload) {
    state.playlists = payload;
  },
  ADD_PLAYLISTS(state, payload) {
    state.playlists.push(...payload);
  },
  SET_LOADING(state, payload) {
    state.loading = payload;
  },
  RESET(state) {
    const initial = initialState();

    Object.keys(initial).forEach((key) => {
      state[key] = initial[key];
    });
  }
};

const actions = {
  async apiGetDeletedPlaylists({ state }, params) {
    const response = await API.getDeletedPlaylists(
      { perPage: state.perPage, ...params }
    );

    return response;
  },
  async getDeletedPlaylists({ state, commit, dispatch }) {
    commit('SET_LOADING', true);
    const response = await dispatch('apiGetDeletedPlaylists', {
      page: 1
    });

    if (response.status === 200) {
      const { data } = response;

      commit('SET_PAGE', 1);
      commit('SET_SHOW_MORE', state.page < data.pagination.pages);
      commit('SET_PLAYLISTS', data.records);
    }

    commit('SET_LOADING', false);

    return response;
  },
  async restoreMedia({ commit }, data) {
    commit('SET_LOADING', true);
    const response = await API.restoreMedia(data);

    commit('SET_LOADING', false);
    return response;
  },
  async destroyPlaylist({ commit }, playlistId) {
    commit('SET_LOADING', true);
    const response = await API.destroyPlaylist(playlistId);

    commit('SET_LOADING', false);
    return response;
  },
  async fetchMore({ state, commit, dispatch }) {
    commit('SET_FETCHING_MORE', true);

    const nextPage = state.page + 1;

    const response = await dispatch('apiGetDeletedPlaylists', {
      page: nextPage
    });

    if (response.status === 200) {
      const { data } = response;

      commit('SET_PAGE', nextPage);
      commit('SET_SHOW_MORE', state.page < data.pagination.pages);
      commit('ADD_PLAYLISTS', data.records);
    }

    commit('SET_FETCHING_MORE', false);
  },
  resetDeletedPlaylistsState({ commit }) {
    commit('RESET');
  }
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  mutations,
  actions
};
