import API from '@api/corporate.api';
import { localTimezone } from '@helpers/common.helper';

function initialState() {
  return {
    propertyId: null,
    records: [],
    selectedDate: '',
    section: '',
    loading: false
  };
}

const getters = {
  records: (state) => state.records,
  loading: (state) => state.loading,
  section: (state) => state.section
};

const mutations = {
  SET_PROPERTY_ID(state, propertyId) {
    state.propertyId = propertyId;
  },
  SET_SELECTED_DATE(state, selectedDate) {
    state.selectedDate = selectedDate;
  },
  SET_SECTION(state, section) {
    state.section = section;
  },
  SET_RECORDS(state, data) {
    state.records = data;
  },
  SET_LOADING(state, loading) {
    state.loading = loading;
  },
  RESET(state) {
    const initial = initialState();

    Object.keys(initial).forEach((key) => {
      state[key] = initial[key];
    });
  }
};

const actions = {
  async getSectionDataByDay({ state, commit }) {
    commit('SET_LOADING', true);

    const getData = (state.section === 'Sessions')
      ? API.getPropertyLiveSessions
      : API.getPropertyLiveTours;

    const { data } = await getData(
      state.propertyId,
      {
        date: state.selectedDate,
        timezone: localTimezone()
      }
    );

    if (data.status !== 'failed') {
      commit('SET_RECORDS', data.records);
    }

    commit('SET_LOADING', false);
  }
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  mutations,
  actions
};
