import apiClient from './apiClient';

export default {
  getVideosTabTotals: () => apiClient.get('/pre-recorded-video/totals'),

  // Videos List
  getVideosTabList: (params) => apiClient.get('/pre-recorded-video/videos/list', { params }),
  getListFolderContent: ({ folderId, ...params }) => apiClient.get(`/pre-recorded-video/videos/list/folders/${folderId}`, { params }),
  getFloorplansList: (params) => apiClient.get('/pre-recorded-video/floorplans', { params }),
  getVideosListUnits: (params) => apiClient.get('/pre-recorded-video/videos/list/units', { params }),

  // Videos
  getVideosList: (params) => apiClient.get('/pre-recorded-video/videos', { params }),
  getUnpublishedVideos: (params) => apiClient.get('/pre-recorded-video/videos/unpublished', { params }),
  getVideoEditingStatus: (videoId) => apiClient.get(`/pre-recorded-video/${videoId}/editing-status`),
  deleteVideo: (params) => apiClient.get('/hostVideo/delete', { params }),
  createMultipleVideoLinks: (params) => apiClient.post('/pre-recorded-video/tracking/multiple-recipients', { ...params }),
  addVideoToFolder: (params) => apiClient.post('/pre-recorded-video/folders/add-video', { ...params }),
  moveVideoToHome: (videoId, params) => apiClient.post(`/pre-recorded-video/videos/move-to-root/${videoId}`, { ...params }),
  deleteMultipleVideos: (params) => apiClient.put('/pre-recorded-video/delete-multiple', { ...params }),
  updateCaptions: (videoId, params) => apiClient.put(`/pre-recorded-video/${videoId}/edit-closed-captions`, { ...params }),
  cancelEditing: (videoId, params) => apiClient.patch(`/pre-recorded-video/${videoId}/editing`, { ...params }),
  checkVideoMoveCopyAvailability: (videoId, params) => apiClient.get(`/pre-recorded-video/${videoId}/check-availability`, { params, silentErrorNotification: true }),
  generateVideoScore: (videoId) => apiClient.post(`/pre-recorded-video/${videoId}/generate-video-score`),
  duplicateVideos: (params) => apiClient.post('/pre-recorded-video/duplicate', { ...params }),
  downloadVideo: (videoId) => apiClient.post(`/pre-recorded-video/${videoId}/download`),
  downloadMultipleVideos: (params) => apiClient.post('/pre-recorded-video/download', { ...params }),

  // Folders
  getFoldersList: (params) => apiClient.get('/pre-recorded-video/folders', { params }),
  getFolderContent: (folderId, params) => apiClient.get(`/pre-recorded-video/folders/${folderId}`, { params }),
  createFolder: (params) => apiClient.post('/pre-recorded-video/folders', { ...params }),
  updateFolder: (folderId, params) => apiClient.put(`/pre-recorded-video/folders/${folderId}`, { ...params }),
  getFoldersTree: () => apiClient.get('/pre-recorded-video/folders-tree'),
  moveFolderToFolder: (params) => apiClient.post('/pre-recorded-video/folder/move', { ...params }),
  moveMultipleItemsToFolder: ({ folderId, ...rest }) => apiClient.put(`/pre-recorded-video/folders/${folderId}/bulk-move`, { ...rest }),
  moveFolderToHome: (folderId) => apiClient.post(`/pre-recorded-video/folders/move-to-root/${folderId}`),
  deleteFolder: (params) => apiClient.delete(`/pre-recorded-video/folder/${params.folderId}`, { params }),

  // Playlists
  getPlaylistsList: (params) => apiClient.get('/pre-recorded-video/playlists', { params }),
  deletePlaylist: (playlistId) => apiClient.delete(`/pre-recorded-video/playlist/${playlistId}/archive`)
};
