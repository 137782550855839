import API from '@api/dashboardVideos.api';
import pagination from '../pagination';

function initialState() {
  return {
    ...pagination.state,
    subVideos: [],
    filters: {}
  };
}

const getters = {
  ...pagination.getters,
  subVideos: (state) => state.subVideos,
  filters: (state) => state.filters
};

const mutations = {
  ...pagination.mutations,
  SET_SUB_VIDEOS(state, data) {
    state.subVideos = data;
  },
  SET_FILTERS(state, filters) {
    state.filters = filters;
  },
  ADD_SUB_VIDEOS(state, data) {
    state.subVideos.push(...data);
  },
  RESET(state) {
    const initial = initialState();

    Object.keys(initial).forEach((key) => {
      state[key] = initial[key];
    });
  }
};

const actions = {
  async apiGetSubVideos({ state, rootState }, params) {
    const response = await API.getFolderContent(
      rootState.subFolderNavigation.currentFolderId,
      {
        type: 'videos',
        page: 1,
        perPage: state.perPage,
        ...params
      }
    );

    return response;
  },
  async getSubVideosList({ state, commit, dispatch }) {
    commit('SET_LOADING', true);

    const response = await dispatch('apiGetSubVideos', {
      ...state.filters
    });

    if (response.status === 200) {
      const { data } = response;

      commit('SET_SHOW_MORE', state.page < data.pagination.pages);
      commit('SET_SUB_VIDEOS', data.records);
    }

    commit('SET_LOADING', false);
  },
  async applyFilters({ state, commit, dispatch }, filters) {
    commit('SET_LOADING', true);

    const response = await dispatch('apiGetSubVideos', {
      ...filters
    });

    if (response.status === 200) {
      const { data } = response;

      commit('SET_PAGE', 1);
      commit('SET_FILTERS', filters);
      commit('SET_SHOW_MORE', state.page < data.pagination.pages);
      commit('SET_SUB_VIDEOS', data.records);
    }

    commit('SET_LOADING', false);
  },
  async fetchMore({ state, commit, dispatch }) {
    commit('SET_FETCHING_MORE', true);

    const nextPage = state.page + 1;

    const response = await dispatch('apiGetSubVideos', {
      page: nextPage,
      ...state.filters
    });

    if (response.status === 200) {
      const { data } = response;

      commit('SET_PAGE', nextPage);
      commit('SET_SHOW_MORE', state.page < data.pagination.pages);
      commit('ADD_SUB_VIDEOS', data.records);
    }

    commit('SET_FETCHING_MORE', false);
  },
  async deleteVideo({ commit }, videoId) {
    commit('SET_LOADING', true);

    const response = await API.deleteVideo({
      hostVideoID: videoId
    });

    commit('SET_LOADING', false);

    return response;
  },
  resetSubVideos({ commit }) {
    commit('RESET');
  }
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  mutations,
  actions
};
