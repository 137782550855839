import apiClient from './apiClient';

export default {
  getDeletedContentsCount: () => apiClient.get('/pre-recorded-video/deletedCounts'),
  getDeletedVideos: (params) => apiClient.get('/pre-recorded-video/videos/deleted', { params }),
  getDeletedPlaylists: (params) => apiClient.get('/pre-recorded-video/playlists/deleted/list', { params }),
  restoreMedia: (params) => apiClient.post('pre-recorded-video/restoreMedia', { ...params }),
  destroyVideos: (params) => apiClient.post('/pre-recorded-video/destroy-multiple', { ...params }),
  destroyPlaylist: (id) => apiClient.delete(`/pre-recorded-video/playlists/${id}`)
};
