import API from '@api/reports.api';
import { dataExportTipLinks } from '@helpers/constants';

function initialState() {
  return {
    properties: [],
    previousFilters: [],
    pickedPreviousFilter: {},
    dataTypes: [],
    loading: false,
    allDataTypesSelected: false
  };
}

const getters = {
  properties: (state) => state.properties,
  previousFilters: (state) => state.previousFilters,
  pickedPreviousFilter: (state) => state.pickedPreviousFilter,
  filterIsActive: (state) => (filter) => state.pickedPreviousFilter?.id === filter.id,
  dataTypes: (state) => state.dataTypes,
  loading: (state) => state.loading,
  allDataTypesSelected: (state) => state.allDataTypesSelected
};

const mutations = {
  SET_PROPERTIES(state, payload) {
    state.properties = payload;
  },
  SET_PREVIOUS_FILTERS(state, payload) {
    state.previousFilters = payload;
  },
  SET_PICKED_PREVIOUS_FILTER(state, payload) {
    state.pickedPreviousFilter = payload;
  },
  SET_DATA_TYPES(state, payload) {
    state.dataTypes = payload.map((el) => {
      const columns = el.columns.map((col) => ({
        ...col,
        checked: false
      }));

      return {
        ...el,
        columns,
        checked: false,
        tipLink: dataExportTipLinks[el.type]
      };
    });
  },
  TOGGLE_ALL_DATA_TYPES(state, payload) {
    state.allDataTypesSelected = payload;
    state.dataTypes.forEach((el) => {
      el.checked = payload;
      // eslint-disable-next-line no-return-assign
      el.columns.forEach((c) => c.checked = payload);
    });
  },
  TOGGLE_TYPE_GROUP(state, payload) {
    const index = state.dataTypes.indexOf(payload);
    const group = state.dataTypes[index];

    if (group.checked) {
      group.checked = false;
      // eslint-disable-next-line no-return-assign
      group.columns.forEach((el) => el.checked = false);
    } else {
      group.checked = true;
      // eslint-disable-next-line no-return-assign
      group.columns.forEach((el) => el.checked = true);
    }
  },
  TOGGLE_CHILD_TYPE(state, payload) {
    const groupInd = state.dataTypes.indexOf(payload.group);
    const group = state.dataTypes[groupInd];
    const colInd = group.columns.indexOf(payload.type);
    const col = state.dataTypes[groupInd].columns[colInd];

    if (!group.checked) {
      group.checked = true;
    }

    col.checked = !payload.type.checked;

    const isGroupEmpty = !group.columns.some((el) => el.checked);

    if (isGroupEmpty) {
      group.checked = false;
    }
  },
  SET_LOADING(state, payload) {
    state.loading = payload;
  },
  RESET(state) {
    const initial = initialState();

    Object.keys(initial).forEach((key) => {
      state[key] = initial[key];
    });
  }
};

const actions = {
  async apiGetProperties() {
    const response = await API.getCorpPropertiesList();

    return response;
  },
  async apiGetPreviousFilters({ commit }) {
    commit('SET_LOADING', true);

    const response = await API.getSavedFilters();

    commit('SET_LOADING', false);

    return response;
  },
  async apiGetColumns({ commit }) {
    commit('SET_LOADING', true);

    const response = await API.getReportColumns();

    commit('SET_LOADING', false);

    return response;
  },
  async getProperties({ commit, dispatch }) {
    commit('SET_LOADING', true);

    const response = await dispatch('apiGetProperties');

    if (response.status === 200) {
      await commit('SET_PROPERTIES', response.data.records);
    }

    commit('SET_LOADING', false);

    return response;
  },
  async getColumns({ commit, dispatch }) {
    const response = await dispatch('apiGetColumns');

    if (response.status === 200) {
      commit('SET_DATA_TYPES', response.data.records);
    }

    return response;
  },
  async getPreviousFilters({ commit, dispatch }) {
    const response = await dispatch('apiGetPreviousFilters');

    if (response.status === 200) {
      commit('SET_PREVIOUS_FILTERS', response.data.records);
    }

    return response;
  },
  toggleAllDataTypes({ commit }, payload) {
    commit('TOGGLE_ALL_DATA_TYPES', payload);
  },
  toggleTypeGroup({ commit }, payload) {
    commit('TOGGLE_TYPE_GROUP', payload);
  },
  toggleChildType({ commit }, payload) {
    commit('TOGGLE_CHILD_TYPE', payload);
  },
  setPickedPreviousFilter({ commit }, payload) {
    commit('SET_PICKED_PREVIOUS_FILTER', payload);
  },
  async setPreviousDataTypes({ state, dispatch }, payload) {
    await dispatch('toggleAllDataTypes', false);

    payload.forEach((item) => {
      const groupIndex = state.dataTypes.findIndex((el) => el.type === item.type);
      const group = state.dataTypes[groupIndex];

      if (groupIndex !== -1 && item.columns.length === group.columns.length) {
        dispatch('toggleTypeGroup', group);
      } else if (groupIndex !== -1) {
        item.columns.forEach((col) => {
          const colIndex = group.columns.findIndex((el) => el.type === col);
          const column = group.columns[colIndex];

          if (colIndex !== -1) {
            dispatch('toggleChildType', { group, type: column });
          }
        });
      }
    });
  },
  resetReportsData({ commit }) {
    commit('RESET');
  }
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  mutations,
  actions
};
