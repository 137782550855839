import API from '@api/brandingSettings.api';
import { brandingPreviewPlaceholders } from '@helpers/constants';

function initialState() {
  return {
    dynamicBrandingComponents: [],
    brandingData: {},
    embedCodeConfig: {},
    editingItem: {},
    qrCodeConfig: {},
    currentPreview: null,
    showTabs: false,
    loading: false
  };
}

const getters = {
  dynamicBrandingComponents: (state) => state.dynamicBrandingComponents,
  brandingData: (state) => state.brandingData,
  embedCodeConfig: (state) => state.embedCodeConfig,
  editingItem: (state) => state.editingItem,
  qrCodeConfig: (state) => state.qrCodeConfig,
  currentPreview: (state) => state.currentPreview,
  tabsShown: (state) => state.showTabs,
  loading: (state) => state.loading
};

const mutations = {
  SET_DYNAMIC_COMPONENTS(state, payload) {
    state.dynamicBrandingComponents = payload;
  },
  SET_BRANDING_DATA(state, payload) {
    state.brandingData = payload;
  },
  SET_EMBED_CODE_CONFIG(state, payload) {
    state.embedCodeConfig = payload;
  },
  SET_QR_CODE_CONFIG(state, payload) {
    state.qrCodeConfig = payload;
  },
  SET_EDITING_ITEM(state, payload) {
    state.editingItem = payload;
  },
  SET_CURRENT_PREVIEW(state, component) {
    state.currentPreview = component;
  },
  SET_SHOW_TABS(state, payload) {
    state.showTabs = payload;
  },
  SET_LOADING(state, payload) {
    state.loading = payload;
  },
  RESET(state) {
    const initial = initialState();

    Object.keys(initial).forEach((key) => {
      state[key] = initial[key];
    });
  }
};

const actions = {
  async apiGetBrandingSettings({ commit }, params) {
    commit('SET_LOADING', true);

    const response = await API.getBrandingSettings(params);

    commit('SET_LOADING', false);

    return response;
  },
  async getBrandingSettings({ commit, state, dispatch }, params) {
    commit('SET_EDITING_ITEM', {
      type: params.type,
      id: params.id
    });

    const response = await dispatch('apiGetBrandingSettings', state.editingItem);

    let placeholder;

    if (params.type !== 'embed') {
      placeholder = brandingPreviewPlaceholders[params.type];
    }

    if (response.status === 200) {
      commit('SET_BRANDING_DATA', { ...response.data, previewPlaceholder: placeholder });
    }

    return response;
  },
  async saveBrandingSettings({ commit, state }) {
    commit('SET_LOADING', true);

    const response = await API.saveVideoBrandingSettings({
      id: state.editingItem.id,
      type: state.editingItem.type,
      customButtonTitle: state.brandingData.customButtonTitle,
      customButtonUrl: state.brandingData.customButtonUrl,
      color: state.brandingData.color,
      brandingPropertyLogoUrl: state.brandingData.brandingPropertyLogoUrl
    });

    commit('SET_LOADING', false);

    return response;
  },
  toggleShowTabs({ commit }, payload) {
    commit('SET_SHOW_TABS', payload);
  },
  updateBrandingSettings({ state, commit }, data) {
    commit('SET_BRANDING_DATA', { ...state.brandingData, ...data });
  },
  setBrandingSettings({ commit }, data) {
    commit('SET_BRANDING_DATA', { ...data });
  },
  setBrandingComponents({ commit }, payload) {
    commit('SET_DYNAMIC_COMPONENTS', payload);
  },
  setEmbedCodeConfig({ commit }, payload) {
    commit('SET_EMBED_CODE_CONFIG', payload);
  },
  // eslint-disable-next-line no-shadow
  setQrCodeConfig({ getters, commit }, payload) {
    commit('SET_QR_CODE_CONFIG', { ...getters.qrCodeConfig, ...payload });
  },
  async setCurrentPreview({ commit }, component) {
    await commit('SET_CURRENT_PREVIEW', component);
  },
  resetBrandingData({ commit }) {
    commit('RESET');
  }
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  mutations,
  actions
};
