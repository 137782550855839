import { Client } from '@revenuegrid/churnzerojs';
import {
  productionSecretKey,
  developSecretKey,
  apiClientUrl
} from '@helpers/constants';

const churnZeroInitialize = async (currentUser) => {
  if (!currentUser) return;

  const property = currentUser.managedProperties[0];
  const { company } = currentUser;
  const accountId = property ? property.id : company.id;

  if (!accountId) return;

  function isProduction() {
    return window.location.host === 'realync.com';
  }

  function defineAppKey() {
    if (isProduction) {
      return productionSecretKey;
    }

    return developSecretKey;
  }

  await Client.connect({
    url: apiClientUrl,
    apiKey: defineAppKey,
    accountId,
    contactId: currentUser.id
  });
};

export default churnZeroInitialize;
