import API from '@api/corporate.api';
import pagination from '../pagination';

function initialState() {
  return {
    ...pagination.state,
    propertyId: null,
    folderId: null,
    subFolders: [],
    filters: {}
  };
}

const getters = {
  ...pagination.getters,
  subFolders: (state) => state.subFolders,
  filters: (state) => state.filters
};

const mutations = {
  ...pagination.mutations,
  SET_REQUEST_PARAMS(state, params) {
    state.propertyId = params.propertyId;
    state.folderId = params.folderId;
  },
  SET_SUB_FOLDERS(state, data) {
    state.subFolders = data;
  },
  SET_FILTERS(state, filters) {
    state.filters = filters;
  },
  ADD_SUB_FOLDERS(state, data) {
    state.subFolders.push(...data);
  },
  RESET(state) {
    const initial = initialState();

    Object.keys(initial).forEach((key) => {
      state[key] = initial[key];
    });
  }
};

const actions = {
  async apiGetSubFolders({ state }, params) {
    const { data } = await API.getPropertyFolderContent(
      state.propertyId,
      state.folderId,
      {
        type: 'subFolders',
        page: 1,
        perPage: state.perPage,
        ...params
      }
    );

    return data;
  },
  async getSubFoldersList({ state, commit, dispatch }) {
    commit('SET_LOADING', true);

    dispatch(
      'subFolderNavigation/setCurrentFolderTitle',
      'Loading...',
      { root: true }
    );

    const data = await dispatch('apiGetSubFolders', {
      ...state.filters
    });

    commit('SET_SHOW_MORE', state.page < data.pagination.pages);
    commit('SET_SUB_FOLDERS', data.records);
    commit('SET_LOADING', false);
  },
  async applyFilters({ state, commit, dispatch }, filters) {
    commit('SET_LOADING', true);

    const data = await dispatch('apiGetSubFolders', {
      ...filters
    });

    commit('SET_FILTERS', filters);
    commit('SET_PAGE', 1);
    commit('SET_SHOW_MORE', state.page < data.pagination.pages);
    commit('SET_SUB_FOLDERS', data.records);
    commit('SET_LOADING', false);
  },
  async fetchMore({ state, commit, dispatch }) {
    commit('SET_FETCHING_MORE', true);

    const nextPage = state.page + 1;

    const data = await dispatch('apiGetSubFolders', {
      page: nextPage,
      ...state.filters
    });

    commit('SET_FETCHING_MORE', false);
    commit('SET_PAGE', nextPage);
    commit('SET_SHOW_MORE', state.page < data.pagination.pages);
    commit('ADD_SUB_FOLDERS', data.records);
  },
  setRequestParams({ commit }, payload) {
    commit('SET_REQUEST_PARAMS', payload);
  },
  reset({ commit }) {
    commit('RESET');
  }
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  mutations,
  actions
};
