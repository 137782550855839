import API from '@api/dashboardVideos.api';
import pagination from '../pagination';

function initialState() {
  return {
    ...pagination.state,
    subFolders: [],
    filters: {}
  };
}

const getters = {
  ...pagination.getters,
  subFolders: (state) => state.subFolders,
  filters: (state) => state.filters
};

const mutations = {
  ...pagination.mutations,
  SET_SUB_FOLDERS(state, data) {
    state.subFolders = data;
  },
  SET_FILTERS(state, filters) {
    state.filters = filters;
  },
  ADD_SUB_FOLDERS(state, data) {
    state.subFolders.push(...data);
  },
  UPDATE_SUB_VIDEOS_COUNT(state, params) {
    const index = state.subFolders.findIndex((f) => f.id === params.folderId);

    if (index > -1) {
      // A little hack to update count of sub folders on FolderCard, which is based on `.length`
      state.subFolders[index].videoIds.push(params.videoId);

      state.subFolders = [...state.subFolders];
    }
  },
  RESET(state) {
    const initial = initialState();

    Object.keys(initial).forEach((key) => {
      state[key] = initial[key];
    });
  }
};

const actions = {
  async apiGetSubFolders({ state, rootState }, params) {
    const response = await API.getFolderContent(
      rootState.subFolderNavigation.currentFolderId,
      {
        type: 'subFolders',
        page: 1,
        perPage: state.perPage,
        ...params
      }
    );

    return response;
  },
  async getSubFoldersList({ state, commit, dispatch }) {
    commit('SET_LOADING', true);

    const response = await dispatch('apiGetSubFolders', {
      ...state.filters
    });

    if (response.status === 200) {
      const { data } = response;

      commit('SET_PAGE', 1);
      commit('SET_SHOW_MORE', state.page < data.pagination.pages);
      commit('SET_SUB_FOLDERS', data.records);
    }

    commit('SET_LOADING', false);
  },
  async applyFilters({ state, commit, dispatch }, filters) {
    commit('SET_LOADING', true);

    const response = await dispatch('apiGetSubFolders', {
      ...filters
    });

    if (response.status === 200) {
      const { data } = response;

      commit('SET_PAGE', 1);
      commit('SET_FILTERS', filters);
      commit('SET_SHOW_MORE', state.page < data.pagination.pages);
      commit('SET_SUB_FOLDERS', data.records);
    }

    commit('SET_LOADING', false);
  },
  async fetchMore({ state, commit, dispatch }) {
    commit('SET_FETCHING_MORE', true);

    const nextPage = state.page + 1;

    const response = await dispatch('apiGetSubFolders', {
      page: nextPage,
      ...state.filters
    });

    if (response.status === 200) {
      const { data } = response;

      commit('SET_PAGE', nextPage);
      commit('SET_SHOW_MORE', state.page < data.pagination.pages);
      commit('ADD_SUB_FOLDERS', data.records);
    }

    commit('SET_FETCHING_MORE', false);
  },
  updateVideosCount({ commit }, params) {
    commit('UPDATE_SUB_VIDEOS_COUNT', params);
  },
  resetSubFolders({ commit }) {
    commit('RESET');
  }
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  mutations,
  actions
};
