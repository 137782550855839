import API from '@api/notifications.api';
import pagination from '../pagination';

function initialState() {
  return {
    ...pagination.state,
    perPage: 10,
    records: [],
    immediateNotifications: [],
    unreadCount: 0,
    filter: ''
  };
}

const getters = {
  ...pagination.getters,
  records: (state) => state.records,
  immediateNotifications: (state) => state.immediateNotifications,
  unreadCount: (state) => state.unreadCount,
  filter: (state) => state.filter
};

const mutations = {
  ...pagination.mutations,
  SET_NOTIFICATIONS(state, data) {
    state.records = data;
  },
  SET_FILTER(state, filter) {
    state.filter = filter;
  },
  SET_UNREAD_COUNT(state, unreadCount) {
    state.unreadCount = unreadCount;
  },
  ADD_NOTIFICATIONS(state, data) {
    state.records.push(...data);
  },
  UNSHIFT_NOTIFICATION(state, data) {
    state.records.unshift(data);
  },
  READ_NOTIFICATIONS(state, data) {
    state.records = state.records.map((record) => {
      if (data.includes(record.id)) {
        return { ...record, read: true };
      }

      return record;
    });
  },
  ADD_IMMEDIATE_NOTIFICATION(state, data) {
    state.immediateNotifications.push(data);
  },
  REMOVE_IMMEDIATE_NOTIFICATION(state, notificationId) {
    const indexToRemove = state.immediateNotifications.findIndex((n) => n.id === notificationId);

    if (indexToRemove > -1) {
      state.immediateNotifications.splice(indexToRemove, 1);
    }
  },
  RESET_IMMEDIATE_NOTIFICATIONS(state) {
    state.immediateNotifications = [];
  },
  RESET(state) {
    const initial = initialState();

    Object.keys(initial).forEach((key) => {
      state[key] = initial[key];
    });
  }
};

const actions = {
  async apiGetNotifications({ state }, params) {
    const { data } = await API.getNotifications({
      perPage: state.perPage, ...params
    });

    return data;
  },
  async getNotificationsList({ state, commit, dispatch }) {
    commit('SET_LOADING', true);

    const data = await dispatch('apiGetNotifications', {
      page: state.page,
      q: state.filter
    });

    commit('SET_LOADING', false);
    commit('SET_SHOW_MORE', state.page < data.pagination.pages);
    commit('SET_NOTIFICATIONS', data.records);
  },
  async fetchMore({ state, commit, dispatch }) {
    commit('SET_FETCHING_MORE', true);

    const nextPage = state.page + 1;

    const data = await dispatch('apiGetNotifications', {
      page: nextPage,
      q: state.filter
    });

    commit('SET_FETCHING_MORE', false);
    commit('SET_PAGE', nextPage);
    commit('SET_SHOW_MORE', state.page < data.pagination.pages);
    commit('ADD_NOTIFICATIONS', data.records);
  },
  async addNotification({ commit, dispatch }, data) {
    commit('UNSHIFT_NOTIFICATION', data);

    await dispatch('getUnreadNotificationsCount');
  },
  async readNotifications({ commit, dispatch }, notificationIds) {
    await API.readNotifications(notificationIds);
    await dispatch('getUnreadNotificationsCount');

    commit('READ_NOTIFICATIONS', notificationIds);
  },
  async getUnreadNotificationsCount({ commit }) {
    const response = await API.getUnreadNotificationsCount();

    if (response.status === 200) {
      commit('SET_UNREAD_COUNT', response.data.unreadCount);
    }
  },
  sortUnread({ state, commit }) {
    const sorted = [...state.records].sort((a, b) => {
      if (!a.read && !b.read) {
        return a.createdAt < b.createdAt ? 1 : -1;
      }
      return (a.read ? 1 : -1);
    });

    commit('SET_NOTIFICATIONS', sorted);
  },
  addImmediateNotification({ commit }, data) {
    commit('ADD_IMMEDIATE_NOTIFICATION', data);
  },
  removeImmediateNotification({ commit }, notificationId) {
    commit('REMOVE_IMMEDIATE_NOTIFICATION', notificationId);
  },
  resetImmediateNotifications({ commit }) {
    commit('RESET_IMMEDIATE_NOTIFICATIONS');
  },
  resetNotifications({ commit }) {
    commit('RESET');
  }
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  mutations,
  actions
};
