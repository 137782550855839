import apiClient from './apiClient';

export default {
  getVideoById: (id) => apiClient.get(`/hostVideo/${id}`),
  logGenericLinkVideoWatch: (shortId) => apiClient.post(`/pre-recorded-video/generic-links/${shortId}/watch`),
  getEditableClips: (videoId) => apiClient.get(`/pre-recorded-video/${videoId}/editable-clips`),
  convertClipsForPreview: (videoId) => apiClient.put(`/pre-recorded-video/videos/${videoId}/clips-for-preview`),
  createHostVideo: (data) => apiClient.post('/hostVideo/create', { ...data }),
  createVideoClipRecord: (data) => apiClient.post('/pre-recorded-video/clip', { ...data }),
  createVideoSegment: (videoId, data) => apiClient.post(`/pre-recorded-video/${videoId}/segments`, { ...data }),
  updateVideoSegments: (videoId, data) => apiClient.put(`/pre-recorded-video/${videoId}/segments`, { ...data }),
  getCustomSegments: (params) => apiClient.get('/pre-recorded-video/custom-segments', { params }),
  finalizeVideoPublish: (videoId, data) => apiClient.post(`/pre-recorded-video/${videoId}/finalize-publishing`, { ...data }),
  cutClip: (clipId, data) => apiClient.put(`/pre-recorded-video/videos/clips/${clipId}/cut`, { ...data }, { silentErrorNotification: true }),
  trimClip: (clipId, data) => apiClient.put(`/pre-recorded-video/videos/clips/${clipId}/trim`, { ...data }, { silentErrorNotification: true }),
  saveVideoEditingHistory: (videoId, data) => apiClient.post(`pre-recorded-video/${videoId}/editing-history`, { ...data }),
  generateTimelineImages: (clipId, params) => apiClient.put(`/pre-recorded-video/videos/clips/${clipId}/timeline-images`, { ...params }),
  saveUnpublishedClips: (data) => apiClient.post('/pre-recorded-video/clips/unpublished', { ...data }),
  deleteClips: (params) => apiClient.put('/pre-recorded-video/clip/delete-saved', { ...params }),
  saveVideo: (data) => apiClient.post('/hostVideo/create', { ...data }),
  saveVideoDraft: (data) => apiClient.post('/pre-recorded-video/unpublished', { ...data }),
  generateVideoPreview: (videoId, params) => apiClient.put(`/pre-recorded-video/videos/${videoId}/preview`, { ...params }),
  getVideoStatistic: (videoId) => apiClient.get(`/pre-recorded-video/${videoId}/statistic`),
  getVideoAppearsInfo: (videoId) => apiClient.get(`/pre-recorded-video/${videoId}/appears-info`)
};
