import apiClient from './apiClient';

export default {
  getUsageCounts: (params) => apiClient.post('analytics/usage/counts', { ...params }),
  getUsageCountsByPeriod: (params) => apiClient.post('analytics/usage/counts-by-period', { ...params }),
  getTopPropertiesByViews: (params) => apiClient.post('analytics/usage/top-views-properties', { ...params }),
  getPropertyListCounts: (params) => apiClient.post('analytics/usage/property-list-counts', { ...params }),
  getMostActiveProspects: (params) => apiClient.post('/analytics/leaderboard/most-active-prospects', { ...params }),
  getMostActiveVideo: (params) => apiClient.post('/analytics/leaderboard/most-active-video', { ...params }),
  getEngagementViewsByChannels: (params) => apiClient.post('analytics/engagement/views-by-channels', { ...params }),
  getEngagementViewsByVideoTypes: (params) => apiClient.post('analytics/engagement/views-by-types', { ...params }),
  getEngagementVideoData: (params) => apiClient.post('analytics/engagement/video-data', { ...params }),
  getEngagementLiveData: (params) => apiClient.post('analytics/engagement/ltls-data', { ...params }),
  getInsightsMainData: (params) => apiClient.post('/analytics/insights/main-data', { ...params }),
  getInsightsCountsTable: (params) => apiClient.post('/analytics/insights/counts-table', { ...params }),
  getInsightsLastYardiIntegrationDate: (params) => apiClient.post('/corporate/last-yardi-integration-date', { ...params }),
  getPropertiesLeadersByVideo: (params) => apiClient.post('/analytics/leaderboard/videos-count', { ...params }),
  getPropertiesLeadersByLtLs: (params) => apiClient.post('/analytics/leaderboard/ltls-count', { ...params }),
  getPropertiesLeadersByLinks: (params) => apiClient.post('/analytics/leaderboard/links-count', { ...params }),
  getLeaderboardTableCounts: (params) => apiClient.post('/analytics/leaderboard/counts-table', { ...params }),
  getPropertyLtlsCount: (params) => apiClient.post('/analytics/leaderboard/ltls-count-by-property', { ...params }),
  getPropertyMostActiveVideo: (params) => apiClient.post('/analytics/leaderboard/most-active-video-by-property', { ...params }),
  getPropertyLinksCounts: (params) => apiClient.post('/analytics/leaderboard/links-counts', { ...params }),
  getLeadToApplication: (params) => apiClient.get('/analytics/dashboard/top-lead-to-app', { params })
};
