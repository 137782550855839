import apiClient from './apiClient';

export default {
  getLiveTour: (param) => apiClient.get(`/live/tours/${param.tourId}/guest/${param.guestId}`),
  initiateLiveTour: (params) => apiClient.post('live/tours/initiate', params),
  getStartedLiveTour: (params) => apiClient.get('live/tours/started', { params }),
  getTour: (tourId) => apiClient.get(`/live/tours/${tourId}/shared`),
  guestRSVP: (tourId, params) => apiClient.post(`/live/tours/${tourId}/register-guest`, params),
  getToken: ({ tourId, ...params }) => apiClient.get(`/live/tours/${tourId}/token`, { params }),
  saveNote: (tourId, payload) => apiClient.post(`/live/tours/${tourId}/note`, payload),
  getNote: (tourId, params) => apiClient.get(`live/tours/${tourId}/note`, { params }),
  getMessages: (tourId, params) => apiClient.get(`/live/tours/${tourId}/messages`, { params }),
  saveMessage: (tourId, params) => apiClient.post(`/live/tours/${tourId}/messages`, params),
  getAttachments: (tourId) => apiClient.get(`/live/tours/${tourId}/attachments`),
  getMapCategories: () => apiClient.get('/user/map-categories'),
  getDefaultPins: (tourId) => apiClient.get(`/live/tours/${tourId}/pins`)
};
