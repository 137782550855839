import API from '@api/corporate.api';

function initialState() {
  return {
    property: {},
    loading: true
  };
}

const getters = {
  property: (state) => state.property,
  propertyProductType: (state) => state.property?.productType,
  loading: (state) => state.loading,
  propertyReviewers: (state) => state.property?.reviewers,
  propertyReviewProcessEnabled: (state) => state.property?.reviewProcess
};

const mutations = {
  SET_PROPERTY(state, data) {
    state.property = data;
  },
  SET_LOADING(state, data) {
    state.loading = data;
  },
  RESET(state) {
    const initial = initialState();

    Object.keys(initial).forEach((key) => {
      state[key] = initial[key];
    });
  }
};

const actions = {
  async getProperty({ commit, dispatch }, propertyId) {
    commit('SET_LOADING', true);

    if (propertyId && propertyId.length) {
      const response = await API.getProperty(propertyId);

      if (response.status === 200) {
        const { data } = response;

        commit('SET_PROPERTY', data.record);

        dispatch(
          'manageDeletedContent/setDeletedContentCounts',
          { videos: data.record.deletedVideosCount },
          { root: true }
        );
      }

      if (response.status === 404) {
        window.location = '/home';
      }
    }

    commit('SET_LOADING', false);
  },
  async reloadProperty({ state, dispatch }) {
    await dispatch('getProperty', state.property.id);
  },
  async resetProperty({ commit }) {
    commit('RESET');
  }
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  mutations,
  actions
};
