import moment from 'moment';
import { localTimezone } from '@helpers/common.helper';
import API from '@api/analytics.api';
import pagination from '../pagination';

/**
 * We use this constants to determine which action/mutation we should apply.
 * mostly used as prop for AnalyticsTable.vue
 */
const tablePaginationMutation = {
  usageTable: 'ADD_USAGE_TABLE_DATA',
  mostActiveProspectsTable: 'ADD_MOST_ACTIVE_PROSPECTS_TABLE_DATA',
  leaderboardTable: 'ADD_LEADERBOARD_TABLE_DATA',
  prospectEngagedTable: 'ADD_PROSPECT_ENGAGED_TABLE_DATA',
  insightsTable: 'ADD_INSIGHTS_TABLE_DATA'
};
const tablePaginationPageMutation = {
  mostActiveProspectsTable: 'SET_PAGE_MOST_ACTIVE_PROSPECTS',
  prospectEngagedTable: 'SET_PAGE_PROSPECTS_ENGAGED'
};
const tablePageState = {
  mostActiveProspectsTable: 'pageMostActiveProspectsTable',
  prospectEngagedTable: 'pageEngagedTable'
};
const tableShowMoreMutation = {
  mostActiveProspectsTable: 'SET_SHOW_MORE_MOST_ACTIVE_PROSPECTS',
  prospectEngagedTable: 'SET_SHOW_MORE_PROSPECTS_ENGAGED'
};
const tableDataMutation = {
  usageTable: 'SET_USAGE_TABLE_DATA',
  mostActiveProspectsTable: 'SET_MOST_ACTIVE_PROSPECTS_TABLE_DATA',
  mostActiveProspectsSmallTableColumns: 'SET_MOST_ACTIVE_PROSPECTS_SMALL_TABLE_DATA',
  leaderboardTable: 'SET_LEADERBOARD_TABLE_DATA',
  prospectEngagedTable: 'SET_PROSPECT_ENGAGED_TABLE_DATA',
  insightsTable: 'SET_INSIGHTS_TABLE_DATA'
};
const actionMethod = {
  propertyVideosCreated: 'apiGetPropertyMostActiveVideo',
  propertyLtlsCount: 'apiGetPropertyLtlsCount',
  propertyUniqueLinks: 'apiGetPropertyUniqueLinks',
  // Table Actions
  usageTable: 'apiGetUsageTableData',
  mostActiveProspectsTable: 'apiGetMostActiveProspects',
  mostActiveProspectsSmallTableColumns: 'apiGetMostActiveProspects',
  leaderboardTable: 'apiGetLeaderboardTableCounts',
  prospectEngagedTable: 'apiGetMostActiveProspects',
  insightsTable: 'apiGetInsightsTableCounts'
};

const normalizeModalDataByType = function (type, data) {
  const structuredData = {
    propertyVideosCreated: {
      modalType: type,
      videosCount: data.videosCount,
      viewsCount: data.videosViewsCount,
      duration: data.videosDuration?.total,
      sectionOneSecondaryData: data.videosDuration?.average,
      videoOptions: { ...data.mostActiveVideo, sources: [{ src: data.mostActiveVideo?.videoURL }] }
    },
    propertyLtlsCount: {
      modalType: type,
      LTLSCount: data.LTLSCount,
      duration: data.completedLTLSDuration?.total,
      sectionOneSecondaryData: data.completedLTLSDuration?.average,
      sectionTwoMainData: data.LTLSStatus?.scheduled,
      sectionTwoSecondaryData: data.LTLSStatus?.completed,
      videoOptions: { ...data.video, sources: [{ src: data.video?.videoURL }] }
    }
  };

  if (type === 'propertyUniqueLinks') {
    const links = Object.entries(data.links).flatMap((value) => {
      if (value[1] < 1) return [];

      return {
        icon: value[0],
        channel: value[0],
        links: value[1]
      };
    });

    structuredData[type] = { links };
  }

  const propertyDetails = {
    propertyName: data?.propertyName || 'Property Name',
    propertyLogo: data?.propertyLogo
  };

  return { ...propertyDetails, ...structuredData[type] };
};

function initialState() {
  return {
    ...pagination.state,
    timezone: localTimezone(),
    loading: false,
    filtersChanged: false,
    dataViewComponent: '',
    mostActiveVideo: {},
    analyticsFilter: {},
    usageViewFilter: {},
    engagementViewFilter: {},
    insightsViewFilter: {},
    leaderboardViewFilter: {},
    // Charts
    chartSchema: [],
    countsByPeriod: [],
    usageCounts: {},
    viewsByChannels: [],
    viewsByVideoTypes: [],
    engagementData: {},
    insightsMainData: {},
    lastYardiIntegrationDate: null,
    leadersByVideos: [],
    leadersByLinks: [],
    leadersByLtLs: [],
    // Tables
    usageTableData: [],
    insightsTableData: [],
    mostActiveProspectsTableData: [],
    mostActiveProspectsSmallTableData: [],
    leaderboardTableData: [],
    prospectEngagedTableData: [],
    showMoreProspectsEngaged: false,
    showMoreMostActiveProspects: false,
    pageEngagedTable: 1,
    pageMostActiveProspectsTable: 1,
    activeTableColumn: '',
    activeTableSort: '',
    // Modals
    propertyDetailsModalType: '',
    prospectEngagedModalData: {},
    propertyDetailsModalData: {},
    propertyDetailsModalLoading: false,
    mostActiveProspectsCount: 0
  };
}

const getters = {
  ...pagination.getters,
  loading: (state) => state.loading,
  filtersChanged: (state) => state.filtersChanged,
  dataViewComponent: (state) => state.dataViewComponent,
  mostActiveVideo: (state) => state.mostActiveVideo,
  analyticsFilter: (state) => state.analyticsFilter,
  usageViewFilter: (state) => state.usageViewFilter,
  engagementViewFilter: (state) => state.engagementViewFilter,
  insightsViewFilter: (state) => state.insightsViewFilter,
  leaderboardViewFilter: (state) => state.leaderboardViewFilter,
  // Charts
  chartSchema: (state) => state.chartSchema,
  countsByPeriod: (state) => state.countsByPeriod,
  usageCounts: (state) => state.usageCounts,
  viewsByChannels: (state) => state.viewsByChannels,
  viewsByVideoTypes: (state) => state.viewsByVideoTypes,
  engagementData: (state) => state.engagementData,
  insightsMainData: (state) => state.insightsMainData,
  lastYardiIntegrationDate: (state) => state.lastYardiIntegrationDate,
  leadersByVideos: (state) => state.leadersByVideos,
  leadersByLinks: (state) => state.leadersByLinks,
  leadersByLtLs: (state) => state.leadersByLtLs,
  // Tables
  usageTableData: (state) => state.usageTableData,
  insightsTableData: (state) => state.insightsTableData,
  mostActiveProspectsTableData: (state) => state.mostActiveProspectsTableData,
  mostActiveProspectsSmallTableData: (state) => state.mostActiveProspectsSmallTableData,
  leaderboardTableData: (state) => state.leaderboardTableData,
  prospectEngagedTableData: (state) => state.prospectEngagedTableData,
  activeTableColumn: (state) => state.activeTableColumn,
  activeTableSort: (state) => state.activeTableSort,
  showMoreProspectsEngaged: (state) => state.showMoreProspectsEngaged,
  showMoreMostActiveProspects: (state) => state.showMoreMostActiveProspects,
  pageEngagedTable: (state) => state.pageEngagedTable,
  pageMostActiveProspectsTable: (state) => state.pageMostActiveProspectsTable,
  // Modals
  prospectEngagedModalData: (state) => state.prospectEngagedModalData,
  propertyDetailsModalType: (state) => state.propertyDetailsModalType,
  propertyDetailsModalData: (state) => state.propertyDetailsModalData,
  propertyDetailsModalLoading: (state) => state.propertyDetailsModalLoading,
  mostActiveProspectsCount: (state) => state.mostActiveProspectsCount
};

const tableMutations = {
  SET_ACTIVE_TABLE_COLUMN(state, payload) {
    state.activeTableColumn = payload;
  },
  SET_ACTIVE_TABLE_SORT(state, payload) {
    state.activeTableSort = payload;
  },
  // Usage View
  SET_USAGE_TABLE_DATA(state, payload) {
    state.usageTableData = payload;
  },
  ADD_USAGE_TABLE_DATA(state, payload) {
    state.usageTableData.push(...payload);
  },
  // Insights View
  SET_INSIGHTS_TABLE_DATA(state, payload) {
    state.insightsTableData = payload;
  },
  ADD_INSIGHTS_TABLE_DATA(state, payload) {
    state.insightsTableData.push(...payload);
  },
  // Leaderboard View
  SET_LEADERBOARD_TABLE_DATA(state, payload) {
    state.leaderboardTableData = payload;
  },
  ADD_LEADERBOARD_TABLE_DATA(state, payload) {
    state.leaderboardTableData.push(...payload);
  },
  SET_MOST_ACTIVE_PROSPECTS_TABLE_DATA(state, payload) {
    state.mostActiveProspectsTableData = payload;
  },
  ADD_MOST_ACTIVE_PROSPECTS_TABLE_DATA(state, payload) {
    state.mostActiveProspectsTableData.push(...payload);
  },
  SET_MOST_ACTIVE_PROSPECTS_SMALL_TABLE_DATA(state, payload) {
    state.mostActiveProspectsSmallTableData = payload;
  },
  // Prospect Engaged Modal table
  SET_PROSPECT_ENGAGED_TABLE_DATA(state, payload) {
    state.prospectEngagedTableData = payload;
  },
  ADD_PROSPECT_ENGAGED_TABLE_DATA(state, payload) {
    state.prospectEngagedTableData.push(...payload);
  },
  SET_PAGE_MOST_ACTIVE_PROSPECTS(state, payload) {
    state.pageMostActiveProspectsTable = payload;
  },
  SET_PAGE_PROSPECTS_ENGAGED(state, payload) {
    state.pageEngagedTable = payload;
  },
  SET_SHOW_MORE_PROSPECTS_ENGAGED(state, payload) {
    state.showMoreProspectsEngaged = payload;
  },
  SET_SHOW_MORE_MOST_ACTIVE_PROSPECTS(state, payload) {
    state.showMoreMostActiveProspects = payload;
  }
};

const chartsMutations = {
  // Usage View
  SET_CHART_SCHEMA(state, payload) {
    state.chartSchema = payload;
  },
  SET_COUNTS_BY_PERIOD(state, payload) {
    if (payload && payload.length) {
      const lastDayWithData = payload[payload.length - 1][0];
      const plusOneDay = moment(lastDayWithData, 'MM/DD/YYYY').add('days', 1).format('MM/DD/YYYY');
      payload.push([
        plusOneDay, 0, 0, 0, 0, 0, 0
      ]);
    }
    state.countsByPeriod = payload;
  },
  SET_USAGE_COUNTS(state, payload) {
    state.usageCounts = payload;
  },
  // Engagement View
  SET_ENGAGEMENT_DATA(state, payload) {
    state.engagementData = payload;
  },
  SET_VIEWS_BY_CHANNELS(state, payload) {
    state.viewsByChannels = payload;
  },
  SET_VIEWS_BY_VIDEO_TYPES(state, payload) {
    state.viewsByVideoTypes = payload;
  },
  // Insights View
  SET_INSIGHTS_MAIN_DATA(state, payload) {
    state.insightsMainData = payload;
  },
  // Leaderboard View
  SET_LEADERS_BY_VIDEOS(state, payload) {
    state.leadersByVideos = payload;
  },
  SET_LEADERS_BY_LINKS(state, payload) {
    state.leadersByLinks = payload;
  },
  SET_LEADERS_BY_LT_LS(state, payload) {
    state.leadersByLtLs = payload;
  },
  SET_MOST_ACTIVE_VIDEO(state, payload) {
    state.mostActiveVideo = payload;
  },
  SET_MOST_ACTIVE_PROSPECTS_COUNT(state, loading) {
    state.mostActiveProspectsCount = loading;
  }
};

const mutations = {
  ...pagination.mutations,
  ...tableMutations,
  ...chartsMutations,
  SET_LOADING(state, payload) {
    state.loading = payload;
  },
  SET_FILTERS_CHANGED(state, payload) {
    state.filtersChanged = payload;
  },
  SET_USAGE_VIEW_FILTERS(state, payload) {
    state.usageViewFilter = payload;
  },
  SET_ENGAGEMENT_VIEW_FILTERS(state, payload) {
    state.engagementViewFilter = payload;
  },
  SET_INSIGHTS_VIEW_FILTERS(state, payload) {
    state.insightsViewFilter = payload;
  },
  SET_LEADERBOARD_VIEW_FILTERS(state, payload) {
    state.leaderboardViewFilter = payload;
  },
  SET_ANALYTICS_FILTER(state, payload) {
    state.analyticsFilter = payload;
  },
  SET_ACTIVE_DATA_VIEW_COMPONENT(state, payload) {
    state.dataViewComponent = payload;
  },
  SET_PROPERTY_DETAILS_MODAL_TYPE(state, type) {
    state.propertyDetailsModalType = type;
  },
  SET_PROPERTY_DETAILS_MODAL_DATA(state, payload) {
    state.propertyDetailsModalData = payload;
  },
  SET_PROSPECT_ENGAGED_MODAL_DATA(state, payload) {
    state.prospectEngagedModalData = payload;
  },
  SET_PROPERTY_DETAILS_MODAL_LOADING(state, loading) {
    state.propertyDetailsModalLoading = loading;
  },
  SET_LAST_YARDI_INTEGRATION_DATE(state, payload) {
    state.lastYardiIntegrationDate = payload;
  },
  RESET(state) {
    const initial = initialState();

    Object.keys(initial).forEach((key) => {
      state[key] = initial[key];
    });
  }
};

const apiActions = {
  async apiGetInsightsTableCounts({ state }, params) {
    // eslint-disable-next-line no-return-await
    return await API.getInsightsCountsTable({ ...params, timezone: state.timezone });
  },
  async apiGetUsageCounts({ state }, params) {
    // eslint-disable-next-line no-return-await
    return await API.getUsageCounts({ ...params, timezone: state.timezone });
  },
  async apiGetLeaderboardTableCounts({ state }, params) {
    // eslint-disable-next-line no-return-await
    return await API.getLeaderboardTableCounts({ ...params, timezone: state.timezone });
  },
  async apiGetInsightsMainData({ state }, params) {
    // eslint-disable-next-line no-return-await
    return await API.getInsightsMainData({ ...params, timezone: state.timezone });
  },
  async apiGetUsageCountsByPeriod({ state }, params) {
    // eslint-disable-next-line no-return-await
    return await API.getUsageCountsByPeriod({ ...params, timezone: state.timezone });
  },
  async apiGetUsageTableData({ state }, params) {
    // eslint-disable-next-line no-return-await
    return await API.getPropertyListCounts({
      ...params,
      timezone: state.timezone
    });
  },
  // eslint-disable-next-line no-unused-vars
  async apiGetMostActiveProspects({ state }, params) {
    // eslint-disable-next-line no-return-await
    return await API.getMostActiveProspects({
      ...params,
      timezone: state.timezone
    });
  },
  async apiGetPropertyMostActiveVideo({ state }, params) {
    // eslint-disable-next-line no-return-await
    return await API.getPropertyMostActiveVideo({ ...params, timezone: state.timezone });
  },
  async apiGetPropertyLtlsCount({ state }, params) {
    // eslint-disable-next-line no-return-await
    return await API.getPropertyLtlsCount({ ...params, timezone: state.timezone });
  },
  async apiGetPropertyUniqueLinks({ state }, params) {
    // eslint-disable-next-line no-return-await
    return await API.getPropertyLinksCounts({ ...params, timezone: state.timezone });
  }
};

const actions = {
  ...apiActions,
  /**
   * General Method for fetching table data on analytics
   * @param state
   * @param commit
   * @param dispatch
   * @param requestData={Object} = { params: API params, tableNameKey: Table name for mutations }
   */
  async getTableData({ state, commit, dispatch }, requestData) {
    const { params, tableNameKey } = requestData;

    const showMoreMutation = tableShowMoreMutation[tableNameKey] || 'SET_SHOW_MORE';
    const pageMutation = tablePaginationPageMutation[tableNameKey] || 'SET_PAGE';
    const statePageKey = tablePageState[tableNameKey] || 'page';

    commit('SET_LOADING', true);
    commit(pageMutation, 1);

    const response = await dispatch(actionMethod[tableNameKey], params);

    if (response.status === 200) {
      if (tableNameKey === 'mostActiveProspectsTable') {
        // set number of prospects to display on modal
        commit('SET_MOST_ACTIVE_PROSPECTS_COUNT', response.data.pagination.count);
      }

      commit(tableDataMutation[tableNameKey], response.data.records);
      commit(showMoreMutation, state[statePageKey] < response.data.pagination.pages);
    }

    commit('SET_LOADING', false);
  },
  /**
   * Get counts for Usage View
   */
  async getAllUsageCounts({ commit, dispatch }, params) {
    commit('SET_LOADING', true);

    const countsByPeriod = await dispatch('apiGetUsageCountsByPeriod', params);
    const usageCounts = await dispatch('apiGetUsageCounts', params);

    if (countsByPeriod.status === 200) {
      commit('SET_COUNTS_BY_PERIOD', countsByPeriod.data.records);
      commit('SET_CHART_SCHEMA', countsByPeriod.data.schema);
    }

    if (usageCounts.status === 200) {
      commit('SET_USAGE_COUNTS', usageCounts.data.records);
    }

    commit('SET_LOADING', false);
  },
  resetUsageCounts({ commit }) {
    commit('SET_COUNTS_BY_PERIOD', []);
    commit('SET_CHART_SCHEMA', []);
    commit('SET_USAGE_COUNTS', []);
  },
  /**
   * Get data for Insights View
   */
  async getInsightsMainData({ commit, dispatch }, params) {
    commit('SET_LOADING', true);

    const response = await dispatch('apiGetInsightsMainData', params);

    if (response.status === 200) {
      commit('SET_INSIGHTS_MAIN_DATA', response.data.record);
    }

    commit('SET_LOADING', false);
  },
  async getMostActiveVideo({ commit, state }, params) {
    commit('SET_LOADING', true);
    const response = await API.getMostActiveVideo({ ...params, timezone: state.timezone });

    if (response.status === 200) {
      commit('SET_MOST_ACTIVE_VIDEO', response.data.record || { notFound: true });
    }

    commit('SET_LOADING', false);
  },
  /**
   * Get data for Engagement View
   */
  async getEngagementCharts({ state, commit }, params) {
    const requestParams = {
      ...params,
      timezone: state.timezone
    };

    commit('SET_LOADING', true);

    const channelsData = new Promise((resolve) => {
      const response = API.getEngagementViewsByChannels(requestParams);
      resolve(response);
    }).then((res) => {
      if (res.status === 200) {
        const vbcData = Object.entries(res.data.records).map((val) => ({
          label: val[0],
          value: val[1]
        }));

        commit('SET_VIEWS_BY_CHANNELS', vbcData);
      }
    });

    const typesData = new Promise((resolve) => {
      const response = API.getEngagementViewsByVideoTypes(requestParams);
      resolve(response);
    }).then((res) => {
      if (res.status === 200) {
        const vvtData = Object.entries(res.data.records).map((val) => ({
          label: val[0],
          value: val[1]
        }));
        commit('SET_VIEWS_BY_VIDEO_TYPES', vvtData);
      }
    });

    const viewsData = new Promise((resolve) => {
      const response = API.getEngagementVideoData(requestParams);
      resolve(response);
    }).then((res) => {
      if (res.status === 200) {
        commit('SET_ENGAGEMENT_DATA', { ...state.engagementData, ...res.data.records, loadingFinished: true });
      }
    });

    const liveData = new Promise((resolve) => {
      const response = API.getEngagementLiveData(requestParams);
      resolve(response);
    }).then((res) => {
      if (res.status === 200) {
        commit('SET_ENGAGEMENT_DATA', { ...state.engagementData, ...res.data.records, loadingFinished: true });
      }
    });

    Promise.all([
      channelsData,
      typesData,
      viewsData,
      liveData
    ]).then(() => {
      commit('SET_LOADING', false);
    });
  },
  /**
   * Get data for Leaderboard View
   */
  async getLeaderboardData({ commit, dispatch }, requestData) {
    commit('SET_LOADING', true);
    let propertiesLeaders;

    const { params, tableNameKey } = requestData;
    const chartRequestParams = {
      startDate: params.startDate,
      endDate: params.endDate,
      propertiesIds: params.propertiesIds
    };

    if (requestData.getPropertiesLeaders) {
      propertiesLeaders = await dispatch('getPropertiesLeaders', chartRequestParams);
    }
    const mostActiveVideo = await dispatch('getMostActiveVideo', chartRequestParams);
    const mostActiveProspects = new Promise((resolve) => {
      const response = dispatch('apiGetMostActiveProspects', params);
      resolve(response);
    }).then((res) => {
      if (res.status === 200) {
        const { records } = res.data;
        commit(tableDataMutation[tableNameKey], records);
      }
    });

    Promise.all([
      propertiesLeaders,
      mostActiveVideo,
      mostActiveProspects
    ]).then(() => {
      commit('SET_LOADING', false);
    });
  },
  async getPropertiesLeaders({ state, commit }, params) {
    commit('SET_LOADING', true);
    const requestParams = {
      ...params,
      timezone: state.timezone
    };

    const videoLeaders = new Promise((resolve) => {
      const response = API.getPropertiesLeadersByVideo(requestParams);
      resolve(response);
    }).then((res) => {
      if (res.status === 200) {
        commit('SET_LEADERS_BY_VIDEOS', res.data.records);
      }
    });

    const ltLsLeaders = new Promise((resolve) => {
      const response = API.getPropertiesLeadersByLtLs(requestParams);
      resolve(response);
    }).then((res) => {
      if (res.status === 200) {
        commit('SET_LEADERS_BY_LT_LS', res.data.records);
      }
    });

    const linksLeaders = new Promise((resolve) => {
      const response = API.getPropertiesLeadersByLinks(requestParams);
      resolve(response);
    }).then((res) => {
      if (res.status === 200) {
        commit('SET_LEADERS_BY_LINKS', res.data.records);
      }
    });

    Promise.all([
      videoLeaders,
      ltLsLeaders,
      linksLeaders
    ]).then(() => {
      commit('SET_LOADING', false);
    });
  },
  /**
   * Pagination for tables
   */
  async fetchMore({ state, commit, dispatch }, requestData) {
    commit('SET_FETCHING_MORE', true);

    const { params, tableNameKey } = requestData;

    const showMoreMutation = tableShowMoreMutation[tableNameKey] || 'SET_SHOW_MORE';
    const pageMutation = tablePaginationPageMutation[tableNameKey] || 'SET_PAGE';
    const statePageKey = tablePageState[tableNameKey] || 'page';

    const nextPage = state[statePageKey] + 1;

    const response = await dispatch(actionMethod[tableNameKey], {
      page: nextPage,
      q: state.filter,
      ...params
    });

    if (response.status === 200) {
      const { data } = response;

      commit(pageMutation, nextPage);
      commit(showMoreMutation, state[statePageKey] < data.pagination.pages);
      commit(tablePaginationMutation[tableNameKey], data.records);
    }

    commit('SET_FETCHING_MORE', false);
  },
  async getPropertyDetailsModal({ commit, dispatch }, options) {
    await commit('SET_PROPERTY_DETAILS_MODAL_TYPE', options.type);

    commit('SET_PROPERTY_DETAILS_MODAL_LOADING', true);

    const response = await dispatch(actionMethod[options.type], {
      ...options.params
    });

    if (response.status === 200) {
      // TODO: should be fixed on API and return `records`
      const { records, record } = response.data;
      const dataByType = normalizeModalDataByType(options.type, records || record);

      commit('SET_PROPERTY_DETAILS_MODAL_DATA', dataByType);
    }

    commit('SET_PROPERTY_DETAILS_MODAL_LOADING', false);
  },
  async setPropertyDetailsModalData({ commit }, payload) {
    await commit('SET_PROPERTY_DETAILS_MODAL_DATA', payload);
  },
  async getInsightsLastYardiIntegrationDate({ commit }, propertyIds) {
    const response = await API.getInsightsLastYardiIntegrationDate({ propertyIds });

    if (response.status === 200) {
      const { record } = response.data;
      const minIntegrationDate = '2022-01-01';
      let lastIntegrationDate;

      if (record) {
        lastIntegrationDate = moment(record).subtract(90, 'days').format('YYYY-MM-DD');
      }

      const date = lastIntegrationDate > minIntegrationDate ? lastIntegrationDate : minIntegrationDate;

      commit('SET_LAST_YARDI_INTEGRATION_DATE', date);
    }
  },
  setProspectEngagedModalData({ commit }, payload) {
    commit('SET_PROSPECT_ENGAGED_MODAL_DATA', payload);
  },
  resetEngagementData({ commit }) {
    commit('SET_VIEWS_BY_CHANNELS', []);
    commit('SET_VIEWS_BY_VIDEO_TYPES', []);
    commit('SET_ENGAGEMENT_DATA', {});
  },
  resetAnalyticsData({ commit }) {
    commit('RESET');
  },
  setActiveDataViewComponent({ commit }, payload) {
    commit('SET_ACTIVE_DATA_VIEW_COMPONENT', payload);
  },
  setActiveTableColumn({ commit }, payload) {
    commit('SET_ACTIVE_TABLE_COLUMN', payload);
  },
  setActiveTableSort({ commit }, payload) {
    commit('SET_ACTIVE_TABLE_SORT', payload);
  },
  setFiltersChanged({ commit }, payload) {
    commit('SET_FILTERS_CHANGED', payload);
  },
  setAnalyticsFilter({ commit }, payload) {
    commit('SET_ANALYTICS_FILTER', payload);
  },
  setUsageViewFilter({ commit }, payload) {
    commit('SET_USAGE_VIEW_FILTERS', payload);
  },
  setEngagementViewFilter({ commit }, payload) {
    commit('SET_ENGAGEMENT_VIEW_FILTERS', payload);
  },
  setInsightsViewFilter({ commit }, payload) {
    commit('SET_INSIGHTS_VIEW_FILTERS', payload);
  },
  setLeaderboardViewFilter({ commit }, payload) {
    commit('SET_LEADERBOARD_VIEW_FILTERS', payload);
  }
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  mutations,
  actions
};
