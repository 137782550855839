import API from '@api/recipients.api';
import APIIntegrations from '@api/corporate.api';
import Vue from 'vue';

function initialState() {
  return {
    shareModalData: {},
    modalComponents: [],
    modalNavigation: [],
    activeInnerTab: 0,
    linksType: '',
    shareLinks: [],
    selected: [],
    width: 640,
    height: 360,
    integrations: [],
    displayTabMark: {},
    loading: false
  };
}

const getters = {
  shareModalData: (state) => state.shareModalData,
  modalComponents: (state) => state.modalComponents,
  modalNavigation: (state) => state.modalNavigation,
  activeInnerTab: (state) => state.activeInnerTab,
  linksType: (state) => state.linksType,
  shareLinks: (state) => state.shareLinks,
  integrations: (state) => state.integrations,
  displayTabMark: (state) => state.displayTabMark,
  selected: (state) => state.selected,
  width: (state) => state.width,
  height: (state) => state.height,
  loading: (state) => state.loading
};

const mutations = {
  SET_MODAL_DATA(state, payload) {
    state.shareModalData = payload;
  },
  SET_MODAL_COMPONENTS(state, payload) {
    state.modalComponents = payload;
  },
  SET_MODAL_NAVIGATION(state, payload) {
    state.modalNavigation = payload;
  },
  SET_ACTIVE_INNER_TAB(state, payload) {
    state.activeInnerTab = payload;
  },
  SET_ACTIVE_COMPONENT(state, index) {
    // eslint-disable-next-line no-return-assign
    state.modalComponents.forEach((el) => el.active = false);
    state.modalComponents[index].active = true;
  },
  SET_LINKS_TYPE(state, payload) {
    state.linksType = payload;
  },
  SET_SHARE_LINKS(state, payload) {
    state.shareLinks = payload;
  },
  ADD_SHARE_LINKS(state, payload) {
    state.shareLinks.push(payload);
  },
  UPDATE_SHARE_LINKS(state, params) {
    const recordId = params.id || params.recipientId;
    state.shareLinks = state.shareLinks.map((link) => {
      if (link.id === recordId) {
        return { ...link, ...params };
      }

      return link;
    });
  },
  SET_INTEGRATIONS(state, payload) {
    state.integrations = payload;
  },
  SET_SELECTED(state, payload) {
    Vue.set(state, 'selected', [...payload]);
  },
  SET_HEIGHT(state, payload) {
    state.height = payload;
  },
  SET_WIDTH(state, payload) {
    state.width = payload;
  },
  SET_TAB_MARK(state, payload) {
    state.displayTabMark = payload;
  },
  SET_LOADING(state, payload) {
    state.loading = payload;
  },
  RESET(state) {
    state.shareModalData = {};
    state.linksType = '';
    state.shareLinks = [];
    state.loading = false;
    state.activeInnerTab = 0;
  }
};

const actions = {
  async getShareLinks({ state, commit }, params, showLoader = true) {
    commit('SET_LOADING', showLoader);
    let response = {};

    // 'qr_code', 'prospect', 'yardi', 'entrata', 'integration', 'embed_code', 'social_media'
    if (state.shareModalData.type === 'playlist') {
      response = await API.getPlaylistShareLinks(params.itemId, { type: params.type });
    } else {
      response = await API.getShareLinks(params.itemId, { type: params.type });
    }

    if (response.status === 200) {
      commit('SET_SHARE_LINKS', response.data.records);
    }

    commit('SET_LOADING', false);

    return response;
  },
  async getPlaylistShareLinks({ commit }, params, showLoader = true) {
    commit('SET_LOADING', showLoader);

    // 'qr_code', 'prospect', 'yardi', 'entrata', 'integration', 'embed_code', 'social_media'
    const response = await API.getPlaylistShareLinks(params.playlistId, { type: params.type });

    if (response.status === 200) {
      commit('SET_SHARE_LINKS', response.data.records);
    }

    commit('SET_LOADING', false);

    return response;
  },
  async saveSocialMediaTracking({ commit }, params) {
    commit('SET_LOADING', true);
    const response = await API.saveSocialMediaTracking(params);
    commit('SET_LOADING', false);

    return response;
  },
  async setIntegrations({ commit }, params) {
    commit('SET_LOADING', true);

    const response = await APIIntegrations.getPropertyIntegrations(params);

    if (response.status === 200) {
      commit('SET_INTEGRATIONS', response.data.records);
    }

    commit('SET_LOADING', false);
  },
  async createQrCodeTracking({ commit }, params) {
    commit('SET_LOADING', true);
    const response = await API.createQrCodeTracking(params);
    commit('SET_LOADING', false);
    return response;
  },
  setShareModalLinks({ commit }, payload) {
    commit('SET_SHARE_LINKS', payload);
  },
  setShareModalData({ commit }, payload) {
    commit('SET_MODAL_DATA', payload);
  },
  setModalComponents({ commit }, payload) {
    commit('SET_MODAL_COMPONENTS', payload);
  },
  setModalNavigation({ commit }, payload) {
    commit('SET_MODAL_NAVIGATION', payload);
  },
  setActiveInnerTab({ commit }, payload) {
    commit('SET_ACTIVE_INNER_TAB', payload);
  },
  setActiveComponent({ dispatch, state, commit }, name) {
    dispatch('setActiveInnerTab', 0);
    const activeIndex = state.modalComponents.findIndex((el) => el.componentId === name);
    commit('SET_ACTIVE_COMPONENT', activeIndex);
  },
  toggleTabMark({ state, commit }, payload) {
    let tabs = {};

    if (Object.keys(payload).length) tabs = { ...state.displayTabMark, ...payload };

    commit('SET_TAB_MARK', tabs);
  },
  setSelected({ commit }, payload) {
    commit('SET_SELECTED', payload);
  },
  setWidth({ commit }, payload) {
    commit('SET_WIDTH', payload);
  },
  setHeight({ commit }, payload) {
    commit('SET_HEIGHT', payload);
  },
  setLoading({ commit }, payload) {
    commit('SET_LOADING', payload);
  },
  resetModal({ commit }) {
    commit('RESET');
  }
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  mutations,
  actions
};
