/* eslint no-shadow: ["error", { "allow": ["state"] }] */

function initialState() {
  return {
    currentFolderId: null,
    currentFolderTitle: '',
    subFolderOpened: false,
    navigationMenu: []
  };
}

const getters = {
  subFolderOpened: (state) => state.subFolderOpened,
  currentFolderId: (state) => state.currentFolderId,
  currentFolderTitle: (state) => state.currentFolderTitle,
  navigationMenu: (state) => state.navigationMenu
};

const mutations = {
  SET_SUB_FOLDER_OPENED(state, opened) {
    state.subFolderOpened = opened;
  },
  SET_CURRENT_FOLDER_ID(state, currentFolderId) {
    state.currentFolderId = currentFolderId;
  },
  SET_CURRENT_FOLDER_TITLE(state, currentFolderTitle) {
    state.currentFolderTitle = currentFolderTitle;
  },
  ADD_NAVIGATION_MENU(state, data) {
    state.navigationMenu.push(data);
  },
  UPDATE_NAVIGATION_MENU(state, data) {
    state.navigationMenu = data;
  },
  RESET(state) {
    const initial = initialState();

    Object.keys(initial).forEach((key) => {
      state[key] = initial[key];
    });
  }
};

const actions = {
  setCurrentFolderId({ commit }, folderId) {
    commit('SET_SUB_FOLDER_OPENED', true);
    commit('SET_CURRENT_FOLDER_ID', folderId);
  },
  setCurrentFolderTitle({ commit }, currentFolderTitle) {
    commit('SET_CURRENT_FOLDER_TITLE', currentFolderTitle);
  },
  updateNavigationMenu({ commit, state }, folder) {
    const index = state.navigationMenu.findIndex((f) => f.id === folder.id);
    const newNav = state.navigationMenu;
    newNav.length = index + 1;

    commit('UPDATE_NAVIGATION_MENU', newNav);
  },
  addToNavigationMenu({ commit }, folder) {
    commit('ADD_NAVIGATION_MENU', folder);
  },
  async resetSubFolderNavigation({ commit }) {
    await commit('RESET');
  }
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  mutations,
  actions
};
