import userApi from '@api/user.api';
import corporateApi from '@api/corporate.api';
import LiveTourApi from '@api/liveTour.api';

function initialState() {
  return {
    userSettings: {},
    userBrandingSettings: {},
    mapCategories: [],
    userPins: [],
    editingPin: {}
  };
}

const getters = {
  userSettings: (state) => state.userSettings,
  userBrandingSettings: (state) => state.userBrandingSettings,
  mapCategories: (state) => state.mapCategories,
  userPins: (state) => state.userPins,
  editingPin: (state) => state.editingPin
};

const mutations = {
  SET_USER_SETTINGS(state, payload) {
    state.userSettings = payload;
  },
  SET_USER_BRANDING_SETTINGS(state, payload) {
    state.userBrandingSettings = payload;
  },
  SET_MAP_CATEGORIES(state, payload) {
    state.mapCategories = payload;
  },
  SET_USER_PINS(state, payload) {
    state.userPins = payload;
  },
  SET_EDITING_PIN(state, payload) {
    state.editingPin = payload;
  },
  UPDATE_PIN(state, payload) {
    const pinIndex = state.userPins.findIndex((el) => el.identifier === payload.identifier);
    Object.assign(state.userPins[pinIndex], payload);
  },
  RESET(state) {
    const initial = initialState();

    Object.keys(initial).forEach((key) => {
      state[key] = initial[key];
    });
  }
};

const actions = {
  async getUserSettings({ commit }) {
    const response = await userApi.getUserSettings();

    if (response.status === 200) {
      commit('SET_USER_SETTINGS', response.data.record);
    }

    return response;
  },
  async getUserBrandingSettings({ commit }, propertyId) {
    const response = await userApi.getUserBrandingSettings({
      propertyId
    });

    if (response.status === 200) {
      commit('SET_USER_BRANDING_SETTINGS', response.data);
    }

    return response;
  },
  async getMapCategories({ commit }) {
    const { data } = await LiveTourApi.getMapCategories();

    data.records.unshift({ title: 'None', key: 'none' });

    commit('SET_MAP_CATEGORIES', data.records);
  },
  async getUserPins({ commit }, propertyId) {
    const response = await corporateApi.getPropertyPins(propertyId);

    commit('SET_USER_PINS', response.data.records);
  },
  // eslint-disable-next-line no-unused-vars
  async deletePin({ state }, { propertyId, pinId }) {
    await corporateApi.deletePropertyPin(propertyId, pinId);
  },
  async putPins({ state, commit }, { propertyId, pins }) {
    commit('SET_USER_PINS', [...state.userPins, ...pins]);

    await corporateApi.putPropertyPins(propertyId, { pins });
  },
  setEditingPin({ commit }, payload) {
    commit('SET_EDITING_PIN', payload);
  },
  async updateUserPin({ commit }, { propertyId, pin }) {
    const response = await corporateApi.updatePropertyPin(propertyId, pin.identifier, {
      title: pin.title,
      subTitle: pin.subTitle
    });

    commit('UPDATE_PIN', pin);

    return response;
  },
  resetUser({ commit }) {
    commit('RESET');
  }
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  mutations,
  actions
};
