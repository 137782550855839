function initialState() {
  return {
    selection: [],
    options: []
  };
}

const getters = {
  selection: (state) => state.selection,
  options: (state) => state.options
};

const mutations = {
  SET_OPTIONS(state, payload) {
    state.options = payload;
  },
  SELECT_ITEM(state, payload) {
    state.selection.splice(state.selection.length, 0, payload);
  },
  DESELECT_ITEM(state, payload) {
    const index = state.selection.indexOf(payload);
    state.selection.splice(index, 1);
  },
  SET_ITEMS_SELECTION(state, payload) {
    state.selection = payload;
  },
  RESET(state) {
    const initial = initialState();

    Object.keys(initial).forEach((key) => {
      state[key] = initial[key];
    });
  }
};

const actions = {
  setOptions({ commit }, payload) {
    commit('SET_OPTIONS', payload);
  },
  selectItem({ commit }, payload) {
    commit('SELECT_ITEM', payload);
  },
  deselectItem({ commit }, payload) {
    commit('DESELECT_ITEM', payload);
  },
  selectAll({ state, commit }) {
    commit('SET_ITEMS_SELECTION', [...state.options]);
  },
  deselectAll({ commit }) {
    commit('SET_ITEMS_SELECTION', []);
  },
  setItemsSelection({ commit }, payload) {
    commit('SET_ITEMS_SELECTION', [...payload]);
  },
  resetMultiselectState({ commit }) {
    commit('RESET');
  }
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  mutations,
  actions
};
