import hostVideoAPI from '@/api/hostVideo.api';
import hostVideoTypeAPI from '@/api/hostVideoType.api';
import { convertUnicode } from '@/helpers/common.helper';
import { segmentsFormatDuration } from '@/helpers/segments';
import EventBus from '@/helpers/eventBus';

const normalizeClipTitle = (title) => {
  const title16 = convertUnicode(title, 16);
  const title8 = convertUnicode(title16, 8);

  return decodeURIComponent(title8);
};

const normalizeClips = (data, modifiedAttributes) => {
  const clips = [];

  data.segments.forEach((segment) => {
    const clipsBySegment = segment.clipsUdids.map((clipUdid) => {
      let clip = data.records.find((c) => c.udid === clipUdid);

      if (modifiedAttributes?.newClipsIds?.includes(clip.id)) {
        let modifiedTitle = modifiedAttributes.attributes.title;

        if (modifiedTitle?.length && modifiedAttributes.action === 'cut' && modifiedAttributes.newClipsIds[1] === clip.id) {
          modifiedTitle = `${modifiedTitle}_2`;
        }

        clip = {
          ...clip,
          ...modifiedAttributes.attributes,
          title: modifiedTitle,
          startCaption: '0',
          finishCaption: clip.duration
        };
      }

      return {
        ...clip,
        segmentId: segment.id,
        segmentName: segment.name,
        process: [],
        title: normalizeClipTitle(clip.title)
      };
    });

    clips.push(clipsBySegment);
  });

  return clips.flat();
};

const normalizeSegmentsForApi = (segments) => {
  let segPos = 0;

  const normalizedSegments = segments.map((segment) => {
    const {
      duration,
      clipsUdids,
      name,
      startTime,
      state,
      customSegment,
      id
    } = segment;

    // Do not send 'new' (created) segments on FE without clips
    if (state === 'new' && !clipsUdids.length) {
      return false;
    }

    // As we are skipping 'new' FE created segments
    // We also need to recalculate position for not 'deleted' segments
    if (state !== 'deleted') {
      segPos += 1;
    }

    return {
      duration,
      clipsUdids,
      name,
      position: state !== 'deleted' ? segPos : 999,
      startTime,
      state,
      ...customSegment && { customSegment },
      ...(state !== 'new') && { id }
    };
  }).filter(Boolean);

  return normalizedSegments;
};

const clipsForApi = (stateClips) => {
  const clips = [];

  stateClips.forEach((el) => {
    let clip = el;

    if (!el.title?.length) {
      clip = { ...clip, title: '' };
    }

    if (el.specificCaptionColor?.length) {
      clip = { ...clip, colorCaption: el.specificCaptionColor };
    }

    if (el.xCoordinate?.length) {
      clip = { ...clip, xCoordinate: Number(el.xCoordinate) };
    }

    if (el.yCoordinate?.length) {
      clip = { ...clip, yCoordinate: Number(el.yCoordinate) };
    }

    if (el.widthCoordinate?.length) {
      clip = { ...clip, widthCoordinate: Number(el.widthCoordinate) };
    }

    if (el.heightCoordinate?.length) {
      clip = { ...clip, heightCoordinate: Number(el.heightCoordinate) };
    }

    if (el.angleOfCaption?.length) {
      clip = { ...clip, angleOfCaption: Number(el.angleOfCaption) };
    }

    if (el.fontSize?.length) {
      clip = { ...clip, fontSize: Number(el.fontSize) };
    }

    clips.push(clip);
  });

  return clips;
};

const initialState = () => ({
  clips: [],
  video: {},
  modifiedAttributes: null,
  segments: [],
  selectedClip: null,
  defaultCaptionStyle: {
    position: 'top-center',
    color: '#ffffff',
    font: {
      fontName: 'Lato-Regular',
      fontFace: 'Lato'
    }
  }
});

const moduleGetters = {
  segments: (state) => state.segments,
  clips: (state) => state.clips,
  clipsForApi: (state) => clipsForApi(state.clips),
  video: (state) => state.video,
  visibleSegments: (state) => state.segments.filter((s) => s.state !== 'deleted'),
  selectedClip: (state) => state.selectedClip,
  selectedClipDuration: (state) => Number(state.selectedClip?.duration).toFixed(2),
  defaultCaptionStyle: (state) => state.defaultCaptionStyle
};

const mutations = {
  SET_SEGMENTS(state, payload) {
    state.segments = payload;
  },
  SET_EDITING_VIDEO(state, payload) {
    if (!payload.unitId) delete payload.unitId;

    let videoAccessibility = false;

    if (typeof payload.accessibility === 'boolean') {
      videoAccessibility = payload.accessibility;
    }

    if (payload.accessibility && Object.keys(payload.accessibility).length) {
      videoAccessibility = payload.accessibility.accessibility;
    }

    state.video = {
      ...payload,
      accessibility: videoAccessibility
    };
  },
  CREATE_SEGMENT(state, payload) {
    const visibleSegments = state.segments.filter((s) => s.state !== 'deleted');
    let startTime = 0;

    if (visibleSegments.length) {
      const lastSegment = visibleSegments[visibleSegments.length - 1];
      startTime = (parseFloat(lastSegment.startTime) + parseFloat(lastSegment.duration)).toFixed(2);
    }

    const customSegment = typeof payload.customSegment === 'undefined' ? true : payload.customSegment;

    state.segments.push({
      clipsUdids: payload.clipsUdids || [],
      customSegment,
      duration: 0,
      id: Date.now(),
      name: payload.name,
      position: visibleSegments.length + 1,
      state: 'new',
      startTime,
      isOpen: !!payload.isOpen
    });
  },
  UPDATE_SEGMENT(state, payload) {
    state.segments = state.segments.map((segment) => {
      if (segment.id === payload.id) {
        return { ...segment, ...payload };
      }

      return segment;
    });
  },
  UPDATE_SEGMENT_CLIPS(state, payload) {
    const { clips, segment } = payload;

    state.clips = state.clips.map((clip) => {
      const clipIndex = clips.findIndex((c) => c.id === clip.id);

      if (clipIndex > -1) {
        const updateClip = { ...clip, segmentId: segment.id, segmentName: segment.name };

        return updateClip;
      }

      return clip;
    });
  },
  DELETE_SEGMENT(state, segment) {
    const index = state.segments.findIndex((s) => s.id === segment.id);

    if (index > -1) {
      if (segment.state === 'new') {
        state.segments = state.segments.filter((s) => s.id !== segment.id);
      } else {
        const oldSegment = state.segments[index];

        state.segments.splice(index, 1, { ...oldSegment, state: 'deleted' });
        state.clips = state.clips.filter((c) => c.segmentId !== segment.id);
      }
    }
  },
  DELETE_ALL_SEGMENTS(state) {
    state.segments = state.segments.map((segment) => {
      if (segment.state === 'new') {
        return segment;
      }

      return { ...segment, state: 'deleted' };
    }).filter((s) => s.state !== 'new');
  },
  UPDATE_ALL_SEGMENTS(state) {
    state.segments = segmentsFormatDuration({
      segments: state.segments,
      clips: state.clips
    });
  },
  POPULATE_SEGMENTS_FROM_DEFAULTS(state, payload) {
    const visibleSegments = state.segments.filter((s) => s.state !== 'deleted');
    let names = [];

    if (visibleSegments.length) {
      names = visibleSegments.map((s) => s.name);
    }

    // Skip default segment creation if the segment with the same name already exists
    if (names.includes(payload.name)) {
      return;
    }

    state.segments.push({
      clipsUdids: [],
      customSegment: false,
      duration: 0,
      id: payload.id,
      name: payload.name,
      position: visibleSegments.length + 1,
      state: 'new',
      startTime: 0
    });
  },
  POPULATE_SEGMENTS_ON_REFRESH(state, payload) {
    // Merge segments with new empty segments that exist only locally to preserve positions
    const mergedSegments = [];
    const visibleSegments = state.segments.filter((s) => s.state !== 'deleted');
    const positionsFromApi = payload.map((s) => s.position);
    const positionsFromLocal = visibleSegments.map((s) => s.position);
    const missingPositionsFromApi = positionsFromLocal.filter((ss) => ss > positionsFromApi[positionsFromApi.length - 1]);
    let newPos = 0;

    const rec = (apiSegment) => {
      newPos += 1;
      const localSegment = state.segments.find((ls) => ls.position === newPos);

      if (localSegment && localSegment.state === 'new' && !localSegment.clipsUdids.length) {
        mergedSegments.push({ ...localSegment, position: newPos });

        rec(apiSegment);
      } else {
        mergedSegments.push({ ...apiSegment, position: newPos, isOpen: localSegment?.isOpen });
      }
    };

    payload.forEach((apiSegment) => {
      rec(apiSegment);
    });

    missingPositionsFromApi.forEach((pos) => {
      if (pos <= newPos) return;

      const localSegment = visibleSegments[pos - 1];

      if (localSegment && localSegment.state === 'new' && !localSegment.clipsUdids.length) {
        newPos += 1;
        mergedSegments.push({ ...localSegment, position: newPos });
      }
    });

    state.segments = mergedSegments;
  },
  REMOVE_EMPTY_SEGMENTS(state) {
    state.segments = state.segments.filter((s) => {
      if (s.clipsUdids.length) {
        return s;
      }

      if (!s.clipsUdids.length && (s.state !== 'new')) {
        return { ...s, state: 'deleted' };
      }

      return false;
    }).filter(Boolean);
  },
  SET_CLIPS(state, payload) {
    state.clips = payload;
  },
  UPDATE_SELECTED_CLIP(state, payload) {
    const { process } = payload;

    state.selectedClip = {
      ...state.selectedClip,
      ...payload,
      ...(process?.length && {
        process
      })
    };
  },
  UPDATE_CLIP(state, payload) {
    const { process } = payload;
    const index = state.clips.findIndex((c) => c.id === payload.id);

    if (index > -1) {
      state.clips.splice(index, 1, {
        ...state.clips[index],
        ...payload,
        ...(process?.length && {
          process
        })
      });
    }
  },
  UPDATE_ALL_CLIPS(state, payload) {
    const { process } = payload;

    state.clips = state.clips.map((clip) => ({
      ...clip,
      ...payload,
      ...(process?.length && {
        process
      })
    }));
  },
  DELETE_CLIP(state, clip) {
    const filteredClips = state.clips.filter((c) => c.id !== clip.id);
    state.clips = filteredClips;

    state.segments = state.segments.map((s) => {
      if (s.id === clip.segmentId) {
        const newClipsUdids = s.clipsUdids.filter((udid) => udid !== clip.udid);

        return { ...s, clipsUdids: newClipsUdids };
      }

      return s;
    });
  },
  SET_SELECTED_CLIP(state, payload) {
    state.selectedClip = payload;
  },
  SET_DEFAULT_CAPTION_STYLE(state, payload) {
    state.defaultCaptionStyle = payload; // fontCaption, colorCaption, specificCaptionColor

    state.clips = state.clips.map((el) => ({
      ...el,
      ...payload
    }));

    state.selectedClip = {
      ...state.selectedClip,
      ...payload
    };
  },
  SET_MODIFIED_ATTRIBUTES(state, payload) {
    if (!payload?.clipIds) {
      state.modifiedAttributes = null;

      return;
    }

    const {
      captionPosition,
      widthCoordinate,
      heightCoordinate,
      xCoordinate,
      yCoordinate,
      colorCaption,
      specificCaptionColor,
      fontCaption,
      title
    } = state.selectedClip;

    state.modifiedAttributes = {
      action: payload.action,
      newClipsIds: payload.clipIds,
      attributes: {
        captionPosition,
        widthCoordinate,
        heightCoordinate,
        xCoordinate,
        yCoordinate,
        colorCaption,
        specificCaptionColor,
        fontCaption,
        title
      }
    };
  },
  RESET(state) {
    const initial = initialState();

    Object.keys(initial).forEach((key) => {
      state[key] = initial[key];
    });
  }
};

const actions = {
  async getEditableClips({ state, commit, dispatch }, videoUdid) {
    const response = await hostVideoAPI.getEditableClips(videoUdid);

    if (response.status === 200) {
      const { segments, records } = response.data;

      if (segments.length) {
        const clips = normalizeClips({ segments, records });

        commit('SET_SEGMENTS', segments);
        commit('SET_CLIPS', clips);
        commit('UPDATE_ALL_SEGMENTS');
      } else {
        records.sort((a, b) => a.position - b.position).forEach((r) => {
          commit('CREATE_SEGMENT', {
            clipsUdids: [r.udid],
            customSegment: false,
            name: ''
          });
        });

        const clips = normalizeClips({ segments: state.segments, records });

        commit('SET_CLIPS', clips);
        commit('UPDATE_ALL_SEGMENTS');
        await dispatch('updateSegmentsApi');
      }
    }
  },
  async refreshEditableClips({ state, commit }, payload) {
    const response = await hostVideoAPI.getEditableClips(state.video.udid);

    if (response.status === 200) {
      let clips = normalizeClips(response.data, state.modifiedAttributes);
      commit('SET_MODIFIED_ATTRIBUTES', null);
      const existingClips = state.clips;

      clips = clips.map((c) => {
        const existingClip = existingClips.find((ec) => ec.id === c.id);

        if (existingClip) {
          return { ...c, ...existingClip, segmentId: c.segmentId };
        }

        return c;
      });

      commit('POPULATE_SEGMENTS_ON_REFRESH', response.data.segments);
      commit('SET_CLIPS', clips);
      commit('UPDATE_ALL_SEGMENTS');

      if (payload?.clipToActive) {
        const clip = clips.find((c) => c.id === payload.clipToActive);

        commit('SET_SELECTED_CLIP', clip);
      }
    }
  },
  setSegments({ commit }, payload) {
    commit('SET_SEGMENTS', payload);
  },
  createSegment({ commit }, segmentName) {
    commit('CREATE_SEGMENT', { name: segmentName, isOpen: true });
  },
  updateSegmentLocal({ commit }, payload) {
    commit('UPDATE_SEGMENT', payload);
  },
  updateSegmentName({ state, commit }, payload) {
    commit('UPDATE_SEGMENT', payload);

    const clips = state.clips.filter((c) => c.segmentId === payload.id);

    commit('UPDATE_SEGMENT_CLIPS', {
      segment: payload,
      clips
    });

    if (state.selectedClip) {
      const clip = state.clips.find((c) => c.id === state.selectedClip.id);

      if (clip) {
        commit('SET_SELECTED_CLIP', clip);
      }
    }
  },
  async updateSegmentsApi({ state: moduleState, rootGetters, dispatch }, eventType) {
    // Set up unload and preview triggers
    EventBus.$emit('content-changed', true);

    const segmentsForApi = normalizeSegmentsForApi(moduleState.segments);

    if (eventType === 'updateName') {
      await hostVideoAPI.updateVideoSegments(moduleState.video.id, {
        propertyId: rootGetters['user/currentUser'].activeProperty.id,
        segments: segmentsForApi
      });

      return;
    }

    // For re-order of clips/segments:
    // - ignore segments with 'deleted' and 'new' states
    // - duplicate segments without 'state'
    // - set state to `new` for just duplicated segments
    // - set state to `deleted` for original segments without 'state'
    const extendedSegmentsForApi = segmentsForApi.reduce((res, current) => {
      if (['deleted', 'new'].includes(current.state)) {
        return [...res, current];
      }

      if (!current.clipsUdids.length) {
        return [...res, { ...current, state: 'deleted' }];
      }

      return [...res, { ...current, state: 'deleted' }, { ...current, state: 'new' }];
    }, []);

    await hostVideoAPI.updateVideoSegments(moduleState.video.id, {
      propertyId: rootGetters['user/currentUser'].activeProperty.id,
      segments: extendedSegmentsForApi
    });

    // Refresh segments list from API
    await dispatch('refreshEditableClips', { clipToActive: moduleState.selectedClip?.id });
  },
  async deleteSegment({ commit, state, dispatch }, segment) {
    const clipIdsToDelete = segment.clipsUdids.map((clipUdid) => {
      const clip = state.clips.find((c) => c.udid === clipUdid);

      return clip?.id;
    });

    commit('DELETE_SEGMENT', segment);
    commit('UPDATE_ALL_SEGMENTS');

    // Do not send API request in case if the segment was just created and clips weren't added to it
    // as it doesn't exist on API yet
    if (segment.state !== 'new') {
      await dispatch('deleteClipsApi', clipIdsToDelete);
      await dispatch('updateSegmentsApi');
    }
  },
  async deleteAllSegments({
    commit, state, dispatch, getters
  }) {
    // Mark all segments as deleted
    commit('DELETE_ALL_SEGMENTS');

    const clipsUdids = state.clips.map((c) => c.udid);

    // Create new segment with all clips from deleted
    commit('CREATE_SEGMENT', {
      name: '',
      customSegment: false,
      clipsUdids
    });

    commit('UPDATE_SEGMENT_CLIPS', {
      clips: state.clips,
      segment: getters.visibleSegments[0]
    });

    commit('UPDATE_ALL_SEGMENTS');

    if (state.selectedClip) {
      const clip = state.clips.find((c) => c.id === state.selectedClip.id);

      if (clip) {
        commit('SET_SELECTED_CLIP', clip);
      }
    }

    await dispatch('updateSegmentsApi');
  },
  updateSegmentClips({ state, commit }, payload) {
    commit('UPDATE_SEGMENT_CLIPS', payload);

    const clipsUdids = payload.clips.map((c) => c.udid);

    commit('UPDATE_SEGMENT', {
      id: payload.segment.id,
      clipsUdids
    });

    if (state.selectedClip) {
      const clip = state.clips.find((c) => c.id === state.selectedClip.id);

      if (clip) {
        commit('SET_SELECTED_CLIP', clip);
      }
    }
  },
  updateClip({ state, commit }, payload) {
    if (state.selectedClip) {
      commit('UPDATE_SELECTED_CLIP', payload);
    }

    commit('UPDATE_CLIP', payload);
  },
  updateAllClips({ state, commit }, payload) {
    if (state.selectedClip) {
      commit('UPDATE_SELECTED_CLIP', payload);
    }

    commit('UPDATE_ALL_CLIPS', payload);
  },
  async deleteClip({ commit, state, dispatch }, clip) {
    const segment = state.segments.find((s) => s.id === clip.segmentId);

    // If it's the last clip of the segment then delete the segment as well
    if (segment.clipsUdids.length === 1 && segment.clipsUdids[0] === clip.udid) {
      dispatch('deleteSegment', segment);
    } else {
      await commit('DELETE_CLIP', clip);
      await dispatch('deleteClipsApi', [clip.id]);
      await dispatch('updateAllSegments');
      await dispatch('updateSegmentsApi');
    }
  },
  // eslint-disable-next-line no-empty-pattern
  async deleteClipsApi({ }, payload) {
    await hostVideoAPI.deleteClips({ ids: payload, type: 'web' });
  },
  async updateAllSegments({ commit }) {
    await commit('UPDATE_ALL_SEGMENTS');
  },
  setSelectedClip({ commit }, payload) {
    commit('SET_SELECTED_CLIP', payload);
  },
  resetVideoEditStore({ commit }) {
    commit('RESET');
  },
  setDefaultCaptionStyles({ commit }, payload) {
    commit('SET_DEFAULT_CAPTION_STYLE', payload);
  },
  getBrandingSettings({ dispatch }) {
    dispatch('user/getUserBrandingSettings', null, { root: true });
    dispatch('user/getUserSettings', null, { root: true });
  },
  async publish({ state, getters }) {
    const {
      autoShareEnabled,
      hostVideoTypeTitle,
      ...video
    } = getters.video;

    const validatedAutoShareEnabled = hostVideoTypeTitle === 'Unit' ? autoShareEnabled : false;

    const clipsResponse = await hostVideoAPI.saveUnpublishedClips({ clips: getters.clipsForApi });

    if (clipsResponse.status !== 200) {
      return [clipsResponse];
    }

    const videoResponse = await hostVideoAPI.saveVideo({
      ...video,
      autoShare: validatedAutoShareEnabled,
      source: 'WEB',
      clipsCount: state.clips.length
    });

    return [clipsResponse, videoResponse];
  },
  async saveDraft({ state, getters }) {
    const {
      autoShareEnabled,
      hostVideoTypeTitle,
      ...video
    } = getters.video;

    const validatedAutoShareEnabled = hostVideoTypeTitle === 'Unit' ? autoShareEnabled : false;

    const clipsResponse = await hostVideoAPI.saveUnpublishedClips({ clips: getters.clipsForApi });

    if (clipsResponse.status !== 200) {
      return [clipsResponse];
    }

    const videoResponse = await hostVideoAPI.saveVideoDraft({
      ...video,
      autoShare: validatedAutoShareEnabled,
      source: 'WEB',
      clipsCount: state.clips.length
    });

    return [clipsResponse, videoResponse];
  },
  async getDefaultSegments({ commit }, payload) {
    if (payload.clear) {
      commit('REMOVE_EMPTY_SEGMENTS');
    } else {
      const response = await hostVideoTypeAPI.getHostVideoTypeDefaultSegments(payload.hostVideoTypeId);

      if (response.status !== 200) {
        return;
      }

      const { data: { records } } = response;

      commit('REMOVE_EMPTY_SEGMENTS');

      if (records?.length) {
        records.forEach((r) => {
          commit('POPULATE_SEGMENTS_FROM_DEFAULTS', r);
        });
      }
    }

    commit('UPDATE_ALL_SEGMENTS');
  },
  setVideo({ commit }, payload) {
    commit('SET_EDITING_VIDEO', payload);
  },
  saveModifiedAttributes({ commit }, payload) {
    commit('SET_MODIFIED_ATTRIBUTES', payload);
  },
  async getFrames({ state, commit }, payload) {
    const { clipId, framesCount } = payload;

    commit('UPDATE_CLIP', {
      id: clipId,
      framesLoading: true
    });

    if (state.selectedClip?.id === clipId) {
      commit('UPDATE_SELECTED_CLIP', {
        framesLoading: true
      });
    }

    const response = await hostVideoAPI.generateTimelineImages(clipId, { framesCount });
    let frames = [];

    if (response.status === 200) {
      frames = response.data.records;
    }

    commit('UPDATE_CLIP', {
      id: clipId,
      framesLoading: false,
      frames
    });

    if (state.selectedClip?.id === clipId) {
      commit('UPDATE_SELECTED_CLIP', {
        framesLoading: false,
        frames
      });
    }
  }
};

export default {
  namespaced: true,
  state: initialState,
  getters: moduleGetters,
  mutations,
  actions
};
