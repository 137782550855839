const segmentsFormatDuration = ({ segments, clips, calculatePlaybackRate = false }) => {
  // Need to recalculate duration, start time, position for all clips and segments
  let startTime = 0.00;
  let duration = 0.00;
  let segPos = 0;

  const markedDeletedSegments = segments.map((segment) => {
    if (segment.state === 'deleted') {
      return { ...segment, position: 999 };
    }

    if (segment.state !== 'new' && !segment.clipsUdids?.length) {
      return {
        ...segment,
        clipsUdids: ['1'],
        state: 'deleted',
        position: 999
      };
    }

    return segment;
  });

  const visibleSegments = markedDeletedSegments.filter((s) => s.state !== 'deleted');

  // If there is a clip with duration less than 1 - adjust the fade in/out duration to be lower
  const minClipDuration = Math.min(...clips.map((c) => {
    if (calculatePlaybackRate) {
      return Number(c.duration) / Number(c.playbackRate);
    }

    return Number(c.duration);
  }));
  const fadeInOutInterval = (minClipDuration / 2) < 1 ? minClipDuration / 2 : 1;

  return markedDeletedSegments.map((segment, index) => {
    if (segment.state === 'deleted') {
      return segment;
    }

    segPos += 1;
    startTime += parseFloat(duration);
    duration = parseFloat(0.00);

    segment.clipsUdids.forEach((clipUdid) => {
      const clip = clips.find((c) => c.udid === clipUdid);

      if (clip) {
        if (calculatePlaybackRate) {
          duration += parseFloat(clip.duration) / Number(clip.playbackRate);
        } else {
          duration += parseFloat(clip.duration);
        }
      }
    });

    let fadeInOutDuration = 0;

    // Calculate duration with transition between the segments/clips
    if (clips.length > 1) {
      // FadeInOut duration for segments:
      // - for the first/last should be 0.5
      // - middle ones should be 1
      let segmentFadeInOutDuration = 0;

      if (visibleSegments.length > 1) {
        segmentFadeInOutDuration = (segPos === 1 || !segments[index + 1]) ? 0.5 : 1;
      }

      const clipsFadeInOutDuration = segment.clipsUdids.length - 1;
      fadeInOutDuration = (clipsFadeInOutDuration + segmentFadeInOutDuration) * fadeInOutInterval;

      duration -= fadeInOutDuration;
    }

    return {
      ...segment,
      position: segPos,
      duration: duration.toFixed(2),
      startTime: startTime.toFixed(2)
    };
  });
};

export {
  segmentsFormatDuration
};
