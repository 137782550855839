import API from '@api/hostVideoType.api';
import corporateAPI from '@api/corporate.api';
import storePagination from '../pagination';

function initialState() {
  return {
    ...storePagination.state,
    records: [],
    filter: '',
    currentHostVideoType: null,
    hostVideoTypeOptionSelected: null
  };
}

const getters = {
  ...storePagination.getters,
  hostVideoTypeOptions: (state) => state.records,
  hostVideoTypeOptionsLoading: (state) => state.loading,
  hostVideoTypeOptionSelected: (state) => state.hostVideoTypeOptionSelected,
  filter: (state) => state.filter,
  hvtoShowMore: (state) => state.showMore
};

const mutations = {
  ...storePagination.mutations,
  SET_HVTO_RECORDS(state, data) {
    state.records = data;
  },
  SET_FILTER(state, filter) {
    state.filter = filter;
  },
  ADD_HVTO_RECORDS(state, data) {
    state.records.push(...data);
  },
  SET_CURRENT_HVT(state, hostVideoType) {
    state.currentHostVideoType = hostVideoType;
  },
  SET_HVTO_SELECTED(state, data) {
    state.hostVideoTypeOptionSelected = data;
  },
  RESET(state) {
    const initial = initialState();

    Object.keys(initial).forEach((key) => {
      state[key] = initial[key];
    });
  }
};

const actions = {
  async apiGetHVTOs({ state, rootState }, params) {
    let response;

    if (rootState.hostVideoType.propertyId) {
      response = await corporateAPI.getPropertyVideoTypeOptions(
        rootState.hostVideoType.propertyId,
        {
          perPage: state.perPage,
          title: state.currentHostVideoType.title,
          ...params
        }
      );
    } else {
      response = await API.getHostVideoTypeOptions(
        state.currentHostVideoType.id,
        { perPage: state.perPage, ...params }
      );
    }

    return response;
  },
  async getHostVideoTypeOptions({ state, commit, dispatch }, params) {
    commit('SET_LOADING', true);
    commit('SET_PAGE', 1);

    const response = await dispatch('apiGetHVTOs', {
      page: state.page,
      q: state.filter,
      ...params
    });

    if (response.status === 200) {
      const { data: { records, pagination, pagiantionData } } = response;

      commit('SET_SHOW_MORE', state.page < (pagination?.pages || pagiantionData?.totalPages));
      commit('SET_HVTO_RECORDS', records);
    }

    commit('SET_LOADING', false);
  },
  async applyFilter({ state, commit, dispatch }, filter) {
    commit('SET_LOADING', true);

    const response = await dispatch('apiGetHVTOs', {
      page: 1,
      q: filter
    });

    if (response.status === 200) {
      const { data: { records, pagination, pagiantionData } } = response;

      commit('SET_PAGE', 1);
      commit('SET_FILTER', filter);
      commit('SET_SHOW_MORE', state.page < (pagination?.pages || pagiantionData?.totalPages));
      commit('SET_HVTO_RECORDS', records);
    }

    commit('SET_LOADING', false);
  },
  async fetchMore({ state, commit, dispatch }, params) {
    commit('SET_FETCHING_MORE', true);

    const nextPage = state.page + 1;

    const response = await dispatch('apiGetHVTOs', {
      page: nextPage,
      q: state.filter,
      ...params
    });

    if (response.status === 200) {
      const { data: { records, pagination, pagiantionData } } = response;

      commit('SET_PAGE', nextPage);
      commit('SET_SHOW_MORE', state.page < (pagination?.pages || pagiantionData?.totalPages));
      commit('ADD_HVTO_RECORDS', records);
    }

    commit('SET_FETCHING_MORE', false);
  },
  setCurrentHostVideoType({ commit }, hostVideoType) {
    commit('SET_CURRENT_HVT', hostVideoType);
  },
  setHostVideoTypeOptionSelected({ commit }, data) {
    let normalizedData;

    if (data && Object.keys(data).length) {
      const id = data.id || data.ids?.[0];

      normalizedData = { ...data, id };
    }

    commit('SET_HVTO_SELECTED', normalizedData);
  },
  resetHostVideoTypeOptions({ commit }, fullReset = true) {
    if (fullReset) {
      commit('RESET');
    } else {
      commit('SET_FILTER', '');
      commit('SET_SHOW_MORE', false);
    }
  }
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  mutations,
  actions
};
