import API from '@api/corporate.api';
import pagination from '../pagination';

function initialState() {
  return {
    ...pagination.state,
    loading: false,
    properties: [],
    filter: ''
  };
}

const getters = {
  ...pagination.getters,
  loading: (state) => state.loading,
  properties: (state) => state.properties,
  filter: (state) => state.filter
};

const mutations = {
  ...pagination.mutations,
  SET_LOADING(state, loading) {
    state.loading = loading;
  },
  SET_PROPERTIES(state, payload) {
    state.properties = payload;
  },
  ADD_PROPERTIES(state, payload) {
    state.properties.push(...payload);
  },
  SET_FILTER(state, payload) {
    state.filter = payload;
  }
};

const actions = {
  // eslint-disable-next-line no-unused-vars
  async apiGetProperties({ state }, body) {
    const response = API.getPropertiesByIds(body);

    return response;
  },
  async applyPropertiesFilter({ state, commit, dispatch }, params) {
    commit('SET_LOADING', true);

    const { data } = await dispatch('apiGetProperties', {
      page: 1,
      perPage: 10,
      ...params
    });

    commit('SET_PAGE', 1);
    commit('SET_FILTER', params);
    commit('SET_SHOW_MORE', state.page < data.pagination.pages);
    commit('SET_PROPERTIES', data.records);
    commit('SET_LOADING', false);
  },
  async fetchMoreProperties({ state, commit, dispatch }) {
    commit('SET_FETCHING_MORE', true);

    const nextPage = state.page + 1;

    const { data } = await dispatch('apiGetProperties', {
      page: nextPage,
      perPage: 10,
      ...state.filter
    });

    commit('SET_PAGE', nextPage);
    commit('SET_SHOW_MORE', state.page < data.pagination.pages);
    commit('ADD_PROPERTIES', data.records);
    commit('SET_FETCHING_MORE', false);
  },
  setLoading({ commit }, payload) {
    commit('SET_LOADING', payload);
  }
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  mutations,
  actions
};
