import moment from 'moment';
import API from '@api/liveTour.api';

function initialState() {
  return {
    presentMode: false,
    loading: false,
    noteTabLoading: false,
    messageTabLoading: false,
    attachmentTabLoading: false,
    mapCategoriesLoading: false,
    defaultPinsLoading: false,
    isPortraitOrientation: true,
    isFullscreenMode: false,
    messagesTabLoading: false,
    liveTour: {
      device: {
        cameraEnabled: true,
        audioEnabled: true
      },
      data: {
        participants: 0,
        timer: null,
        currentParticipant: null,
        participantDataTrack: null,
        messages: [],
        note: '',
        agentJoined: false,
        agentPaused: false,
        attachments: [],
        agentChatState: false,
        mapCategories: [],
        defaultPins: []
      }
    },
    sharedTour: {}
  };
}

const getters = {
  liveTour: (state) => state.liveTour,
  sharedTour: (state) => state.sharedTour,
  loading: (state) => state.loading,
  noteTabLoading: (state) => state.noteTabLoading,
  attachmentTabLoading: (state) => state.attachmentTabLoading,
  messageTabLoading: (state) => state.messageTabLoading,
  mapCategoriesLoading: (state) => state.mapCategoriesLoading,
  participants: (state) => state.liveTour.data.participants,
  currentParticipant: (state) => state.liveTour.data.currentParticipant,
  participantDataTrack: (state) => state.liveTour.data.participantDataTrack,
  messages: (state) => state.liveTour.data.messages,
  messagesTabLoading: (state) => state.messagesTabLoading,
  attachments: (state) => state.liveTour.data.attachments,
  note: (state) => state.liveTour.data.note,
  agentJoined: (state) => state.liveTour.data.agentJoined,
  agentPaused: (state) => state.liveTour.data.agentPaused,
  agentChatState: (state) => state.liveTour.data.agentChatState,
  mapCategories: (state) => state.liveTour.data.mapCategories,
  defaultPins: (state) => state.liveTour.data.defaultPins,
  presentMode: (state) => state.presentMode,
  isPortraitOrientation: (state) => state.isPortraitOrientation,
  isFullscreenMode: (state) => state.isFullscreenMode
};

const mutations = {
  SET_CAMERA_STATE(state, data) {
    state.liveTour.device.cameraEnabled = data.cameraEnabled;
  },
  SET_AUDIO_STATE(state, data) {
    state.liveTour.device.audioEnabled = data.audioEnabled;
  },
  SET_LIVE_TOUR(state, data) {
    state.liveTour.record = data;
  },
  SET_SINGLE_LOADING(state, data) {
    state.loading = data;
  },
  SET_ATTACHMENT_TAB_LOADING(state, data) {
    state.attachmentTabLoading = data;
  },
  SET_NOTE_TAB_LOADING(state, data) {
    state.noteTabLoading = data;
  },
  SET_MESSAGE_TAB_LOADING(state, data) {
    state.messageTabLoading = data;
  },
  SET_MAP_CATEGORIES_LOADING(state, data) {
    state.mapCategoriesLoading = data;
  },
  DEFAULT_PINS_LOADING(state, data) {
    state.defaultPinsLoading = data;
  },
  SET_DEFAULT_PINS(state, data) {
    state.liveTour.data.defaultPins = data;
  },
  SET_LIVE_TOUR_MESSAGES(state, data) {
    state.liveTour.data.messages = data;
  },
  SET_LIVE_TOUR_NOTE(state, data) {
    state.liveTour.data.note = data;
  },
  SET_LIVE_TOUR_ATTACHMENTS(state, data) {
    state.liveTour.data.attachments = data;
  },
  SET_PARTICIPANT_STATE(state, data) {
    state.liveTour.data.participants = data;
  },
  SET_CURRENT_PARTICIPANT_STATE(state, data) {
    state.liveTour.data.currentParticipant = data;
  },
  SET_DATA_TRACK_PARTICIPANT_STATE(state, data) {
    state.liveTour.data.participantDataTrack = data;
  },
  SET_MESSAGE_STATE(state, data) {
    state.liveTour.data.messages = data;
  },
  ADD_MESSAGE(state, payload) {
    state.liveTour.data.messages.push(payload);
  },
  SET_AGENT_JOINED_STATE(state, data) {
    state.liveTour.data.agentJoined = data;
  },
  SET_AGENT_PAUSED_STATE(state, data) {
    state.liveTour.data.agentPaused = data;
  },
  SET_AGENT_CHAT_STATE(state, data) {
    state.liveTour.data.agentChatState = data;
  },
  SET_MAP_CATEGORIES(state, data) {
    state.liveTour.data.mapCategories = data;
  },
  SET_PRESENT_MODE(state, data) {
    state.presentMode = data;
  },
  SET_ORIENTATION_STATE(state, data) {
    state.isPortraitOrientation = data;
  },
  SET_FULLSCREEN_STATE(state, data) {
    state.isFullscreenMode = data;
  },
  SET_SHARED_TOUR(state, data) {
    state.sharedTour = data;
  }
};

const actions = {
  setCameraState({ commit }, data) {
    commit('SET_CAMERA_STATE', data);
  },
  setAudioState({ commit }, data) {
    commit('SET_AUDIO_STATE', data);
  },
  setParticipantState({ commit }, data) {
    commit('SET_PARTICIPANT_STATE', data);
  },
  setCurrentParticipantState({ commit }, data) {
    commit('SET_CURRENT_PARTICIPANT_STATE', data);
  },
  setDataTrackParticipantState({ commit }, data) {
    commit('SET_DATA_TRACK_PARTICIPANT_STATE', data);
  },
  setMessageState({ commit }, data) {
    commit('SET_MESSAGE_STATE', data);
  },
  addMessage({ commit }, payload) {
    commit('ADD_MESSAGE', payload);
  },
  setAgentJoinedState({ commit }, data) {
    commit('SET_AGENT_JOINED_STATE', data);
  },
  setAgentPausedState({ commit }, data) {
    commit('SET_AGENT_PAUSED_STATE', data);
  },
  setAgentChatState({ commit }, data) {
    commit('SET_AGENT_CHAT_STATE', data);
  },
  setOrientationState({ commit }, data) {
    commit('SET_ORIENTATION_STATE', data);
  },
  setFullscreenState({ commit }, data) {
    commit('SET_FULLSCREEN_STATE', data);
  },
  async getLiveTour({ commit }, params) {
    const response = await API.getLiveTour(params);

    if (response.status === 200) {
      const { data } = response;

      commit('SET_LIVE_TOUR', data.record);
      // set allowing private chats in public tour
      commit('SET_AGENT_CHAT_STATE', data.record?.hideClientsChatMessages);
    } else {
      commit('SET_LIVE_TOUR', { not_found: true });
    }
  },
  async getSharedTour({ commit }, tourId) {
    const response = await API.getTour(tourId);

    if (response.status === 200) {
      commit('SET_SHARED_TOUR', response.data.record);
    }
  },
  // eslint-disable-next-line no-empty-pattern
  async guestRSVP({ }, params) {
    return API.guestRSVP(params.tourId, params.payload);
  },
  async saveNote({ commit }, params) {
    const { data } = await API.saveNote(params.tourId, params.payload);

    commit('SET_LIVE_TOUR_NOTE', params.payload.note);

    return data;
  },
  async getNote({ commit }, params) {
    commit('SET_NOTE_TAB_LOADING', true);
    const { data } = await API.getNote(params.tourId, {
      guestId: params.guestId
    });

    commit('SET_LIVE_TOUR_NOTE', data.record);
    commit('SET_NOTE_TAB_LOADING', false);

    return data.record;
  },
  async getMessages({ commit, state }) {
    commit('SET_MESSAGE_TAB_LOADING', true);

    const guestId = state.liveTour.record.currentGuest.id;

    const { data } = await API.getMessages(state.liveTour.record.id, { guestId, agent: false });
    const serializedResponse = [];

    data.records.forEach((message) => {
      serializedResponse.push({
        id: message.id,
        type: 'message',
        text: message.text,
        sender: message.sender,
        currentUser: message.guestId === guestId,
        time: moment(message.createdAt).format('hh:mm a'),
        agent: message.agent,
        visible: message.visible
      });
    });

    commit('SET_LIVE_TOUR_MESSAGES', serializedResponse);
    commit('SET_MESSAGE_TAB_LOADING', false);
  },
  async saveMessage({ state }, data) {
    await API.saveMessage(state.liveTour.record.id, data);
  },
  async getAttachments({ dispatch, state }) {
    await dispatch(
      'attachments/getAttachments',
      {
        apiModule: 'LIVE_TOUR',
        recordId: state.liveTour.record.id
      },
      { root: true }
    );
  },
  async getMapCategories({ commit }) {
    commit('SET_MAP_CATEGORIES_LOADING', true);

    const { data } = await API.getMapCategories();

    data.records.unshift({ title: 'None', key: 'none' });

    commit('SET_MAP_CATEGORIES', data.records);
    commit('SET_MAP_CATEGORIES_LOADING', false);
  },
  async getDefaultPins({ commit, state }) {
    commit('DEFAULT_PINS_LOADING', true);

    const { data } = await API.getDefaultPins(state.liveTour.record.id);

    commit('SET_DEFAULT_PINS', data.records);
    commit('DEFAULT_PINS_LOADING', false);
  },
  getTourBranding({ state, dispatch }) {
    dispatch(
      'brandingHeader/getBrandingHeader',
      { type: 'tour', id: state.liveTour.record.id },
      { root: true }
    );
  },
  setPresentMode({ commit }, params) {
    commit('SET_PRESENT_MODE', params);
  }
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  mutations,
  actions
};
