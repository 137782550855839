import { extend } from 'vee-validate';
import {
  confirmed, required, email, min, max
} from 'vee-validate/dist/rules';

extend('required', {
  ...required,
  message: 'This field is required'
});

extend('email', {
  ...email,
  message: 'This field must be a valid email'
});

extend('url', {
  validate: (value) => {
    const urlRegex = /^https:\/\/[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,}(:[0-9]{1,5})?(\/.*)?$/;

    return urlRegex.test(value);
  },
  message: 'This value must be a valid URL'
});

extend('confirmed', {
  ...confirmed,
  message: 'Passwords must match'
});

extend('strongPassword', {
  validate: (value) => {
    const strongRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~])(?=.{8,})/;
    return strongRegex.test(value);
  },
  message: 'The password must contain: uppercase letter, lowercase letter, number and one special character'
});

extend('uppercase', {
  validate: (value) => value.match(/[A-Z]/g) !== null,
  message: 'Password must include 1 uppercase letter'
});

extend('lowercase', {
  validate: (value) => value.match(/[a-z]/g) !== null,
  message: 'Password must include 1 lowercase letter'
});

extend('number', {
  validate: (value) => value.match(/[0-9]/g) !== null,
  message: 'Password must include 1 number'
});

extend('special', {
  validate: (value) => value.match(/[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]/g) !== null,
  message: 'Password must include 1 symbol'
});

extend('min', {
  ...min,
  message: 'The {_field_} field must be at least {length} characters'
});

extend('max', {
  ...max,
  message: 'The {_field_} field must be not longer than {length} characters'
});
