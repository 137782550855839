export default (time) => {
  const splittedTime = time.split(':');

  const hours = (+splittedTime[0]) * 60 * 60;
  const minutes = (+splittedTime[1]) * 60;

  const secondsAndMilliseconds = splittedTime[2].split('.');

  const seconds = (+secondsAndMilliseconds[0]);
  const convertedMilliseconds = (+secondsAndMilliseconds[1]);
  const milliseconds = convertedMilliseconds > 0 ? convertedMilliseconds / 1000 : 0;

  return hours + minutes + seconds + milliseconds;
};
