import API from '@api/corporate.api';
import { localTimezone } from '@helpers/common.helper';

function initialState() {
  return {
    records: [],
    monthTotal: 0,
    loading: false
  };
}

const getters = {
  records: (state) => state.records,
  monthTotal: (state) => state.monthTotal,
  loading: (state) => state.loading
};

const mutations = {
  SET_DATA(state, data) {
    state.records = data.records;
    state.monthTotal = data.monthTotal || 0;
  },
  SET_LOADING(state, loading) {
    state.loading = loading;
  },
  RESET(state) {
    const initial = initialState();

    Object.keys(initial).forEach((key) => {
      state[key] = initial[key];
    });
  }
};

const actions = {
  async getSectionTotals({ commit }, params) {
    if (params.reset) commit('SET_LOADING', true);

    const getTotals = (params.section === 'Sessions')
      ? API.getPropertyLiveSessionTotals
      : API.getPropertyLiveTourTotals;

    const { data } = await getTotals(
      params.propertyId,
      {
        date: params.date,
        timezone: localTimezone()
      }
    );

    if (data.status !== 'failed') {
      commit('SET_DATA', data);
    }

    commit('SET_LOADING', false);
  }
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  mutations,
  actions
};
