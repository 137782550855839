function initialState() {
  return {
    selectedProperty: {},
    selectedAgendaVideos: [],
    selectedAgendaPlaylists: [],
    previewAgenda: {}
  };
}

const getters = {
  selectedProperty: (state) => state.selectedProperty,
  selectedAgendaVideos: (state) => state.selectedAgendaVideos,
  selectedAgendaPlaylists: (state) => state.selectedAgendaPlaylists,
  previewAgenda: (state) => state.previewAgenda
};

const mutations = {
  SET_SELECTED_PROPERTY(state, data) {
    state.selectedProperty = data;
  },
  SET_SELECTED_AGENDA_VIDEOS(state, data) {
    state.selectedAgendaVideos = data;
  },
  SET_SELECTED_AGENDA_PLAYLISTS(state, data) {
    state.selectedAgendaPlaylists = data;
  },
  SET_PREVIEW_AGENDA(state, data) {
    state.previewAgenda = data;
  },
  RESET(state) {
    const initial = initialState();

    Object.keys(initial).forEach((key) => {
      state[key] = initial[key];
    });
  }
};

const actions = {
  setPreviewAgenda({ commit }, data) {
    commit('SET_PREVIEW_AGENDA', data);
  },
  setSelectedAgendaItems({ commit }, data) {
    const { videos, playlists } = data;

    if (videos) {
      commit('SET_SELECTED_AGENDA_VIDEOS', videos);
    }

    if (playlists) {
      commit('SET_SELECTED_AGENDA_PLAYLISTS', playlists);
    }

    if (!videos && !playlists) {
      commit('SET_SELECTED_AGENDA_VIDEOS', []);
      commit('SET_SELECTED_AGENDA_PLAYLISTS', []);
    }
  },
  setSelectedProperty({ commit }, data) {
    commit('SET_SELECTED_PROPERTY', data);
  },
  resetAgenda({ commit }) {
    commit('RESET');
  }
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  mutations,
  actions
};
