import Vue from 'vue';
import axios from 'axios';
import { getCurrent } from './helpers/currentApiUrl';

const currentApiUrl = getCurrent.path();
const getLiveTourPathRegex = /\/live_tour\/(.*)\/(.*)/;
const getLiveSessionPathRegex = /\/video_sessions\/(.*)/;

const webClientId = '5f47819899085edfd241d762';

localStorage.url = currentApiUrl;

const apiClient = axios.create({
  baseURL: currentApiUrl
});

const apiAuthClient = axios.create({
  baseURL: currentApiUrl,
  headers: {
    Authorization: `${webClientId} nBm0s5d7DyWnWpaF`
  }
});

const refreshAccessToken = async (silentRefresh) => {
  localStorage.refreshingToken = true;

  const { refreshToken } = localStorage;
  const url = '/session/refresh';

  let response;

  await apiClient.post(url, { refreshToken }, { silentErrorNotification: true })
    .then((res) => {
      const { data } = res;

      if (res.status === 200) {
        localStorage.refreshRetryNumber = 0;
        localStorage.accessToken = data.accessToken;
        localStorage.refreshToken = data.refreshToken;
        localStorage.expiresUtc = data.expiresUtc;
        localStorage.expiresIn = data.expiresIn;
        if (data.token) localStorage.token = data.token;
      } else {
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
      }
    }).catch(() => {
      if (!silentRefresh) {
        localStorage.clear();

        window.location = '/sign_in?unauthorized=true';
      } else {
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
        response = 'FAILED';
      }
    });

  localStorage.refreshingToken = false;

  return response;
};

const tryAuthWithCallback = async (callback) => {
  const url = '/session/isAuth';

  if (localStorage.accessToken && localStorage.refreshToken) {
    if (
      !localStorage.lastActivity
      || ((new Date() - new Date(localStorage.lastActivity)) / 1000 > 120)
    ) {
      await apiClient.get(url, { silentErrorNotification: true })
        .then((response) => {
        // For deactivated user we get response with 404 code
          const authorized = response.status !== 404;

          if (authorized) {
            localStorage.lastActivity = new Date();
          }

          callback(authorized);
        });
    } else {
      callback(true);
    }
  } else {
    callback(false);
  }
};

apiClient.interceptors.request.use(
  (request) => {
    const { accessToken } = localStorage;
    const { url } = request;
    const isGetLiveContentPath = getLiveTourPathRegex.test(url) || getLiveSessionPathRegex.test(url);

    if (accessToken && accessToken.length) {
      request.headers.Authorization = `Bearer ${localStorage.accessToken}`;
    }

    if (isGetLiveContentPath && !url.includes('token')) {
      request.headers['Cache-Control'] = 'no-cache, no-store, must-revalidate';
    }

    return request;
  },
  (error) => {
    Promise.reject(error);
  }
);

apiClient.interceptors.response.use(
  async (response) => {
    const originalRequest = response.config;
    const { data, config: { url } } = response;
    const isGetLiveContentPath = getLiveTourPathRegex.test(url) || getLiveSessionPathRegex.test(url);

    if (data && (data.status === 'failed') && !originalRequest.silentErrorNotification) {
      Vue.notify({
        group: 'global',
        title: 'Failed',
        type: 'error',
        text: data.message || 'Something went wrong'
      });
    }

    if (isGetLiveContentPath && !url.includes('token')) {
      response.headers['Cache-Control'] = 'no-cache, no-store, must-revalidate';
    }

    if (localStorage.refreshingToken !== 'true') {
      const secondsToExpire = parseInt((new Date(localStorage.expiresUtc) - new Date()) / (1000), 10);

      // If the token has less than 30% of lifetime then the refresh will be invoked
      if ((secondsToExpire / localStorage.expiresIn) * 100 < 30) {
        localStorage.expiresUtc = new Date(new Date().setDate(new Date().getDate() + 1));

        await refreshAccessToken();
      }
    }

    return response;
  },
  async (error) => {
    const originalRequest = error.config;
    const response = error && error.response;
    const status = response && response.status;

    if (status === 401 && !originalRequest._retry) {
      const { refreshRetryNumber } = localStorage;

      if (refreshRetryNumber > 4) {
        localStorage.clear();

        window.location = '/sign_in?unauthorized=true';
      } else {
        if (localStorage.refreshingToken !== 'true') {
          localStorage.refreshRetryNumber = (refreshRetryNumber || 0) + 1;

          const refreshResponse = await refreshAccessToken(originalRequest.silentRefresh);

          if (originalRequest.silentRefresh && refreshResponse === 'FAILED') {
            return Promise.resolve(error);
          }
        }

        originalRequest._retry = true;

        return apiClient(originalRequest);
      }
    }

    const data = response && response.data;

    if (!originalRequest.silentErrorNotification) {
      Vue.notify({
        group: 'global',
        title: 'Failed',
        type: 'error',
        text: data.message || 'Something went wrong'
      });
    }

    if (response) return response;

    return Promise.reject(error);
  }
);

export default apiClient;
export {
  apiAuthClient,
  tryAuthWithCallback,
  webClientId
};
