import API from '@api/hostVideoType.api';
import corporateAPI from '@api/corporate.api';

function initialState() {
  return {
    hostVideoTypes: [],
    hostVideoTypeSelected: null,
    hostVideoTypesLoading: false,
    propertyId: null
  };
}

const getters = {
  hostVideoTypes: (state) => state.hostVideoTypes,
  hostVideoTypeSelected: (state) => state.hostVideoTypeSelected,
  hostVideoTypesLoading: (state) => state.hostVideoTypesLoading
};

const mutations = {
  SET_HOST_VIDEO_TYPES(state, data) {
    state.hostVideoTypes = data;
  },
  SET_HVT_SELECTED(state, data) {
    state.hostVideoTypeSelected = data;
  },
  SET_HVT_LOADING(state, loading) {
    state.hostVideoTypesLoading = loading;
  },
  SET_PROPERTY_ID(state, propertyId) {
    state.propertyId = propertyId;
  },
  RESET(state) {
    const initial = initialState();

    Object.keys(initial).forEach((key) => {
      state[key] = initial[key];
    });
  }
};

const actions = {
  async getGeneralHostVideoTypes({ commit, state }) {
    commit('SET_HVT_LOADING', true);

    let response;

    if (state.propertyId) {
      response = await corporateAPI.getPropertyVideoTypes(state.propertyId);
    } else {
      response = await API.getGeneralHostVideoTypes();
    }

    const { records } = response.data;

    commit('SET_HOST_VIDEO_TYPES', records);
    commit('SET_HVT_LOADING', false);
  },
  setHostVideoTypeSelected({ commit }, data) {
    let normalizedData;

    if (data && Object.keys(data).length) {
      const id = data.id || data.ids?.[0];

      normalizedData = { ...data, id };
    }

    commit('SET_HVT_SELECTED', normalizedData);
  },
  setPropertyId({ commit }, propertyId) {
    commit('SET_PROPERTY_ID', propertyId?.length ? propertyId : null);
  },
  resetHostVideoTypes({ commit }) {
    // reset the options first
    commit('hostVideoTypeOption/RESET', null, { root: true });
    commit('RESET');
  }
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  mutations,
  actions
};
