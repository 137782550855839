import moment from 'moment-timezone';
import API from '@api/tours.api';
import userAPI from '@api/user.api';
import APIAttachments from '@api/attachments.api';
import APILiveTour from '@api/liveTour.api';
import { localTimezone } from '@helpers/common.helper';

function initialState() {
  return {
    tour: {
      attachmentIds: [],
      attendees: [],
      date: null,
      fullAddress: '',
      id: null,
      note: '',
      title: '',
      time: moment().format('HH:mm:ss'),
      unitId: null,
      building: null
    },
    attachmentsTab: 'attachments',
    edit: false,
    editableAttachment: {},
    editTourTypeChanged: false,
    isValid: null,
    persisted: false,
    privateTourHasAttendees: null,
    recentAttendees: [],
    saving: false,
    savingAttachment: false,
    type: 'private',
    validationError: null,
    validationErrorType: null,
    validationErrorFields: [],
    validDateTime: null
  };
}

const getters = {
  attachmentsOpen: (state) => state.attachmentsTab === 'attachments',
  attendees: (state) => state.tour.attendees,
  edit: (state) => state.edit,
  editableAttachment: (state) => state.editableAttachment,
  editTourTypeChanged: (state) => state.editTourTypeChanged,
  fullDate: (state) => `${state.tour.date} ${state.tour.time}`,
  isValid: (state) => state.isValid,
  isPublic: (state) => state.type === 'public',
  persisted: (state) => state.persisted,
  privateTourHasAttendees: (state) => state.privateTourHasAttendees,
  recentAttendees: (state) => state.recentAttendees,
  saving: (state) => state.saving,
  savingAttachment: (state) => state.savingAttachment,
  tour: (state) => state.tour,
  type: (state) => state.type,
  validationError: (state) => state.validationError,
  validationErrorType: (state) => state.validationErrorType,
  validationErrorFields: (state) => state.validationErrorFields,
  validDateTime: (state) => state.validDateTime
};

const mutations = {
  SET_TOUR(state, formData) {
    state.tour = formData;
  },
  SET_TOUR_TYPE(state, type) {
    state.type = type;
  },
  SET_EDIT(state, edit) {
    state.edit = edit;
  },
  SET_EDIT_TOUR_TYPE_CHANGED(state) {
    if (state.edit) state.editTourTypeChanged = true;
  },
  SET_ATTACHMENTS_TAB(state, tab) {
    state.attachmentsTab = tab;
  },
  UPDATE_TOUR_FORM(state, data) {
    state.tour = { ...state.tour, ...data };
  },
  ADD_ATTENDEE(state, data) {
    state.tour.attendees.push(data);
  },
  REMOVE_ATTENDEE(state, email) {
    const index = state.tour.attendees.findIndex((attendee) => attendee.email === email);

    state.tour.attendees.splice(index, 1);
  },
  ADD_ATTACHMENT(state, data) {
    state.tour.attachmentIds.push(data);
  },
  REMOVE_ATTACHMENT(state, data) {
    state.tour.attachmentIds.splice(state.tour.attachmentIds.indexOf(data), 1);
  },
  SET_RECENT_ATTENDEES(state, recent) {
    state.recentAttendees = recent;
  },
  SET_PERSISTED(state, persisted) {
    state.persisted = persisted;
  },
  TOGGLE_SAVING(state) {
    state.saving = !state.saving;
  },
  SET_SAVING_ATTACHMENT(state, savingAttachment) {
    state.savingAttachment = savingAttachment;
  },
  SET_EDITABLE_ATTACHMENT(state, attachment) {
    state.editableAttachment = attachment;
  },
  SET_VALID(state, isValid) {
    state.isValid = isValid;
  },
  SET_VALID_DATETIME(state, valid) {
    state.validDateTime = valid;
  },
  SET_PRIVATE_TOUR_HAS_ATTENDEES(state, valid) {
    state.privateTourHasAttendees = valid;
  },
  SET_VALIDATION_ERROR(state, errorMessage) {
    state.validationError = errorMessage;
  },
  SET_VALIDATION_ERROR_TYPE(state, errorType) {
    state.validationErrorType = errorType;
  },
  SET_VALIDATION_ERROR_FIELDS(state, fields) {
    state.validationErrorFields = fields;
  },
  RESET(state) {
    const initial = initialState();

    Object.keys(initial).forEach((key) => {
      state[key] = initial[key];
    });
  }
};

const actions = {
  async buildRequestObj({ state }) {
    const {
      attendees,
      fullAddress,
      attachmentIds,
      date,
      title,
      time,
      unitId,
      note
    } = state.tour;
    const dateTime = moment(`${date} ${time}`).utc().format();
    const isOpenHouse = state.type === 'public';
    const emails = state.type === 'public' ? [] : attendees.map((attendee) => attendee.email);

    return {
      attachmentIds,
      fullAddress,
      emails,
      dateTime,
      isOpenHouse,
      note,
      title,
      unitId
    };
  },
  async createTour({ commit, dispatch }) {
    commit('TOGGLE_SAVING');

    const requestObj = await dispatch('buildRequestObj');

    if (!requestObj.unitId?.length) {
      delete requestObj.unitId;
    }

    await API.createTour(requestObj);

    commit('SET_PERSISTED', true);
    commit('TOGGLE_SAVING');
  },
  async updateTour({ commit, dispatch, state }) {
    commit('TOGGLE_SAVING');

    const requestObj = await dispatch('buildRequestObj');
    const tourId = state.tour.id;

    await API.updateTour(tourId, requestObj);

    commit('SET_PERSISTED', true);
    commit('TOGGLE_SAVING');
  },
  async uploadAttachment({ commit }, params) {
    const { data: { record: attachment } } = await APIAttachments.createAttachment(params);

    commit('ADD_ATTACHMENT', attachment.id);

    return attachment;
  },
  async getDefaultAttachments() {
    const { data: { records: attachments } } = await APIAttachments.getAttachments();

    return attachments;
  },
  // eslint-disable-next-line no-empty-pattern
  async getAttachments({ }, tourId) {
    const {
      data: { records: attachments }
    } = await APILiveTour.getAttachments(tourId);

    return attachments;
  },
  async getTour({ commit }, tourId) {
    const { data: { record } } = await APILiveTour.getTour(tourId);

    const {
      id, isOpenHouse, attachmentIds: attachmentIdsOriginal,
      title, fullAddress, accessList, startDate, note,
      unitId, building
    } = record;

    const attendees = accessList
      .filter((attendee) => attendee.status !== 'host')
      .map((attendee) => {
        const { email, name } = attendee;

        return { email, name };
      });
    const attachmentIds = attachmentIdsOriginal || [];
    const timezone = localTimezone();
    const dateTime = moment(startDate).tz(timezone);
    const date = dateTime.format('Y-MM-DD');
    const time = dateTime.format('HH:mm:ss');
    const type = isOpenHouse ? 'public' : 'private';

    commit('SET_TOUR', {
      id, attendees, attachmentIds, date, time, title, fullAddress, unitId, building, note
    });
    commit('SET_TOUR_TYPE', type);
  },
  async deleteTour({ dispatch }, tourID) {
    await API.deleteTour(tourID);

    dispatch(
      'tours/removeTourFromTheList',
      tourID,
      { root: true }
    );
  },
  async getRecentAttendees({ commit }) {
    const { data: { records } } = await userAPI.getRecentAttendees();

    commit('SET_RECENT_ATTENDEES', records);
  },
  setEditableAttachment({ commit }, params) {
    commit('SET_EDITABLE_ATTACHMENT', params);
  },
  async updateAttachment({ commit, state }, params) {
    commit('SET_SAVING_ATTACHMENT', true);

    const { id, ...data } = params;
    await APIAttachments.updateAttachment(id, data);

    commit('SET_EDITABLE_ATTACHMENT', { ...state.editableAttachment, ...data });
    commit('SET_SAVING_ATTACHMENT', false);
  },
  setEdit({ commit }) {
    commit('SET_EDIT', true);
  },
  setEditTourTypeChanged({ commit }) {
    commit('SET_EDIT_TOUR_TYPE_CHANGED');
  },
  setValid({ commit }, params) {
    commit('SET_VALID', params.valid);
    commit('SET_VALIDATION_ERROR', params.errorMessage || null);
    commit('SET_VALIDATION_ERROR_TYPE', params.errorType || null);
    commit('SET_VALIDATION_ERROR_FIELDS', params.errorFields || []);
  },
  setTourType({ commit }, type) {
    commit('SET_TOUR_TYPE', type);
  },
  setAttachmentsTab({ commit }, tab) {
    commit('SET_ATTACHMENTS_TAB', tab);
  },
  updateTourForm({ commit }, data) {
    commit('UPDATE_TOUR_FORM', data);
  },
  addAttendee({ commit }, data) {
    commit('ADD_ATTENDEE', data);
  },
  removeAttendee({ commit }, data) {
    commit('REMOVE_ATTENDEE', data);
  },
  addAttachment({ commit }, attachmentId) {
    commit('ADD_ATTACHMENT', attachmentId);
  },
  removeAttachment({ commit }, attachmentId) {
    // Remove only if not added to defaults
    // APIAttachments.deleteAttachment(attachmentId);

    commit('REMOVE_ATTACHMENT', attachmentId);
  },
  validateDateTime({ commit, state }) {
    const { date, time } = state.tour;
    let valid = null;

    if (date && time) {
      valid = moment(`${date} ${time}`).isAfter(moment());
    }

    commit('SET_VALID_DATETIME', valid);
  },
  validatePrivateTourAttendees({ commit, state }) {
    let valid = true;

    if (!(state.type === 'public')) {
      valid = state.tour.attendees.length > 0;
    }

    commit('SET_PRIVATE_TOUR_HAS_ATTENDEES', valid);
  },
  async validateForm({ dispatch, state }) {
    await dispatch('validateDateTime');
    await dispatch('validatePrivateTourAttendees');

    if (state.validDateTime && state.privateTourHasAttendees) {
      dispatch('setValid', { valid: true });
    }

    if (!state.validDateTime) {
      const errorFields = [];
      let errorMessage = "You can't create a Tour in the past!";
      let errorType = 'dateTimeInThePast';

      if (!state.tour.date || !state.tour.time) {
        if (!state.tour.date) errorFields.push('date');
        if (!state.tour.time) errorFields.push('time');

        errorMessage = `Missing required fields: ${errorFields.join(', ')}`;
        errorType = 'missingDateTimeFields';
      }
      dispatch('setValid', {
        valid: false,
        errorMessage,
        errorType,
        errorFields

      });
    } else if (!state.privateTourHasAttendees) {
      dispatch('setValid', {
        valid: false,
        errorMessage: 'Please Invite Attendees to the Private Tour!',
        errorType: 'noAttendees'
      });
    }
  },
  removeFromValidationErrorFields({ commit, state }, field) {
    const indexOfField = state.validationErrorFields.indexOf(field);

    if (indexOfField >= 0) {
      const newFields = [...state.validationErrorFields];
      newFields.splice(indexOfField, 1);

      commit('SET_VALIDATION_ERROR_FIELDS', newFields);
    }
  },
  clearValidationError({ dispatch, state }, type) {
    if (state.validationErrorType === type) {
      dispatch('setValid', {
        valid: true,
        errorMessage: null,
        errorType: null,
        errorFields: null
      });
    }
  },
  resetTourForm({ commit }) {
    commit('RESET');
  }
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  mutations,
  actions
};
