import API from '@api/tours.api';
import { localTimezone } from '@helpers/common.helper';

function initialState() {
  return {
    records: [],
    selectedDate: '',
    ownership: 'my',
    loading: false
  };
}

const getters = {
  ownership: (state) => state.ownership,
  records: (state) => state.records,
  loading: (state) => state.loading
};

const mutations = {
  SET_SELECTED_DATE(state, selectedDate) {
    state.selectedDate = selectedDate;
  },
  SET_SECTION(state, section) {
    state.section = section;
  },
  SET_RECORDS(state, data) {
    state.records = data;
  },
  SET_LOADING(state, loading) {
    state.loading = loading;
  },
  REMOVE_TOUR_FROM_THE_LIST(state, tourId) {
    const index = state.records.findIndex((r) => r.id === tourId);

    state.records.splice(index, 1);
  },
  SET_TOURS_OWNERSHIP(state, owner) {
    state.ownership = owner;
  },
  RESET(state) {
    const initial = initialState();

    Object.keys(initial).forEach((key) => {
      state[key] = initial[key];
    });
  }
};

const actions = {
  async getToursByDay({ state, commit }) {
    commit('SET_LOADING', true);

    const { data } = await API.getTours(
      {
        date: state.selectedDate,
        ownership: state.ownership,
        timezone: localTimezone()
      }
    );

    if (data.status !== 'failed') {
      commit('SET_RECORDS', data.records);
    }

    commit('SET_LOADING', false);
  },
  setSelectedDate({ commit }, date) {
    commit('SET_SELECTED_DATE', date);
  },
  removeTourFromTheList({ commit }, tourId) {
    commit('REMOVE_TOUR_FROM_THE_LIST', tourId);
  },
  setToursOwnership({ commit }, owner) {
    commit('SET_TOURS_OWNERSHIP', owner);
  },
  resetTours({ commit }) {
    commit('RESET');
  }
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  mutations,
  actions
};
