import API from '@api/corporate.api';
import pagination from '../pagination';

function initialState() {
  return {
    ...pagination.state,
    properties: [],
    loading: false,
    filter: '',
    perPage: 24
  };
}

const getters = {
  ...pagination.getters,
  filter: (state) => state.filter,
  properties: (state) => state.properties,
  propertiesLoading: (state) => state.propertiesLoading
};

const mutations = {
  ...pagination.mutations,
  SET_PROPERTIES_LOADING(state, loading) {
    state.loading = loading;
  },
  SET_PROPERTIES(state, data) {
    state.properties = data;
  },
  ADD_PROPERTIES(state, data) {
    state.properties.push(...data);
  },
  SET_FILTER(state, filter) {
    state.filter = filter;
  },
  RESET(state) {
    const initial = initialState();

    Object.keys(initial).forEach((key) => {
      state[key] = initial[key];
    });
  },
  RESET_PROPERTIES(state) {
    state.properties = [];
    state.page = 1;
    state.filter = '';
  }
};

const actions = {
  async apiGetProperties({ state }, params) {
    const { data } = await API.getProperties({
      perPage: state.perPage, ...params
    });

    return data;
  },
  async getProperties({ commit, state, dispatch }) {
    commit('SET_PROPERTIES_LOADING', true);

    const data = await dispatch('apiGetProperties', {
      page: state.page,
      q: state.filter
    });

    commit('SET_PROPERTIES', data.records);
    commit('SET_SHOW_MORE', state.page < data.pagination.pages);
    commit('SET_PROPERTIES_LOADING', false);
  },
  async applyFilter({ state, commit, dispatch }, filter) {
    commit('SET_PROPERTIES_LOADING', true);

    const data = await dispatch('apiGetProperties', {
      page: 1,
      q: filter
    });

    commit('SET_PROPERTIES', data.records);
    commit('SET_PAGE', 1);
    commit('SET_FILTER', filter);
    commit('SET_SHOW_MORE', state.page < data.pagination.pages);
    commit('SET_PROPERTIES_LOADING', false);
  },
  async fetchMore({ state, commit, dispatch }) {
    commit('SET_FETCHING_MORE', true);

    const nextPage = state.page + 1;

    const data = await dispatch('apiGetProperties', {
      page: nextPage,
      q: state.filter
    });

    commit('SET_PAGE', nextPage);
    commit('SET_SHOW_MORE', state.page < data.pagination.pages);
    commit('ADD_PROPERTIES', data.records);
    commit('SET_FETCHING_MORE', false);
  },
  resetProperties({ commit }) {
    commit('RESET_PROPERTIES');
  }
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  mutations,
  actions
};
