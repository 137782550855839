import API from '@api/integrations.api';
import pagination from '../pagination';

function initialState() {
  return {
    ...pagination.state,
    filter: '',
    records: [],
    type: null
  };
}

const getters = {
  ...pagination.getters,
  records: (state) => state.records,
  filter: (state) => state.filter,
  type: (state) => state.type
};

const mutations = {
  ...pagination.mutations,
  SET_PROSPECTS(state, data) {
    state.records = data;
  },
  SET_PROSPECTS_TYPE(state, type) {
    state.type = type;
  },
  SET_FILTER(state, filter) {
    state.filter = filter;
  },
  ADD_PROSPECTS(state, data) {
    state.records.push(...data);
  },
  RESET(state) {
    const initial = initialState();

    Object.keys(initial).forEach((key) => {
      state[key] = initial[key];
    });
  }
};

const actions = {
  async apiGetProspects({ state, rootGetters }, params) {
    const managedPropertyId = rootGetters['user/isCorpOrManager']
      ? rootGetters['property/property'].id
      : rootGetters['user/currentUser'].activeProperty.id;

    const response = await API.getProspects(managedPropertyId, {
      perPage: state.perPage,
      ...params
    });

    return response;
  },
  async getProspectsList({ state, commit, dispatch }) {
    commit('SET_LOADING', true);

    const response = await dispatch('apiGetProspects', {
      page: state.page,
      search: state.filter
    });

    if (response.status === 200) {
      const { data } = response;

      commit('SET_SHOW_MORE', state.page < data.pagination.pages);
      commit('SET_PROSPECTS', data.records);
    }

    commit('SET_LOADING', false);
  },
  async applyFilter({ state, commit, dispatch }, filter) {
    commit('SET_LOADING', true);

    const response = await dispatch('apiGetProspects', {
      page: 1,
      search: filter
    });

    if (response.status === 200) {
      const { data } = response;

      commit('SET_PAGE', 1);
      commit('SET_FILTER', filter);
      commit('SET_SHOW_MORE', state.page < data.pagination.pages);
      commit('SET_PROSPECTS', data.records);
    }

    commit('SET_LOADING', false);
  },
  async fetchMore({ state, commit, dispatch }) {
    commit('SET_FETCHING_MORE', true);

    const nextPage = state.page + 1;

    const response = await dispatch('apiGetProspects', {
      page: nextPage,
      search: state.filter
    });

    if (response.status === 200) {
      const { data } = response;

      commit('SET_PAGE', nextPage);
      commit('SET_SHOW_MORE', state.page < data.pagination.pages);
      commit('ADD_PROSPECTS', data.records);
    }

    commit('SET_FETCHING_MORE', false);
  },
  setProspectsType({ commit }, type) {
    commit('SET_PROSPECTS_TYPE', type);
  },
  resetPagination({ commit }) {
    commit('SET_PAGE', 1);
  },
  resetProspects({ commit }) {
    commit('RESET');
  }
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  mutations,
  actions
};
