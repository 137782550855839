/* eslint no-shadow: ["error", { "allow": ["state"] }] */

import API from '../../api/liveTour.api';

function initialState() {
  return {
    loading: false,
    token: null
  };
}

const getters = {
  loading: (state) => state.loading,
  token: (state) => state.token
};

const mutations = {
  SET_SINGLE_LOADING(state, data) {
    state.loading = data;
  },
  SET_TOKEN(state, data) {
    state.token = data;
  }
};

const actions = {
  async getToken({ commit }, params) {
    commit('SET_SINGLE_LOADING', true);

    const { data } = await API.getToken(params);

    commit('SET_TOKEN', data.record.token);
    commit('SET_SINGLE_LOADING', false);
  }
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  mutations,
  actions
};
