<template>
  <div id="app">
    <SVGSprite />
    <notifications
      group="global"
      position="top center"
    />
    <router-view />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import EventBus from '@/helpers/eventBus';
import SVGSprite from '@/components/shared/SVGSprite/SVGSprite.vue';
import intercomInitialize from '@/resources/externalLibs/intercom';
import churnZeroInitialize from '@/resources/externalLibs/churnZero';
import microsoftClarityInitialize from '@/resources/externalLibs/microsoftClarity';

export default {
  name: 'App',
  components: {
    SVGSprite
  },
  data() {
    return {
      isMediaAccess: false,
      hideNavigation: true,
      loaded: false
    };
  },
  computed: {
    ...mapGetters('user', ['currentUser']),
    bodyElement() {
      return document.getElementsByTagName('body')[0];
    },
    isProduction() {
      return window.location.host === 'realync.com';
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler(to) {
        if (this.loaded) {
          this.hideNavigation = !!to.meta.publicPage;
        } else {
          this.loaded = true;
        }

        const routeTitle = to.meta && to.meta.title;

        if (routeTitle) {
          document.title = routeTitle;
        }
      }
    },
    currentUser(newVal, oldVal) {
      if (newVal.id?.length && (oldVal?.id !== newVal?.id)) {
        this.initMicrosoftClarityWithUserId();
      }
    }
  },
  created() {
    // Until we implement auth via Vue
    const user = localStorage.getItem('user');

    if (user) this.setCurrentUser(JSON.parse(user));
    const currentUser = JSON.parse(user);

    EventBus.$on('media-access', this.toggleMediaAccess);
    this.bodyElement.addEventListener('keydown', this.toggleTabNavigation);

    churnZeroInitialize(currentUser);
    intercomInitialize(currentUser);
  },
  mounted() {
    if (this.isProduction) {
      microsoftClarityInitialize();
    }
  },
  destroyed() {
    EventBus.$off('media-access', this.toggleMediaAccess);
  },
  methods: {
    ...mapActions('user', ['setCurrentUser']),
    initMicrosoftClarityWithUserId() {
      const existingUser = localStorage.getItem('user');

      if (this.isProduction && window.clarity !== undefined && (existingUser?.id?.length || this.currentUser?.id?.length)) {
        const userId = existingUser?.id?.length ? existingUser.id : this.currentUser.id;
        window.clarity('set', 'userId', userId);
      }
    },
    toggleMediaAccess(options) {
      this.isMediaAccess = options.opened;

      // Disable scrolling when the Media Access popup is shown
      this.bodyElement.style.overflow = options.opened ? 'hidden' : '';
    },
    toggleTabNavigation(event) {
      // Disable tab navigation when the Media Access popup is shown
      if (this.isMediaAccess) {
        if (event.keyCode === 9) {
          event.preventDefault();
        }
      } else {
        this.bodyElement.removeEventListener('keydown', this.toggleTabNavigation);
      }
    }
  }
};
</script>
