import API from '../../api/brandingHeader.api';

function initialState() {
  return {
    brandingData: {},
    brandingLoading: true,
    brandingPresent: false
  };
}

const getters = {
  brandingData: (state) => state.brandingData,
  brandingLoading: (state) => state.brandingLoading,
  brandingPresent: (state) => !!state.brandingData.propertyLogoUrl?.length
};

const mutations = {
  SET_BRANDING_DATA(state, data) {
    state.brandingData = data;
  },
  SET_BRANDING_LOADING(state, data) {
    state.brandingLoading = data;
  }
};

const actions = {
  async getBrandingHeader({ commit }, params) {
    commit('SET_BRANDING_LOADING', true);

    const { status, data } = await API.getBrandingHeader(params);

    if (status === 200) {
      commit('SET_BRANDING_DATA', data);
    }

    commit('SET_BRANDING_LOADING', false);
  },
  // eslint-disable-next-line no-empty-pattern
  async trackBrandingBtnClick({}, params) {
    await API.trackBrandingBtnClick(params.id, params);
  }
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  mutations,
  actions
};
