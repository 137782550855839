import API from '@api/recipients.api';
import pagination from '../pagination';

function initialState() {
  return {
    ...pagination.state,
    filters: {},
    users: [],
    loading: false
  };
}

const getters = {
  ...pagination.getters,
  filters: (state) => state.filters,
  users: (state) => state.users,
  loading: (state) => state.loading
};

const mutations = {
  ...pagination.mutations,
  SET_USERS(state, payload) {
    state.users = payload;
  },
  ADD_USERS(state, payload) {
    state.users.push(...payload);
  },
  SET_FILTERS(state, payload) {
    state.filters = payload;
  },
  SET_LOADING(state, payload) {
    state.loading = payload;
  },
  RESET(state) {
    const initial = initialState();

    Object.keys(initial).forEach((key) => {
      state[key] = initial[key];
    });
  }
};

const actions = {
  async apiSearchRecipients({ state }, params) {
    const response = await API.searchRecipients({ perPage: state.perPage, ...params });

    return response;
  },
  async applyFilter({ dispatch, state, commit }, filters) {
    commit('SET_LOADING', true);

    const response = await dispatch('apiSearchRecipients', {
      page: 1,
      ...filters
    });

    if (response.status === 200) {
      const { data } = response;

      commit('SET_FILTERS', filters);
      commit('SET_PAGE', 1);
      commit('SET_SHOW_MORE', state.page < data.pagination.pages);
      commit('SET_USERS', data.records);
    }

    commit('SET_LOADING', false);

    return response;
  },
  async fetchMore({ state, commit, dispatch }) {
    commit('SET_FETCHING_MORE', true);

    const nextPage = state.page + 1;

    const response = await dispatch('apiSearchRecipients', {
      ...state.filters,
      page: nextPage
    });

    if (response.status === 200) {
      const { data } = response;

      commit('SET_PAGE', nextPage);
      commit('SET_SHOW_MORE', state.page < data.pagination.pages);
      commit('ADD_USERS', data.records);
    }

    commit('SET_FETCHING_MORE', false);

    return response;
  },
  resetAll({ commit }) {
    commit('RESET');
  }
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  mutations,
  actions
};
