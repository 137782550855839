import APIAttachments from '@api/attachments.api';
import API from '@api/playlists.api';

function initialState() {
  return {
    attachments: [],
    description: '',
    lastPosition: 0,
    thumbnailUrl: '',
    title: '',
    selectedVideos: [],
    saving: false,
    isEdit: null,
    currentPlaylist: null,
    showViewDataModal: false
  };
}

const getters = {
  attachments: (state) => state.attachments,
  description: (state) => state.description,
  saving: (state) => state.saving,
  isEdit: (state) => state.isEdit,
  selectedVideos: (state) => state.selectedVideos,
  thumbnailUrl: (state) => state.thumbnailUrl,
  title: (state) => state.title,
  currentPlaylist: (state) => state.currentPlaylist,
  showViewDataModal: (state) => state.showViewDataModal
};

const mutations = {
  ADD_VIDEO(state, video) {
    state.lastPosition += 1;
    state.selectedVideos.push({ ...video, position: state.lastPosition });
  },
  REMOVE_VIDEO(state, video) {
    const indexToRemove = state.selectedVideos.findIndex((v) => v.id === video.id);

    if (indexToRemove > -1) {
      state.selectedVideos.splice(indexToRemove, 1);
    }
  },
  UPDATE_POSITIONS(state) {
    state.lastPosition = 0;
    state.selectedVideos = state.selectedVideos.map((record) => {
      state.lastPosition += 1;

      return { ...record, position: state.lastPosition };
    });
  },
  SET_SELECTED_VIDEOS(state, payload) {
    state.selectedVideos = payload;
  },
  SET_THUMBNAIL_URL(state, payload) {
    state.thumbnailUrl = payload;
  },
  SET_TITLE(state, payload) {
    state.title = payload;
  },
  SET_DESCRIPTION(state, payload) {
    state.description = payload;
  },
  ADD_ATTACHMENT(state, payload) {
    state.attachments.push(payload);
  },
  SET_UPLOADED_ATTACHMENT(state, payload) {
    state.attachments.unshift(payload);
  },
  UPDATE_ATTACHMENT(state, payload) {
    const index = state.attachments.findIndex((a) => a.id === payload.id);
    const attachment = state.attachments[index];

    state.attachments.splice(index, 1, { ...attachment, ...payload });
  },
  REMOVE_ATTACHMENT(state, attachmentIndex) {
    state.attachments.splice(attachmentIndex, 1);
  },
  SET_SAVING_PLAYLIST(state, payload) {
    state.saving = payload;
  },
  SET_IS_EDIT(state, payload) {
    state.isEdit = payload;
  },
  SET_CURRENT_PLAYLIST(state, data) {
    state.currentPlaylist = data;
  },
  SET_SHOW_VIEW_DATA_MODAL(state, show) {
    state.showViewDataModal = show;
  },
  RESET(state) {
    const initial = initialState();

    Object.keys(initial).forEach((key) => {
      state[key] = initial[key];
    });
  }
};

const actions = {
  addVideo({ commit }, video) {
    commit('ADD_VIDEO', video);
  },
  removeVideo({ commit }, video) {
    commit('REMOVE_VIDEO', video);
    commit('UPDATE_POSITIONS');
  },
  setSelectedVideos({ commit }, videos) {
    commit('SET_SELECTED_VIDEOS', videos);
    commit('UPDATE_POSITIONS');
  },
  addAttachment({ commit }, attachment) {
    commit('ADD_ATTACHMENT', attachment);
  },
  async uploadAttachment({ commit }, params) {
    const { data: { record } } = await APIAttachments.createAttachment(params);

    commit('SET_UPLOADED_ATTACHMENT', record);

    return record;
  },
  async getDefaultAttachments({ commit }) {
    const { data: { records } } = await APIAttachments.getAttachments();

    if (records.length) {
      records.forEach((attachment) => commit('ADD_ATTACHMENT', attachment));
    }
  },
  updateAttachmentInList({ commit }, payload) {
    commit('UPDATE_ATTACHMENT', payload);
  },
  async buildRequestObj({ state }) {
    const {
      attachments, description, thumbnailUrl, title, selectedVideos
    } = state;

    return {
      attachmentIds: attachments.map((a) => a.id),
      description,
      title,
      videoIds: selectedVideos.map((v) => ({ id: v.id, position: v.position })),
      thumbnailUrl
    };
  },
  async createPlaylist({ commit, dispatch }) {
    commit('SET_SAVING_PLAYLIST', true);

    const requestObj = await dispatch('buildRequestObj');
    const response = await API.createPlaylist(requestObj);

    if (response.status === 200) {
      dispatch(
        'dashboardVideosTabPlaylists/updatePlaylistList',
        null,
        { root: true }
      );
    }

    commit('SET_SAVING_PLAYLIST', false);

    return response;
  },
  async updatePlaylist({ commit, state, dispatch }, notify) {
    commit('SET_SAVING_PLAYLIST', true);

    const requestObj = await dispatch('buildRequestObj');

    const response = await API.updatePlaylist(
      state.currentPlaylist.id,
      {
        ...requestObj,
        notify
      }
    );

    if (response.status === 200) {
      dispatch(
        'dashboardVideosTabPlaylists/updatePlaylistList',
        null,
        { root: true }
      );
    }

    commit('SET_SAVING_PLAYLIST', false);

    return response;
  },
  removeAttachment({ commit }, attachmentId) {
    commit('REMOVE_ATTACHMENT', attachmentId);
  },
  async setPlaylistPreview({ commit, dispatch }, playlistId) {
    const response = await API.getPlaylistPreview(playlistId);

    if (response.status === 200) {
      const {
        title,
        description,
        attachments,
        videos,
        thumbnailUrl
      } = response.data.record;

      commit('SET_CURRENT_PLAYLIST', response.data.record);
      commit('SET_TITLE', title);
      commit('SET_DESCRIPTION', description);

      if (thumbnailUrl?.length) {
        commit('SET_THUMBNAIL_URL', thumbnailUrl);
      }

      if (attachments.length) {
        attachments.forEach((attachment) => commit('ADD_ATTACHMENT', attachment));
      }

      if (videos.length) {
        videos.forEach((video) => commit('ADD_VIDEO', video));

        dispatch(
          'sharedPlaylist/setSharedPlaylist',
          {
            title,
            description,
            videos,
            currentVideo: videos[0],
            autoplay: false
          },
          { root: true }
        );
      }
    }
  },
  setIsEdit({ commit }, payload) {
    commit('SET_IS_EDIT', payload);
  },
  setThumbnailUrl({ commit }, payload) {
    commit('SET_THUMBNAIL_URL', payload);
  },
  async setCurrentPlaylist({ commit }, data) {
    commit('SET_CURRENT_PLAYLIST', data);
  },
  setShowViewDataModal({ commit }, show) {
    commit('SET_SHOW_VIEW_DATA_MODAL', show);
  },
  reset({ commit }) {
    commit('RESET');
  }
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  mutations,
  actions
};
