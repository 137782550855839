import API from '@api/editVideoAttributes.api';
import pagination from '../pagination';

function initialState() {
  return {
    ...pagination.state,
    currentEditingVideo: {},
    accessibilityLoading: false
  };
}

const getters = {
  ...pagination.getters,
  currentEditingVideo: (state) => state.currentEditingVideo,
  accessibilityLoading: (state) => state.accessibilityLoading
};

const mutations = {
  ...pagination.mutations,
  SET_VIDEO(state, payload) {
    state.currentEditingVideo = payload;
  },
  SET_ACCESSIBILITY_LOADING(state, payload) {
    state.accessibilityLoading = payload;
  },
  RESET(state) {
    const initial = initialState();

    Object.keys(initial).forEach((key) => {
      state[key] = initial[key];
    });
  }
};

const actions = {
  async updateVideoAttributes({ state }, params) {
    const response = await API.updateVideoAttributes(state.currentEditingVideo.id, params);

    return response;
  },
  async getVideoAccessibility({ state, commit }, videoId) {
    commit('SET_ACCESSIBILITY_LOADING', true);

    const response = await API.getVideoAccessibility(videoId);

    if (response.status === 200) {
      const accessibility = response.data.record;

      if (Object.keys(accessibility).length > 0) {
        commit('SET_VIDEO', {
          ...state.currentEditingVideo,
          accessibility
        });
      }
    }

    commit('SET_ACCESSIBILITY_LOADING', false);
  },
  setCurrentEditingVideo({ commit }, video) {
    commit('SET_VIDEO', video);
  },
  resetModal({ commit }) {
    commit('RESET');
  }
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  mutations,
  actions
};
