import API from '@api/share.api';
import EventBus from '@helpers/eventBus';
import _ from 'underscore';

function initialState() {
  return {
    playlist: {},
    loading: false,
    invalidPlaylist: false,
    currentActiveVideo: null,
    watchedVideos: [],
    prevVideoPresent: false,
    nextVideoPresent: false
  };
}

const getters = {
  playlist: (state) => state.playlist,
  loading: (state) => state.loading,
  invalidPlaylist: (state) => state.invalidPlaylist,
  currentActiveVideo: (state) => state.currentActiveVideo,
  watchedVideos: (state) => state.watchedVideos,
  prevVideoPresent: (state) => state.prevVideoPresent,
  nextVideoPresent: (state) => state.nextVideoPresent
};

const mutations = {
  SET_PLAYLIST(state, payload) {
    state.playlist = payload;
  },
  SET_LOADING(state, payload) {
    state.loading = payload;
  },
  SET_INVALID_PLAYLIST(state, payload) {
    state.invalidPlaylist = payload;
  },
  SET_CURRENT_ACTIVE_VIDEO(state, payload) {
    state.currentActiveVideo = payload;
  },
  ADD_WATCHED_VIDEO(state, payload) {
    state.watchedVideos = [...new Set([...state.watchedVideos, payload])];
  },
  SET_PREV_VIDEO_PRESENT(state, payload) {
    state.prevVideoPresent = payload;
  },
  SET_NEXT_VIDEO_PRESENT(state, payload) {
    state.nextVideoPresent = payload;
  },
  RESET(state) {
    const initial = initialState();

    Object.keys(initial).forEach((key) => {
      state[key] = initial[key];
    });
  }
};

const actions = {
  async getSharedPlaylist({ commit, dispatch }, params) {
    commit('SET_LOADING', true);

    const { playlistId, recipientId } = params;

    const response = await API.getSharedPlaylist(playlistId, recipientId);

    if (response.status === 200) {
      const { playlist, playlistTracking } = response.data;

      if (_.isEmpty(playlist) || !playlist.videos.length) {
        commit('SET_INVALID_PLAYLIST', true);
      } else {
        commit('SET_PLAYLIST', playlist);

        dispatch(
          'brandingHeader/getBrandingHeader',
          { type: 'playlist', id: playlistId, linkId: recipientId },
          { root: true }
        );

        dispatch('setActiveVideo', { video: playlist.videos[0] });

        dispatch(
          'sharedVideo/setRecipient',
          playlistTracking,
          { root: true }
        );
      }
    } else {
      commit('SET_INVALID_PLAYLIST', true);
    }

    commit('SET_LOADING', false);
  },
  async setSharedPlaylist({ state, commit, dispatch }, payload) {
    commit('SET_PLAYLIST', { ...state.playlist, ...payload });

    if (payload.currentVideo) {
      await dispatch('setActiveVideo', {
        video: { forceLoadSegments: true, ...payload.currentVideo },
        autoplay: (typeof payload.autoplay !== 'undefined') ? payload.autoplay : true
      });
    }
  },
  async setActiveVideo({ commit, dispatch }, params) {
    commit('SET_CURRENT_ACTIVE_VIDEO', params.video);

    dispatch('setPlaylistNavArrows', params.video);
    await dispatch('sharedVideo/setActiveVideo', params.video, { root: true });

    if (params.autoplay) EventBus.$emit('player-video-play');
  },
  async markAsWatched({ state, commit, dispatch }, videoId) {
    if (!state.watchedVideos.includes(videoId)) {
      commit('ADD_WATCHED_VIDEO', videoId);

      await dispatch('sharedVideo/logRecipientWatchedPlaylistVideo', state.playlist.id, { root: true });
    }
  },
  setPlaylistNavArrows({ commit, state }, newVideo) {
    const { videos } = state.playlist;
    const newVideoIndex = videos.findIndex((video) => video.id === newVideo.id);

    commit('SET_PREV_VIDEO_PRESENT', !!videos[newVideoIndex - 1]);
    commit('SET_NEXT_VIDEO_PRESENT', !!videos[newVideoIndex + 1]);
  },
  async playVideo({ state, dispatch }, navDirection) {
    const { videos, currentVideo } = state.playlist;
    const currentVideoIndex = videos.findIndex((video) => video.id === state.currentActiveVideo.id);

    let videoToPlay;

    if (navDirection === 'prev') videoToPlay = videos[currentVideoIndex - 1];
    if (navDirection === 'next') videoToPlay = videos[currentVideoIndex + 1];

    if (videoToPlay) {
      await dispatch('setActiveVideo', {
        video: {
          ...videoToPlay,
          ...(currentVideo && Object.keys(currentVideo).length && {
            forceLoadSegments: true
          })
        },
        autoplay: true
      });
    }
  },
  resetSharedPlaylist({ commit }) {
    commit('RESET');
  }
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  mutations,
  actions
};
