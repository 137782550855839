import API from '@api/dashboardVideosDeleted.api';
import pagination from '../pagination';

const tabIndexes = {
  videos: 0,
  playlists: 1,
  drafts: 2
};

function initialState() {
  return {
    ...pagination.state,
    totals: {},
    modalActive: false,
    isPropertyTab: false,
    activeTab: 0
  };
}

const getters = {
  ...pagination.getters,
  totals: (state) => state.totals,
  isModalActive: (state) => state.modalActive,
  isPropertyTab: (state) => state.isPropertyTab,
  activeTab: (state) => state.activeTab,
  totalsCount: (state) => (Object.keys(state.totals).length
    ? Object.values(state.totals).reduce((prev, next) => prev + next) : 0)
};

const mutations = {
  ...pagination.mutations,
  SET_TOTALS(state, payload) {
    state.totals = payload;
  },
  SET_MODAL_ACTIVE(state, payload) {
    state.modalActive = payload;
  },
  SET_IS_PROPERTY_TAB(state, payload) {
    state.isPropertyTab = payload;
  },
  SET_ACTIVE_TAB(state, payload) {
    state.activeTab = tabIndexes[payload];
  },
  RESET(state) {
    const initial = initialState();

    Object.keys(initial).forEach((key) => {
      state[key] = initial[key];
    });
  }
};

const actions = {
  async getDeletedContentsCount({ commit, state, rootGetters }) {
    if (state.isPropertyTab) {
      const { deletedVideosCount } = rootGetters['property/property'];

      commit('SET_TOTALS', { videos: deletedVideosCount, playlists: 0 });
    } else {
      const response = await API.getDeletedContentsCount();

      if (response.status === 200) {
        const showDrafts = rootGetters['user/isUserActiveRoleAgent'] && rootGetters['user/currentUser'].activeProperty?.crossDeviceVideoEditing;

        const formattedObj = {
          videos: response.data.videos,
          playlists: response.data.playlists,
          ...(showDrafts && { drafts: response.data.drafts || 0 })
        };

        commit('SET_TOTALS', formattedObj);
      }
    }
  },
  toggleModalActive({ commit }, isActive) {
    commit('SET_MODAL_ACTIVE', isActive);
  },
  setActiveTab({ commit }, tab) {
    commit('SET_ACTIVE_TAB', tab);
  },
  setModalActive({ commit }, modalType) {
    commit('SET_MODAL_ACTIVE', true);
    commit('SET_ACTIVE_TAB', modalType);
  },
  setIsPropertyTab({ commit }, payload) {
    commit('SET_IS_PROPERTY_TAB', payload);
  },
  setDeletedContentCounts({ commit }, counts) {
    commit('SET_TOTALS', counts);
  },
  resetDeletedModalState({ commit }) {
    commit('RESET');
  }
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  mutations,
  actions
};
