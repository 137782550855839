<template>
  <t-button
    variant="show-more"
    :disabled="fetchingMore"
    @click="$emit('click')"
  >
    <TheSpinner v-if="fetchingMore" />
    <span v-else class="ml-2 text-sm font-bold">Show More</span>
  </t-button>
</template>

<script>
export default {
  name: 'TheShowMoreButton',
  props: {
    fetchingMore: {
      type: Boolean,
      default: false
    }
  }
};
</script>
