import moment from 'moment';
import API from '@api/pastTour.api';
import APILiveTour from '@api/liveTour.api';

function initialState() {
  return {
    editableNote: false,
    messagesTabLoading: false,
    pastTourLoading: false,
    pastTour: {
      messages: [],
      record: {}
    }
  };
}

const getters = {
  editableNote: (state) => state.editableNote,
  messagesTabLoading: (state) => state.messagesTabLoading,
  messages: (state) => state.pastTour.messages,
  note: (state) => state.pastTour.record.note,
  pastTour: (state) => state.pastTour,
  pastTourLoading: (state) => state.pastTourLoading
};

const mutations = {
  SET_EDITABLE_NOTE(state, data) {
    state.editableNote = data;
  },
  SET_MESSAGES_TAB_LOADING(state, data) {
    state.messagesTabLoading = data;
  },
  SET_MESSAGES(state, data) {
    state.pastTour.messages = data;
  },
  SET_PAST_TOUR(state, data) {
    state.pastTour.record = data;
  },
  SET_PAST_TOUR_LOADING(state, data) {
    state.pastTourLoading = data;
  },
  SET_PAST_TOUR_NOTE(state, note) {
    state.pastTour.record.note = note;
  },
  RESET(state) {
    const initial = initialState();

    Object.keys(initial).forEach((key) => {
      state[key] = initial[key];
    });
  }
};

const actions = {
  async getPastTour({ commit }, params) {
    commit('SET_PAST_TOUR_LOADING', true);

    const { data } = await API.getPastTour(params.tourId, {
      guestId: params.guestId
    });

    commit('SET_PAST_TOUR', data.record);
    commit('SET_PAST_TOUR_LOADING', false);
  },
  async getMessages({ commit, state }, currentUserId) {
    commit('SET_MESSAGES_TAB_LOADING', true);

    const guestId = currentUserId || state.pastTour.record.currentGuest.id;
    const isAgent = state.pastTour.record.realtor === guestId;

    const { data } = await APILiveTour.getMessages(state.pastTour.record.id, { guestId, agent: isAgent });
    const serializedResponse = [];

    data.records.forEach((message) => {
      serializedResponse.push({
        id: message.id,
        type: 'message',
        text: message.text,
        sender: message.sender,
        currentUser: message.guestId === guestId,
        time: moment(message.createdAt).format('hh:mm a'),
        agent: message.agent,
        visible: message.visible
      });
    });

    commit('SET_MESSAGES', serializedResponse);
    commit('SET_MESSAGES_TAB_LOADING', false);
  },
  async getAttachments({ dispatch, state }) {
    await dispatch(
      'attachments/getAttachments',
      {
        apiModule: 'LIVE_TOUR',
        recordId: state.pastTour.record.id
      },
      { root: true }
    );
  },
  async updateNote({ commit, state }, note) {
    await API.updateNote(state.pastTour.record.id, {
      note
    });

    commit('SET_PAST_TOUR_NOTE', note);
  },
  // eslint-disable-next-line no-unused-vars
  async guestWatched({ state }, params) {
    await API.guestWatched(params);
  },
  // eslint-disable-next-line no-unused-vars
  async resendRecordedPastTour({ state }, params) {
    const response = await API.resendRecordedPastTour(params);

    return response;
  },
  getPastTourBranding({ state, dispatch }) {
    dispatch(
      'brandingHeader/getBrandingHeader',
      { type: 'tour', id: state.pastTour.record.id },
      { root: true }
    );
  },
  setEditableNote({ commit }, data) {
    commit('SET_EDITABLE_NOTE', data);
  },
  resetPastTour({ commit }) {
    commit('RESET');
  }
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  mutations,
  actions
};
