import API from '@api/tours.api';
import { localTimezone } from '@helpers/common.helper';

function initialState() {
  return {
    records: [],
    monthTotal: 0,
    loadingTotals: false
  };
}

const getters = {
  records: (state) => state.records,
  monthTotal: (state) => state.monthTotal,
  loadingTotals: (state) => state.loadingTotals
};

const mutations = {
  SET_DATA(state, data) {
    state.records = data.records;
    state.monthTotal = data.monthTotal || 0;
  },
  SET_LOADING_TOTALS(state, loadingTotals) {
    state.loadingTotals = loadingTotals;
  },
  RESET(state) {
    const initial = initialState();

    Object.keys(initial).forEach((key) => {
      state[key] = initial[key];
    });
  }
};

const actions = {
  async getTourTotals({ commit, rootState }, params) {
    if (params.reset) commit('SET_LOADING_TOTALS', true);

    const { data } = await API.getTourTotals(
      {
        date: params.date,
        ownership: rootState.tours.ownership,
        timezone: localTimezone()
      }
    );

    commit('SET_DATA', data);
    commit('SET_LOADING_TOTALS', false);
  },
  resetTotals({ commit }) {
    commit('RESET');
  }
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  mutations,
  actions
};
