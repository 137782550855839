import moment from 'moment-timezone';

function downloadVideo(filename, data) {
  const blob = new Blob([data.Body], { type: 'video/mp4' });

  if (window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveBlob(blob, filename);
  } else {
    const elem = window.document.createElement('a');
    elem.href = window.URL.createObjectURL(blob);
    elem.download = filename;
    document.body.appendChild(elem);
    elem.click();
    document.body.removeChild(elem);
  }
}

function localTimezone() {
  return moment.tz.guess();
}

function parseTimeToSeconds(runtime) {
  const splittedTime = runtime.split(':');

  return splittedTime.reduce((acc, time) => (60 * acc) + +time);
}

function convertRuntime(runtime) {
  let convertedRuntime;

  if (runtime && runtime.indexOf(':') !== -1) {
    convertedRuntime = runtime;

    if (runtime.indexOf('.') === -1) {
      convertedRuntime = `${runtime}.0`;
    }

    return convertedRuntime;
  }

  if (!runtime || !parseInt(runtime, 10)) {
    return null;
  }

  if (parseInt(runtime, 10) > 60) {
    convertedRuntime = `${Math.floor(runtime / 60)}:`;

    // if runtime less than 10 mintues add leading zero
    if ((parseInt(runtime, 10) % 60) < 10) {
      convertedRuntime += `0${(runtime % 60).toFixed(0)}`;
    } else {
      convertedRuntime += (runtime % 60).toFixed(0);
    }
    // if time is less than 10 seconds add leading zeros
  } else if (parseInt(runtime, 10) < 10) {
    convertedRuntime = `0:0${runtime}`;
  } else {
    convertedRuntime = `0:${runtime}`;
  }

  return convertedRuntime;
}

function convertUnicode(input, encoding) {
  const map = {
    8: /\\+([0-9]{3})/g,
    16: /\\+u([0-9a-fA-F]{4})/g
  };

  return input.replace(map[encoding], (a, b) => String.fromCharCode(parseInt(b, encoding)));
}

async function checkAWSLinkAvailability(link) {
  return fetch(link).then((response) => {
    if (response.ok) return true;

    return false;
  }).catch((error) => {
    console.error('Error checking link availability:', error);
    return false;
  });
}

export {
  downloadVideo,
  localTimezone,
  convertRuntime,
  parseTimeToSeconds,
  convertUnicode,
  checkAWSLinkAvailability
};
