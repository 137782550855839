export default {
  capitalize: (value) => {
    if (!value) return '';
    return value.toString().replace(/^./, value[0].toUpperCase());
  },
  truncate: (value, limit = 30) => {
    if (!value) return '';
    if (value.length <= limit) return value;
    return `${value.toString().slice(value, limit)}...`;
  }
};
