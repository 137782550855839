export const getCurrent = {
  path() {
    const hosts = {
      'realync.com': 'https://api.realync.com',
      'stage.realync.com': 'https://api.staging.realync.com',
      'sandbox.realync.com': 'https://api.staging.realync.com',
      'stage-ltd.realync.com': 'https://api.staging-ltd.realync.com',
      'develop.realync.com': 'https://api.develop.realync.com'
    };

    return hosts[window.location.host] || window.location.origin.replace('8080', '3000');
  },
  webSocketPath() {
    const hosts = {
      'realync.com': 'https://api-socket-service.realync.com',
      'stage.realync.com': 'https://api-socket-service.staging.realync.com',
      'sandbox.realync.com': 'https://api-socket-service.staging.realync.com',
      'stage-ltd.realync.com': 'https://api-socket-service.staging-ltd.realync.com',
      'develop.realync.com': 'https://api-socket-service.develop.realync.com'
    };

    return hosts[window.location.host] || 'http://localhost:4001';
  }
};
