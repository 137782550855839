function findByUdid(udid, steps) {
  const results = [];

  steps.forEach((el) => {
    if (el.udid === udid) {
      results.push(el);
    }
  });

  if (results.length) {
    return results[0];
  }

  steps.forEach((el) => {
    const isStepWithSubSteps = el.actions.some((action) => action.subStep);

    if (isStepWithSubSteps) { // Need to search through actions for sub-step
      const node = findByUdid(udid, el.actions);

      if (node) {
        results.push(node);
      }
    }
  });

  return results[0];
}

function initialState() {
  return {
    journey: {},
    steps: [],
    activeStep: {},
    activeStepIndex: 0
  };
}

const getters = {
  journey: (state) => state.journey,
  steps: (state) => state.steps,
  activeStep: (state) => state.activeStep,
  activeStepIndex: (state) => state.activeStepIndex
};

const mutations = {
  SET_JOURNEY(state, payload) {
    state.journey = { ...state.journey, ...payload };
  },
  SET_STEPS(state, payload) {
    state.steps = payload;
  },
  SET_ACTIVE_STEP(state, stepIndex) {
    state.activeStep = state.steps[stepIndex];
    state.activeStepIndex = stepIndex;
  },
  UPDATE_ACTION_BY_ID(state, payload) {
    const {
      udid, name, url, hostVideo, steps, updateTrailheadActions
    } = payload;

    const stepToEdit = findByUdid(udid, steps || state.steps);
    let subStepToEdit;

    if (updateTrailheadActions) {
      subStepToEdit = findByUdid(udid, state.steps[0].actions);
    }

    if (typeof url !== 'undefined') {
      if (subStepToEdit) subStepToEdit.url = url;
      stepToEdit.url = url;
    }

    if (typeof name !== 'undefined') {
      if (subStepToEdit) subStepToEdit.name = name;
      stepToEdit.name = name;
    }

    if (typeof hostVideo !== 'undefined') {
      if (subStepToEdit) subStepToEdit.hostVideo = hostVideo;
      stepToEdit.hostVideo = hostVideo;

      if (udid === state.activeStep.udid) {
        state.activeStep.hostVideo = hostVideo;
      }
    }
  },
  ADD_STEP_ACTION(state, payload) {
    const { subStep } = payload;

    if (subStep && payload.type !== 'cta') {
      // Place sub-step on above 'Other Videos' & 'WrapUp' static steps
      state.activeStep.actions.splice(state.activeStep.actions.length - 2, 0, payload);
    } else {
      state.activeStep.actions.push(payload);
    }

    if (!subStep && payload.type !== 'cta') { // We keep root steps untouched if it is subStep or 'Call To ction'
      const stepOrder = state.activeStep.actions.length; // There should always be at least one step
      state.steps.splice(stepOrder, 0, payload);
    }
  },
  REMOVE_STEP_ACTION(state, payload) {
    const elIndex = state.activeStep.actions.findIndex((el) => el.udid === payload.udid);
    state.activeStep.actions.splice(elIndex, 1);

    if (!payload.subStep) { // We keep root steps untouched if it is subStep
      state.steps.splice(elIndex + 1, 1);
    }
  },
  RESET(state) {
    const initial = initialState();

    Object.keys(initial).forEach((key) => {
      state[key] = initial[key];
    });
  }
};

const actions = {
  setJourney({ commit }, payload) {
    commit('SET_JOURNEY', payload);
  },
  setSteps({ commit }, payload) {
    commit('SET_STEPS', payload);
  },
  removeStepAction({ commit }, payload) {
    commit('REMOVE_STEP_ACTION', payload);
  },
  addStepAction({ commit }, payload) {
    commit('ADD_STEP_ACTION', payload);
  },
  updateActionById({ commit }, payload) {
    commit('UPDATE_ACTION_BY_ID', payload);
  },
  resetJourneyStore({ commit }) {
    commit('RESET');
  },
  setActiveStep({ commit }, payload) {
    commit('SET_ACTIVE_STEP', payload);
  },
  // eslint-disable-next-line no-shadow
  nextStep({ commit, getters }) {
    commit('SET_ACTIVE_STEP', getters.activeStepIndex + 1);
  },
  // eslint-disable-next-line no-shadow
  previousStep({ commit, getters }) {
    commit('SET_ACTIVE_STEP', getters.activeStepIndex - 1);
  }
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  mutations,
  actions
};
