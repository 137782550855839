import parseTimeToSeconds from './parseTimeToSeconds.helper';

function parseTimestamps(transcriptionJSON) {
  return transcriptionJSON.map((transcription, index) => {
    const splittedTime = transcription.time.split('-->');

    transcription.index = index;

    transcription.from = parseTimeToSeconds(splittedTime[0].trim());
    transcription.to = parseTimeToSeconds(splittedTime[1].trim());

    transcription.fromSimplified = splittedTime[0].trim().substring(3, 8);
    transcription.toSimplified = splittedTime[1].trim().substring(3, 8);

    return transcription;
  });
}

function getRangeIndex(range, allTimeRanges) {
  return allTimeRanges.indexOf(range);
}

function getRange(time, allTimeRanges) {
  return allTimeRanges.find((range) => (range.from <= time) && (time < range.to));
}

function getIndexes(currentTime, previousTime, allTimeRanges) {
  const result = {};

  const currentRange = getRange(currentTime, allTimeRanges);

  if (!currentRange) {
    return allTimeRanges.forEach((r) => {
      r.current = false;
    });
  }

  const previousRange = getRange(previousTime, allTimeRanges);

  if (previousRange && (currentRange && currentRange.from !== previousRange.from)) {
    result.previousTimeRangeIndex = getRangeIndex(previousRange, allTimeRanges);
  }

  if (!currentRange || (previousTime > currentRange.from && previousTime <= currentTime)) {
    return result;
  }

  result.currentTimeRangeIndex = getRangeIndex(currentRange, allTimeRanges);

  return result;
}

function getAllTimeRanges(transcriptionJSON) {
  const mappedTranscription = parseTimestamps(transcriptionJSON);

  return mappedTranscription.map((transcription) => {
    const {
      to,
      from,
      toSimplified,
      fromSimplified,
      text
    } = transcription;

    return {
      to,
      from,
      toSimplified,
      fromSimplified,
      text,
      current: false
    };
  });
}

export {
  getAllTimeRanges,
  getRange,
  getIndexes
};
